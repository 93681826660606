import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import Amplify, { Auth, Hub, Storage, API } from 'aws-amplify';
import { AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
//import 'ag-grid-enterprise';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { type } from "@testing-library/user-event/dist/type";
import { printConsole } from "./lib/utility";

ModuleRegistry.registerModules([ExcelExportModule]);

const axios = require('axios');

const myAPI="mktappapi";
let path = '/strategy';

const MyPortfolioIronCondor = ({ signOut }) => {

  printConsole("Executing MyPortfolio");
  const navigate = useNavigate();

  let numberOfChecked=useRef(0);
  let deleteIds=useRef([]);

  //const [email, setEmail]=useState()
  let email=useRef();
  const [days, setDays]=useState();
  const [forceRender, setForceRender]=useState(false);
  const [daysOffset, setDaysOffset]=useState();
  const [minInterest, setMinInyerest]=useState();
  const [maxInterest, setMaxInterest]=useState();
  const [settingsStr, setSettingsStr]=useState({"days":"30","daysOffset":"10","minInterest":"0.7","maxInterest":"1.5"});
  const [data,setData]=useState();
  const [counter, setCounter]=useState(0);
  const [error,setError]=useState('');
  const [hide, setHide]=useState("hidden");
  const [submitted, setSubmitted]=useState(false);
  const [stocks, setStocks]=useState({});
  let calledGetMyTransactions = useRef(false);
  let [isEditing, setIsEditing] = useState(false);
  let selectedRecord = useRef();
  let deleteRecIds = useRef([]);
  let [recsWereDeleted, setRecsWereDeleted] = useState(-1);
  let [displayMainMessage, setDisplayMainMessage] = useState("");
  let unrealizedProfit = useRef(0);
  let realizedProfit = useRef(0);
  let investedInMarket = useRef(0);
  let asOfDateTime = useRef();

  let apiCalled=useRef(0);

  async function getUserInfo() {
    printConsole("Executing getUserInfo");
    return (
      await new Promise((resolve, reject)=>{
        Auth.currentAuthenticatedUser().then(async user=>{
          await printConsole("getUserInfo : user.attributes.email = "+JSON.stringify(user.attributes.email));
          //await setEmail(user.attributes.email)
          email.current=user.attributes.email;
          await printConsole("getUserInfo : setEmail = "+email.current);
        })
        setTimeout(() => resolve("done"), 1000);
      })
    )
  }

  
  let initString="";

  //const [analysis, setAnalysis]=useState([]);
  let analysis=useRef([]);

  let recLst=[];
  let indRecLst=0;

  let maxChg="";
  let avgChg="";
  let wAvgChg="";
  let maxChgArrStr=[];
  let maxChgArrNum=[];
  let chgPercentCnt=0;
  let sumChgPercent=0;
  let wSumChgPercent=0;
  let wSum=0;
  let cnt33=0;
  let cnt66=0;
  let cnt100=0;
  let profitResponse="";
  let daysNowToExp = useRef();

  
  async function getMyTransactions(email) {
    printConsole("Executing getMyTransactions");
    printConsole("getMyTransactions : email = "+email);
    calledGetMyTransactions.current = true;
    path = '/getIronCondorTransactions';

    var passApistr=path + "/" + email;
    printConsole("passApiStr = "+passApistr);
    await API.get(myAPI, passApistr)
       .then(async response => {
         await printConsole("getMyTransactions : response = " + JSON.stringify(response));
         await printConsole(response);
         var count = Object.keys(response).length;
         analysis.current=[];
         await printConsole("getMyTransactions : count = "+ count);
         if (count>0){
          analysis.current=[];
          for (var i=0; i<count; i++) {
            if (i==1){
              for (var j=0; j<response[i].length; j++) {
                var keys = Object.keys(response[i]);
                //await printConsole(keys[j] + " = " + response[i][j]);
                if (response[i][j]=="null") {
                  response[i][j]=0;
                }
              }
            }
            await printConsole ("response[" + i + "] = " +JSON.stringify(response[i]));
            let responseObj = JSON.parse(response[i]);
            await console.log("getMyTransactions : responseObj = ", responseObj);
            
            analysis.current.push(responseObj);
          }
          await printConsole("getMyTransactions : analysis.length = " +analysis.current.length);
         }
       })
      .then(() => {
        loadRowData();
      })
       .catch(error => {
         printConsole(error)
       })
  }

  
let rowdata=[];
const [rowDataState,setRowDataSate]=useState([]);

  function loadRowData() {
    printConsole ("Executing loadRowData");
    printConsole ("loadRowData : analysis = "+ JSON.stringify(analysis.current));
    var buyWriteCost, premium, maxProfit, breakEven, daysNowToExp, optionPrice, pnl, pnlProfit;
    if (typeof(analysis.current)!='undefined') {
      var key=0;
      //var count = Object.keys(analysis).length;
      var count=analysis.current.length;
      printConsole ("loadRowData : analysis length= "+count);
      var rank=0;

      if (count === 0) {
        rowdata = [];
        setRowDataSate(rowdata);
      }

      for (key=0; key<count; key++) {
        let recObj = analysis.current[key].toString().trim();
        //recObj = "{'Security' : 'SPY', 'Sell Call Strike' : 545.00, 'Buy Call Strike' : 524.00, 'Buy Put Strike' : 519.00, 'Sell Put Strike' : 495.00, 'Position' : 'LONG', 'Expiration' : '2024-04-26', 'Last Sale' : 521.21, 'Sell Call Open Quote' : 0.80, 'Sell Call Open Cost' : 160.00, 'Buy Call Open Quote' : 7.02, 'Buy Call Open Cost' : -1404.00, 'Buy Put Open Quote' : 5.27, 'Buy Put Open Cost' : -1054.00, 'Sell Put Open Quote' : 1.17, 'Sell Put Open Cost' : 234.00, 'Net Amount' : -10.32, 'Max Loss' : -2064.00, 'Commission Fee' : 5.20, 'Upper': 5.00, 'Lower': 0.50, 'Days': 30, 'Contracts': 2, 'Open Trade Date': '2024-03-24', 'Close Trade Date': '', 'Desired Profit Percent': 2.00, 'Open Cost': -2069.20, 'Buy Call Close Quote': 0.83, 'Buy Call Close Cost': -166, 'Sell Call Close Quote': 6.96, 'Sell Call Close Cost': 1392, 'Buy Put Close Quote': 1.2, 'Buy Put Close Cost': -240, 'Sell Put Close Quote': 5.21, 'Sell Put Close Cost': 1042, 'Close Cost': 2028, 'Profit Loss Dollars': -41.20, 'Profit Loss Percents': -0.02 }"
        recObj = recObj.replace(/'/g, '"');
        //recObj = recObj.replace("LONG", "\"LONG\"");
        //recObj = recObj.replace("SHORT", "\"SHORT\"");
        recObj = recObj.toString().replace("{ ", "{");
        recObj = recObj.toString().replace(" }", "}");
        console.log("loadRowData : recObj before parse = " + recObj);
        console.log("loadRowData : recObj before parse type = " + typeof(recObj));
        recObj = recObj.replace(/NaN/g, '0');
        recObj = JSON.parse(recObj);
        console.log("loadRowData : recObj after parse type = " + typeof(recObj));
        console.log("loadRowData : recObj = ", recObj);
        let closeTradeDate = "";
        if (recObj["Close Trade Date"].toString().trim().toLowerCase() === "null") {
          closeTradeDate = "";
        } else {
          closeTradeDate = recObj["Close Trade Date"];
        }
        if (recObj["Desired Profit Percent"] === "") {
          recObj["Desired Profit Percent"] = 0.0;
        }
        let buyCallStrikeUpdate = true;
        if (recObj["Buy Call Strike Update"] === "true") {
          buyCallStrikeUpdate = false;
        };
        let sellCallStrikeUpdate = true;
        if (recObj["Sell Call Strike Update"] === "true") {
          sellCallStrikeUpdate = false;
        };
        let sellPutStrikeUpdate = true;
        if (recObj["Sell Put Strike Update"] === "true") {
          sellPutStrikeUpdate = false;
        };
        let buyPutStrikeUpdate = true;
        if (recObj["Buy Put Strike Update"] === "true") {
          buyPutStrikeUpdate = false;
        };
        let paperTrade = true;
        if (recObj["Paper Trade"] === "false") {
          paperTrade = false;
        }
        let includeCommissionFee = true;
        if (recObj["Include Commission Fee"] === "false") {
          includeCommissionFee = false;
        }
        let desiredNetAmount = ((-1) * parseFloat(recObj["Net Amount"]) + (-1) * parseFloat(recObj["Net Amount"]) * parseFloat(recObj["Desired Profit Percent"]) / 100).toFixed(2);

        let gammaBuyCallStrike = parseFloat(parseFloat(recObj["Gamma Buy Call Strike"]).toFixed(2)) || 0;
        let gammaSellCallStrike = parseFloat(parseFloat(recObj["Gamma Sell Call Strike"]).toFixed(2)) || 0;
        let gammaBuyPutStrike = parseFloat(parseFloat(recObj["Gamma Buy Put Strike"]).toFixed(2)) || 0;
        let gammaSellPutStrike = parseFloat(parseFloat(recObj["Gamma Sell Put Strike"]).toFixed(2)) || 0;
        let vegaBuyCallStrike = parseFloat(parseFloat(recObj["Vega Buy Call Strike"]).toFixed(2)) || 0;
        let vegaSellCallStrike = parseFloat(parseFloat(recObj["Vega Sell Call Strike"]).toFixed(2)) || 0;
        let vegaBuyPutStrike = parseFloat(parseFloat(recObj["Vega Buy Put Strike"]).toFixed(2)) || 0;
        let vegaSellPutStrike = parseFloat(parseFloat(recObj["Vega Sell Put Strike"]).toFixed(2)) || 0;
        let thetaBuyCallStrike = parseFloat(parseFloat(recObj["Theta Buy Call Strike"]).toFixed(2)) || 0;
        let thetaSellCallStrike = parseFloat(parseFloat(recObj["Theta Sell Call Strike"]).toFixed(2)) || 0;
        let thetaBuyPutStrike = parseFloat(parseFloat(recObj["Theta Buy Put Strike"]).toFixed(2)) || 0;
        let thetaSellPutStrike = parseFloat(parseFloat(recObj["Theta Sell Put Strike"]).toFixed(2)) || 0;
        let deltaBuyCallStrike = parseFloat(parseFloat(recObj["Delta Buy Call Strike"]).toFixed(2)) || 0;
        let deltaSellCallStrike = parseFloat(parseFloat(recObj["Delta Sell Call Strike"]).toFixed(2)) || 0;
        let deltaBuyPutStrike = parseFloat(parseFloat(recObj["Delta Buy Put Strike"]).toFixed(2)) || 0;
        let deltaSellPutStrike = parseFloat(parseFloat(recObj["Delta Sell Put Strike"]).toFixed(2)) || 0;
        let netDelta = (deltaBuyCallStrike + deltaBuyPutStrike - deltaSellCallStrike - deltaSellPutStrike).toFixed(2);
        let netTheta = (thetaBuyCallStrike + thetaBuyPutStrike - thetaSellCallStrike - thetaSellPutStrike).toFixed(2);
        let netGamma = (gammaBuyCallStrike + gammaBuyPutStrike - gammaSellCallStrike - gammaSellPutStrike).toFixed(2);
        let netVega = (vegaBuyCallStrike + vegaBuyPutStrike - vegaSellCallStrike - vegaSellPutStrike).toFixed(2);

        let gammaBuyCallStrikeCurrent = parseFloat(parseFloat(recObj["Gamma Buy Call Strike Current"]).toFixed(2)) || 0;
        let gammaSellCallStrikeCurrent = parseFloat(parseFloat(recObj["Gamma Sell Call Strike Current"]).toFixed(2)) || 0;
        let gammaBuyPutStrikeCurrent = parseFloat(parseFloat(recObj["Gamma Buy Put Strike Current"]).toFixed(2)) || 0;
        let gammaSellPutStrikeCurrent = parseFloat(parseFloat(recObj["Gamma Sell Put Strike Current"]).toFixed(2)) || 0;
        let vegaBuyCallStrikeCurrent = parseFloat(parseFloat(recObj["Vega Buy Call Strike Current"]).toFixed(2)) || 0;
        let vegaSellCallStrikeCurrent = parseFloat(parseFloat(recObj["Vega Sell Call Strike Current"]).toFixed(2)) || 0;
        let vegaBuyPutStrikeCurrent = parseFloat(parseFloat(recObj["Vega Buy Put Strike Current"]).toFixed(2)) || 0;
        let vegaSellPutStrikeCurrent = parseFloat(parseFloat(recObj["Vega Sell Put Strike Current"]).toFixed(2)) || 0;
        let thetaBuyCallStrikeCurrent = parseFloat(parseFloat(recObj["Theta Buy Call Strike Current"]).toFixed(2)) || 0;
        let thetaSellCallStrikeCurrent = parseFloat(parseFloat(recObj["Theta Sell Call Strike Current"]).toFixed(2)) || 0;
        let thetaBuyPutStrikeCurrent = parseFloat(parseFloat(recObj["Theta Buy Put Strike Current"]).toFixed(2)) || 0;
        let thetaSellPutStrikeCurrent = parseFloat(parseFloat(recObj["Theta Sell Put Strike Current"]).toFixed(2)) || 0;
        let deltaBuyCallStrikeCurrent = parseFloat(parseFloat(recObj["Delta Buy Call Strike Current"]).toFixed(2)) || 0;
        let deltaSellCallStrikeCurrent = parseFloat(parseFloat(recObj["Delta Sell Call Strike Current"]).toFixed(2)) || 0;
        let deltaBuyPutStrikeCurrent = parseFloat(parseFloat(recObj["Delta Buy Put Strike Current"]).toFixed(2)) || 0;
        let deltaSellPutStrikeCurrent = parseFloat(parseFloat(recObj["Delta Sell Put Strike Current"]).toFixed(2)) || 0;
        let maxGain = parseFloat(parseFloat(recObj["Max Gain"]).toFixed(2)) || 0;
        let vixLastSale = parseFloat(parseFloat(recObj["VIX Last Sale"]).toFixed(2)) || 0;
        let vixChange = parseFloat(parseFloat(recObj["VIX Change"]).toFixed(2)) || 0;
        let netDeltaCurrent = (deltaBuyCallStrikeCurrent + deltaBuyPutStrikeCurrent - deltaSellCallStrikeCurrent - deltaSellPutStrikeCurrent).toFixed(2);
        let netThetaCurrent = (thetaBuyCallStrikeCurrent + thetaBuyPutStrikeCurrent - thetaSellCallStrikeCurrent - thetaSellPutStrikeCurrent).toFixed(2);
        let netGammaCurrent = (gammaBuyCallStrikeCurrent + gammaBuyPutStrikeCurrent - gammaSellCallStrikeCurrent - gammaSellPutStrikeCurrent).toFixed(2);
        let netVegaCurrent = (vegaBuyCallStrikeCurrent + vegaBuyPutStrikeCurrent - vegaSellCallStrikeCurrent - vegaSellPutStrikeCurrent).toFixed(2);

        rowdata[key]={
          email: email.current,
          id: recObj["Id"],
          security: recObj["Security"],
          exp: recObj["Expiration"],
          contracts: parseInt(recObj["Contracts"]),
          sellCallStrike: parseFloat(recObj["Sell Call Strike"]).toFixed(2),
          buyCallStrike: parseFloat(recObj["Buy Call Strike"]).toFixed(2),
          buyPutStrike: parseFloat(recObj["Buy Put Strike"]).toFixed(2),
          sellPutStrike: parseFloat(recObj["Sell Put Strike"]).toFixed(2),
          openCost: parseFloat(recObj["Open Cost"]).toFixed(2),
          closeCost: parseFloat(recObj["Close Cost"]).toFixed(2),
          profitLossDollars: parseFloat(recObj["Profit Loss Dollars"]).toFixed(2),
          profitLossPercent: parseFloat(recObj["Profit Loss Percents"]).toFixed(2),
          position: recObj.Position,
          lastSale: parseFloat(recObj["Last Sale"]).toFixed(2),
          sellCallOpenQuote: parseFloat(recObj["Sell Call Open Quote"]).toFixed(2),
          sellCallOpenCost: parseFloat(recObj["Sell Call Open Cost"]).toFixed(2),
          buyCallOpenQuote: parseFloat(recObj["Buy Call Open Quote"]).toFixed(2),
          buyCallOpenCost: parseFloat(recObj["Buy Call Open Cost"]).toFixed(2),
          buyPutOpenCost: parseFloat(recObj["Buy Put Open Cost"]).toFixed(2),
          buyPutOpenQuote: parseFloat(recObj["Buy Put Open Quote"]).toFixed(2),
          sellPutOpenQuote: parseFloat(recObj["Sell Put Open Quote"]).toFixed(2),
          sellPutOpenCost: parseFloat(recObj["Sell Put Open Cost"]).toFixed(2),
          netAmount: parseFloat(recObj["Net Amount"]).toFixed(2),
          maxLoss: parseFloat(recObj["Max Loss"]).toFixed(2),
          commissionFee: parseFloat(recObj["Commission Fee"]).toFixed(2),
          upper: parseFloat(recObj["Upper"]).toFixed(2),
          lower: parseFloat(recObj["Lower"]).toFixed(2),
          days: parseInt(recObj["Days"]),
          openTradeDate: recObj["Open Trade Date"],
          closeTradeDate: closeTradeDate,
          desiredProfitPercent: parseFloat(recObj["Desired Profit Percent"]).toFixed(2),
          sellCallCloseQuote: parseFloat(recObj["Sell Call Close Quote"]).toFixed(2),
          sellCallCloseCost: parseFloat(recObj["Sell Call Close Cost"]).toFixed(2),
          buyCallCloseQuote: parseFloat(recObj["Buy Call Close Quote"]).toFixed(2),
          buyCallCloseCost: parseFloat(recObj["Buy Call Close Cost"]).toFixed(2),
          buyPutCloseCost: parseFloat(recObj["Buy Put Close Cost"]).toFixed(2),
          buyPutCloseQuote: parseFloat(recObj["Buy Put Close Quote"]).toFixed(2),
          sellPutCloseQuote: parseFloat(recObj["Sell Put Close Quote"]).toFixed(2),
          sellPutCloseCost: parseFloat(recObj["Sell Put Close Cost"]).toFixed(2),
          lastSaleClose: parseFloat(recObj["Last Sale Close"]).toFixed(2),
          netAmountClose: parseFloat(recObj["Net Amount Close"]).toFixed(2),
          desiredNetAmount: desiredNetAmount,
          buyCallStrikeUpdate: buyCallStrikeUpdate,
          sellCallStrikeUpdate: sellCallStrikeUpdate,
          sellPutStrikeUpdate: sellPutStrikeUpdate,
          buyPutStrikeUpdate: buyPutStrikeUpdate,
          paperTrade: paperTrade,
          includeCommissionFee: includeCommissionFee,
          upperBreakeven: parseFloat(recObj["Upper Breakeven"]).toFixed(2),
          lowerBreakeven: parseFloat(recObj["Lower Breakeven"]).toFixed(2),
          /*vegaSellCallStrike: parseFloat(recObj["Vega Sell Call Strike"]).toFixed(2),
          vegaBuyCallStrike: parseFloat(recObj["Vega Buy Call Strike"]).toFixed(2),
          vegaBuyPutStrike: parseFloat(recObj["Vega Buy Put Strike"]).toFixed(2),
          vegaSellPutStrike: parseFloat(recObj["Vega Sell Put Strike"]).toFixed(2),*/
          gammaSellCallStrike: gammaSellCallStrike,
          gammaBuyCallStrike: gammaBuyCallStrike,
          gammaBuyPutStrike: gammaBuyPutStrike,
          gammaSellPutStrike: gammaSellPutStrike,
          vegaSellCallStrike: vegaSellCallStrike,
          vegaBuyCallStrike: vegaBuyCallStrike,
          vegaBuyPutStrike: vegaBuyPutStrike,
          vegaSellPutStrike: vegaSellPutStrike,
          thetaSellCallStrike: thetaSellCallStrike,
          thetaBuyCallStrike: thetaBuyCallStrike,
          thetaBuyPutStrike: thetaBuyPutStrike,
          thetaSellPutStrike: thetaSellPutStrike,
          deltaSellCallStrike: deltaSellCallStrike,
          deltaBuyCallStrike: deltaBuyCallStrike,
          deltaBuyPutStrike: deltaBuyPutStrike,
          deltaSellPutStrike: deltaSellPutStrike,
          netDelta: netDelta,
          netTheta: netTheta,
          netGamma: netGamma,
          netVega: netVega,
          notes: recObj.Notes,
          gammaSellCallStrikeCurrent: gammaSellCallStrikeCurrent,
          gammaBuyCallStrikeCurrent: gammaBuyCallStrikeCurrent,
          gammaBuyPutStrikeCurrent: gammaBuyPutStrikeCurrent,
          gammaSellPutStrikeCurrent: gammaSellPutStrikeCurrent,
          vegaSellCallStrikeCurrent: vegaSellCallStrikeCurrent,
          vegaBuyCallStrikeCurrent: vegaBuyCallStrikeCurrent,
          vegaBuyPutStrikeCurrent: vegaBuyPutStrikeCurrent,
          vegaSellPutStrikeCurrent: vegaSellPutStrikeCurrent,
          thetaSellCallStrikeCurrent: thetaSellCallStrikeCurrent,
          thetaBuyCallStrikeCurrent: thetaBuyCallStrikeCurrent,
          thetaBuyPutStrikeCurrent: thetaBuyPutStrikeCurrent,
          thetaSellPutStrikeCurrent: thetaSellPutStrikeCurrent,
          deltaSellCallStrikeCurrent: deltaSellCallStrikeCurrent,
          deltaBuyCallStrikeCurrent: deltaBuyCallStrikeCurrent,
          deltaBuyPutStrikeCurrent: deltaBuyPutStrikeCurrent,
          deltaSellPutStrikeCurrent: deltaSellPutStrikeCurrent,
          netDeltaCurrent: netDeltaCurrent,
          netThetaCurrent: netThetaCurrent,
          netGammaCurrent: netGammaCurrent,
          netVegaCurrent: netVegaCurrent,
          vixLastSale: vixLastSale,
          vixChange: vixChange,
          maxGain: maxGain,
          mktDirection: recObj["MKT Direction"]
        }
        let rowDataCount = Object.keys(rowdata[key]).length;
        printConsole("loadRowData : rowDataCount = " + rowDataCount);
      }
      
      printConsole("loadRowData : typeof rowDataState = "+typeof(rowDataState));
      printConsole("loadRowData : length rowDataState = "+rowDataState.length);
      printConsole("loadRowData : count = "+count);
      printConsole("loadRowData : rowDataState.length = "+rowDataState.length);;
      let realProfit = 0;
      let unRealProfit = 0;
      let invest = 0;
      for (let rowInd = 0; rowInd < rowdata.length; rowInd++) {
        console.log ("loadRowData : closeTradeDate = " + rowdata[rowInd].closeTradeDate + "\trowdata[rowInd].closeTradeDate.length = " + rowdata[rowInd].closeTradeDate.length + "\tpaperTrade = " + rowdata[rowInd].paperTrade);
        if ((isExpired(rowdata[rowInd].exp) || rowdata[rowInd].closeTradeDate.length > 0) && !rowdata[rowInd].paperTrade) {
          realProfit = realProfit + parseFloat(rowdata[rowInd].profitLossDollars);
          console.log ("loadRowData : rowdata[rowInd].profitLossDollars = " + rowdata[rowInd].profitLossDollars);
          console.log ("loadRowData : realProfit = " + realProfit);
        } else if (!rowdata[rowInd].paperTrade){
          unRealProfit = unRealProfit + parseFloat(rowdata[rowInd].profitLossDollars);

          invest = invest + parseFloat(rowdata[rowInd].openCost);
          
        }
      }
      if (parseFloat(invest) < 0) {
        invest = parseFloat(invest) * (-1);
      }
      /*unrealizedProfit.current = unRealProfit.toLocaleString('en-US');
      realizedProfit.current = realProfit.toLocaleString('en-US');
      investedInMarket.current = invest.toLocaleString('en-US');*/

      unrealizedProfit.current = unRealProfit.toString();
      realizedProfit.current = realProfit.toString();;
      investedInMarket.current = invest.toString();;

      if (count!=0) {
        setRowDataSate(rowdata);
        printConsole("loadRowData : rowdata = " + JSON.stringify(rowdata[0]));
      }
      
    }
  }

  const rowClassRules = {

    // row style function
    'goodOption': function(params) {
      printConsole ("Executing goodOption");
      console.log ("goodOption : params = ", params);
      var expStr=params.data["exp"];
      var dtExp=new Date(expStr).toUTCString().toString();
      let dateObj = new Date(dtExp);
      let secondsExp = dateObj.getTime() / 1000;
      var dtNow=new Date();
      dateObj = new Date(dtNow);
      let secondsNow = dateObj.getTime() / 1000;
      printConsole("goodOption : secondsNow = " + secondsNow + "\tsecondsExp = " + secondsExp);
      printConsole("goodOption : dtNow = " + dtNow + "\tdtExp = " + dtExp);
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : goodOption : daysNowToExp = "+expDays  + "\tsymbol = " + params.data["symbol"]);
    
      return parseFloat(params.data.profitLossDollars) > 0.00 && expDays >= 0 && params.data.closeTradeDate.length === 0;
    },
    'goodOptionClosed': function(params) {
      printConsole ("Executing goodOption");
      console.log ("goodOption : params = ", params);
      var expStr=params.data["exp"];
      var dtExp=new Date(expStr).toUTCString().toString();
      let dateObj = new Date(dtExp);
      let secondsExp = dateObj.getTime() / 1000;
      var dtNow=new Date();
      dateObj = new Date(dtNow);
      let secondsNow = dateObj.getTime() / 1000;
      printConsole("goodOption : secondsNow = " + secondsNow + "\tsecondsExp = " + secondsExp);
      printConsole("goodOption : dtNow = " + dtNow + "\tdtExp = " + dtExp);
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : goodOption : daysNowToExp = "+expDays  + "\tsymbol = " + params.data["symbol"]);
    
      return parseFloat(params.data.profitLossDollars) > 0.00 && (expDays < 0 || params.data.closeTradeDate.length > 0);
    },
    /*'mediumOption': function (params) {
      printConsole ("Executing medium");
      printConsole("mediumOption : params = ", params);
      var expStr=params.data["exp"];
      var dtExp=new Date(expStr).toUTCString().toString();
      let dateObj = new Date(dtExp);
      let secondsExp = dateObj.getTime() / 1000;
      var dtNow=new Date();
      dateObj = new Date(dtNow);
      let secondsNow = dateObj.getTime() / 1000;
      printConsole("medium : secondsNow = " + secondsNow + "\tsecondsExp = " + secondsExp);
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : mediumOption : daysNowToExp = "+expDays + "\tsymbol = " + params.data["symbol"]);
      
      return parseFloat(params.data.profitLossDollars) <= 0  && expDays >= 0 && params.data.closeTradeDate.length === 0 && (parseFloat(params.data.lastSaleClose) > parseFloat(params.data.buyCallStrike) || parseFloat(params.data.lastSaleClose) < parseFloat(params.data.buyPutStrike));
    },*/
    // row style expression
    'badOptionPortfolio': function (params) {
      printConsole ("Executing badOptionPortfolio");
      printConsole("badOption : params = ", params);
      var expStr=params.data["exp"];
      var dtExp=new Date(expStr).toUTCString().toString();
      let dateObj = new Date(dtExp);
      let secondsExp = dateObj.getTime() / 1000;
      var dtNow=new Date();
      dateObj = new Date(dtNow);
      let secondsNow = dateObj.getTime() / 1000;
      printConsole("badOption : secondsNow = " + secondsNow + "\tsecondsExp = " + secondsExp);
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : mbadOptionPortfolio : daysNowToExp = "+expDays + "\tsymbol = " + params.data["symbol"]);
      
      return parseFloat(params.data.profitLossDollars) < 0  && expDays >= 0 && params.data.closeTradeDate.length === 0 ;
    },
    'badOptionPortfolioClosed': function (params) {
      printConsole ("Executing badOptionPortfolio");
      printConsole("badOption : params = ", params);
      var expStr=params.data["exp"];
      var dtExp=new Date(expStr).toUTCString().toString();
      let dateObj = new Date(dtExp);
      let secondsExp = dateObj.getTime() / 1000;
      var dtNow=new Date();
      dateObj = new Date(dtNow);
      let secondsNow = dateObj.getTime() / 1000;
      printConsole("badOption : secondsNow = " + secondsNow + "\tsecondsExp = " + secondsExp);
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : mbadOptionPortfolio : daysNowToExp = "+expDays + "\tsymbol = " + params.data["symbol"]);
      
      return parseFloat(params.data.profitLossDollars) < 0  && (expDays < 0 || params.data.closeTradeDate.length > 0);
    },
};

useEffect(()=>{
  printConsole ("Executing useEffect");
  async function fetchData() {
    await printConsole ("Executing useEffect : fetchData");
    await getUserInfo();
    await printConsole ("useEffect : email.current = " + email.current);
    await printConsole ("useEffect : calledGetMyTransactions.current = " + calledGetMyTransactions.current);
    if (typeof(email.current)!='undefined' && !calledGetMyTransactions.current) {
      await getMyTransactions(email.current);
    };
  };
  if (isEditing === false) {
    fetchData();
    const interval = setInterval(() => {
      calledGetMyTransactions.current = false;
      fetchData();
      setCounter(prevCounter => prevCounter + 1); // Update directly inside
    }, 5 * 60 * 1000); 

    return () => clearInterval(interval);
  }
},[submitted, isEditing, recsWereDeleted, counter])

const gridRef = useRef();

let gridApi = useCallback(() => {
  printConsole ("Executing gridApi");
  if (!gridRef.current) {
    printConsole("!gridRef.current");
    return;
  }

  printConsole("!gridRef.current");
  return gridRef.current.gridApi;
}, [gridRef]);

const setCellValue = (rowIndex, columnField, value) => {
  printConsole ("Executing setCellValue");
  gridApi().setDataValue(rowIndex, columnField, value);
};

function getFormattedDate(date) {
  printConsole ("Executing getFormattedDate");
  printConsole("getFormattedDate: received date = "+date);
  if (typeof(date)!='undefined') {
    if (date.toString().length>0){
      //printConsole("getFormattedDate : received date = "+ date.toString());
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
    
      printConsole("getFormattedDate : returning date = "+ month + '/' + day + '/' + year);
    
      return month + '/' + day + '/' + year;
    } else {
      return "";
    }
  } else {
    return "";
  }
  
}

function formatCurrency(value, locale = 'en-US', currency = 'USD') {
  // Convert the string to a number first if it's not already
  const numberValue = parseFloat(value);

  // Format the number as currency
  return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency
  }).format(numberValue);
}

const columns=[
  {headerName: "Id", field: "id", hide: true, valueGetter : row => {
    return row.data.id;
  },},
  {headerName: "email", field: "email", hide: true, valueGetter : row => {
    var emailRow=email.current;
    row.data.email=emailRow;
    return emailRow;
  },},
  //{headerName: "Delete", field: "delete", pinned: true, headerCheckboxSelection: false, checkboxSelection: true, minWidth:10, maxWidth:90},
  {
    headerName: "Delete",
    field: "delete",
    pinned: true,
    headerCheckboxSelection: false,
    checkboxSelection: true,
    minWidth: 10,
    maxWidth: 90,
    cellRenderer: (params) => {
      return (
        <span>
          {params.data.paperTrade && <img src="paper.png" style={{ width: "20px", height: "20px", marginRight: "5px" }} />}
          {params.value}
        </span>
      );
    }
  },
  {headerName: "Security", field: "security", sortable: true, filter: true, pinned: true, minWidth:10, maxWidth:90, resizable:true, editable: true,},
  {headerName: "Last Sale", field: "lastSaleClose", sortable: true, pinned: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:100, editable: false, resizable:true, /*cellRenderer: row =>{
    if (typeof(row.data.lastSaleClose) !== 'undefined') {
      if (isNaN(row.data.lastSaleClose)) {
        row.data.lastSaleClose=0;
        return row.data.lastSaleClose;
      } else {
          return row.data.lastSaleClose;
      }
    }
  },*/
    valueGetter: (params) => {
    // Ensure the value is a number, provide 0 as a fallback
        return parseFloat(params.data.lastSaleClose) || 0;
    },
    valueFormatter: (params) => {
        // Convert to string with two decimal places and append '%'
        return `${formatCurrency(params.value)}`;
    },
    cellRenderer: (params) => {
        // Use value formatter in cell renderer for consistency
        return params.valueFormatted;
    }
  },
  /*{headerName: "Profit / Loss %", field: "profitLossPercent", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:130, editable: false, resizable:true, cellRenderer: row =>{
    console.log ("row = ", row);
    if (typeof(row.data.exp) !== 'undefined') {
      if (isNaN(row.data.profitLossPercent)) {
        row.data.profitLossPercent=0;
        return row.data.profitLossPercent;
      } else {
          
          row.data.profitLossPercent = parseFloat(row.data.profitLossPercent).toFixed(2);
          console.log ("cell : row = ",row);
          return row.data.profitLossPercent;
      }
    }
  },
  },*/
  {
    headerName: "Profit / Loss %", 
    field: "profitLossPercent", 
    sortable: true, 
    filter: 'agNumberColumnFilter', 
    minWidth: 50, 
    maxWidth: 130, 
    editable: false, 
    resizable: true,
    valueGetter: (params) => {
        // Ensure the value is a number, provide 0 as a fallback
        return parseFloat(params.data.profitLossPercent) || 0;
    },
    valueFormatter: (params) => {
        // Convert to string with two decimal places and append '%'
        return `${params.value.toFixed(2)}%`;
    },
    cellStyle: (params) => {
      let breakEvenUp = (-1) * parseFloat(params.data.openCost) / (parseInt(params.data.contracts) * 100) + parseFloat(params.data.buyCallStrike);
      let breakEvenDown = parseFloat(params.data.buyPutStrike) - (-1) * parseFloat(params.data.openCost) / (parseInt(params.data.contracts) * 100);
      console.log ("breakEvenUp = " + breakEvenUp + "\tbreakEvenDown = " + breakEvenDown + "\tlastSaleClose = " + parseFloat(params.data.lastSaleClose));
      if ((parseFloat(params.data.lastSaleClose) > breakEvenUp ||
          parseFloat(params.data.lastSaleClose) < breakEvenDown) &&
          parseFloat(params.data.profitLossDollars) < 0 &&
          !isExpired(params.data.exp) && params.data.closeTradeDate.length === 0) {
          return { backgroundColor: '#f9cdaa' };  // Apply bold style if the condition is met
      } else {
          return null;  // Return null for default styling
      }
    },
    cellRenderer: (params) => {
        // Use value formatter in cell renderer for consistency
        return params.valueFormatted;
    }
  },
  {
    headerName: "Profit / Loss $", 
    field: "profitLossDollars", 
    sortable: true, 
    filter: 'agNumberColumnFilter', 
    minWidth: 50, 
    maxWidth: 130, 
    editable: false, 
    resizable: true,
    valueGetter: (params) => {
        // Ensure the value is a number, provide 0 as a fallback
        return parseFloat(params.data.profitLossDollars) || 0;
    },
    valueFormatter: (params) => {
        // Convert to string with two decimal places and append '%'
        return `${formatCurrency(params.value)}`;
    },
    cellStyle: (params) => {
      let breakEvenUp = (-1) * parseFloat(params.data.openCost) / (parseInt(params.data.contracts) * 100) + parseFloat(params.data.buyCallStrike);
      let breakEvenDown = parseFloat(params.data.buyPutStrike) - (-1) * parseFloat(params.data.openCost) / (parseInt(params.data.contracts) * 100);
      if ((parseFloat(params.data.lastSaleClose) > breakEvenUp ||
          parseFloat(params.data.lastSaleClose) < breakEvenDown) &&
          parseFloat(params.data.profitLossDollars) < 0 &&
          !isExpired(params.data.exp) && params.data.closeTradeDate.length === 0) {
          return { backgroundColor: '#f9cdaa' };  // Apply bold style if the condition is met
      } else {
          return null;  // Return null for default styling
      }
    },
    cellRenderer: (params) => {
        // Use value formatter in cell renderer for consistency
        return params.valueFormatted;
    }
  },
  {headerName: "Contracts", field: "contracts", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:100, editable: false, resizable:true, cellRenderer: row =>{
    if (typeof(row.data.exp) !== 'undefined') {
      if (isNaN(row.data.contracts)) {
        row.data.contracts=0;
        return row.data.contracts;
      } else {
          return row.data.contracts;
      }
    }
  },},
  {headerName: "Expiration", field: "exp", sortable: true, filter: 'agDateColumnFilter',minWidth:50, maxWidth:100, editable: true, resizable:true, valueGetter: row =>{
    if (typeof(row.data.exp) !== 'undefined') {
      printConsole("row: exp org = "+row.data.exp.toString());
      if (row.data.exp.toString().indexOf("GMT")>0) {
        var expiration=row.data.exp;
        expiration=expiration.toString().replace("GMT","");
        printConsole("row: expiration without GMT = "+expiration);
        expiration=getFormattedDate(new Date(expiration));
        printConsole("row: expiration formatted = "+expiration.toString());
        row.data.exp=expiration
        return expiration;
      } else {
          return row.data.exp;
      }
    }
  },},
  
  {headerName: "Sell Call Strike", field: "sellCallStrike", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:130, editable: false, resizable:true, /*cellRenderer: row =>{
    if (typeof(row.data.exp) !== 'undefined') {
      if (isNaN(row.data.sellCallStrike)) {
        row.data.sellCallStrike=0;
        return row.data.sellCallStrike;
      } else {
          return row.data.sellCallStrike;
      }
    }
  },*/
    valueGetter: (params) => {
    // Ensure the value is a number, provide 0 as a fallback
        return parseFloat(params.data.sellCallStrike) || 0;
    },
    valueFormatter: (params) => {
        // Convert to string with two decimal places and append '%'
        return `${formatCurrency(params.value)}`;
    },
    cellStyle: (params) => {
      if (parseFloat(params.data.lastSaleClose) > parseFloat(params.data.sellCallStrike)) {
          return { fontWeight: '900' };  // Apply bold style if the condition is met
      } else {
          return null;  // Return null for default styling
      }
    },
    cellRenderer: (params) => {
        // Use value formatter in cell renderer for consistency
        return params.valueFormatted;
    }
  },
  {headerName: "Buy Call Strike", field: "buyCallStrike", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:130, editable: false, cellClass: 'bordered-cell', resizable:true, 
    valueGetter: (params) => {
    // Ensure the value is a number, provide 0 as a fallback
        return parseFloat(params.data.buyCallStrike) || 0;
    },

    valueFormatter: (params) => {
      // Assuming formatCurrency is a function that formats the number as a currency string
      return formatCurrency(params.value);
    },
    cellStyle: (params) => {
        if (parseFloat(params.data.lastSaleClose) > parseFloat(params.data.buyCallStrike)) {
            return { fontWeight: '900' };  // Apply bold style if the condition is met
        } else {
            return null;  // Return null for default styling
        }
    },

    cellRenderer: (params) => {
      // Use value formatter in cell renderer for consistency
      return params.valueFormatted;
    }
  },
  {headerName: "Buy Put Strike", field: "buyPutStrike", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:130, editable: false, cellClass: 'bordered-cell', resizable:true, 
    valueGetter: (params) => {
    // Ensure the value is a number, provide 0 as a fallback
        return parseFloat(params.data.buyPutStrike) || 0;
    },
    valueFormatter: (params) => {
        // Convert to string with two decimal places and append '%'
        return `${formatCurrency(params.value)}`;
    },
    cellStyle: (params) => {
      if (parseFloat(params.data.lastSaleClose) < parseFloat(params.data.buyPutStrike)) {
          return { fontWeight: 'bold' };  // Apply bold style if the condition is met
      } else {
          return null;  // Return null for default styling
      }
    },
    cellRenderer: (params) => {
        // Use value formatter in cell renderer for consistency
        return params.valueFormatted;
    }
  },
  {headerName: "Sell Put Strike", field: "sellPutStrike", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:130, editable: false, resizable:true, /*cellRenderer: row =>{
    if (typeof(row.data.exp) !== 'undefined') {
      if (isNaN(row.data.sellPutStrike)) {
        row.data.sellPutStrike=0;
        return row.data.sellPutStrike;
      } else {
          return row.data.sellPutStrike;
      }
    }
  },*/
    valueGetter: (params) => {
    // Ensure the value is a number, provide 0 as a fallback
        return parseFloat(params.data.sellPutStrike) || 0;
    },
    valueFormatter: (params) => {
        // Convert to string with two decimal places and append '%'
        return `${formatCurrency(params.value)}`;
    },
    cellStyle: (params) => {
      if (parseFloat(params.data.lastSaleClose) < parseFloat(params.data.sellPutStrike)) {
          return { fontWeight: 'bold' };  // Apply bold style if the condition is met
      } else {
          return null;  // Return null for default styling
      }
    },
    cellRenderer: (params) => {
        // Use value formatter in cell renderer for consistency
        return params.valueFormatted;
    }
  },
  {headerName: "Open Cost", field: "openCost", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:120, editable: false, resizable:true, /*cellRenderer: row =>{
    if (typeof(row.data.exp) !== 'undefined') {
      if (isNaN(row.data.openCost)) {
        row.data.openCost=0;
        return row.data.openCost;
      } else {
          return row.data.openCost;
      }
    }
  },*/
    valueGetter: (params) => {
    // Ensure the value is a number, provide 0 as a fallback
        return parseFloat(params.data.openCost) || 0;
    },
    valueFormatter: (params) => {
        // Convert to string with two decimal places and append '%'
        return `${formatCurrency(params.value)}`;
    },
    cellRenderer: (params) => {
        // Use value formatter in cell renderer for consistency
        return params.valueFormatted;
    }
  },
  {headerName: "Close Cost", field: "closeCost", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:120, editable: false, resizable:true, /*cellRenderer: row =>{
    if (typeof(row.data.exp) !== 'undefined') {
      if (isNaN(row.data.closeCost)) {
        row.data.closeCost=0;
        return row.data.closeCost;
      } else {
          return row.data.closeCost;
      }
    }
  },*/
    valueGetter: (params) => {
    // Ensure the value is a number, provide 0 as a fallback
        return parseFloat(params.data.closeCost) || 0;
    },
    valueFormatter: (params) => {
        // Convert to string with two decimal places and append '%'
        return `${formatCurrency(params.value)}`;
    },
    cellRenderer: (params) => {
        // Use value formatter in cell renderer for consistency
        return params.valueFormatted;
    }
  },
  {headerName: "Close Trade Date", field: "closeTradeDate", sortable: true, sort: 'asc', filter: 'agDateColumnFilter', minWidth:50, maxWidth:100, editable: true, resizable:true, valueGetter: row =>{
    if (typeof(row.data.closeTradeDate) !== 'undefined') {
      printConsole("row: closeTradeDate org = "+row.data.closeTradeDate.toString());
      if (row.data.closeTradeDate.toString().indexOf("GMT")>0) {
        var closeTradeDate=row.data.closeTradeDate;
        closeTradeDate=closeTradeDate.toString().replace("GMT","");
        printConsole("row: closeTradeDate without GMT = "+closeTradeDate);
        closeTradeDate=getFormattedDate(new Date(closeTradeDate));
        printConsole("row: closeTradeDate formatted = "+closeTradeDate.toString());
        row.data.closeTradeDate=closeTradeDate
        return closeTradeDate;
      } else {
          return row.data.closeTradeDate;
      }
    }
  },},
  {headerName: "Max Gain", field: "maxGain", hide: true, valueGetter : row => {
    return row.data.maxLoss;
  },},
  {headerName: "VIX Last Sale", field: "vixLastSale", hide: true, valueGetter : row => {
    return row.data.vixLastSale;
  },},
  {headerName: "MKT Direction", field: "mktDirection", hide: true, valueGetter : row => {
    return row.data.mktDirection;
  },},
  {headerName: "VIX Change", field: "vixChange", hide: true, valueGetter : row => {
    return row.data.vixChange;
  },},
  
  {headerName: "Details", field: "details", minWidth:50, maxWidth:100, editable: false, resizable:true, cellRenderer: (params) => { // Notice 'params' here
    console.log("headerName Details : params.api.getColumns = ", params.api.getColumns());
    console.log("headerName Details : params.data = ", params.data);
    const handleClick = () => {
      let dataJson = params.data;
      handleEditClick(params.data); // Pass the row data
    };

    if (isNaN(params.data.details)) {
      return <span onClick={handleClick}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Edit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>; // Wrap in span for click
    } else {
      return <span onClick={handleClick}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Edit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>; 
    }
 
  }, 
}
];

const onSelectionChanged=() =>{
  printConsole ("Executing onSelectionChanged");
  let commitDelete="";
  let selectedRowsNumber=gridRef.current.api.getSelectedRows().length;
  printConsole("onSelectionChanged : gridRef.current.api.getSelectedRows().length = " + selectedRowsNumber);

  if (selectedRowsNumber>=numberOfChecked.current) {
    commitDelete="delete";
    numberOfChecked.current++;
  } else {
    commitDelete="commit";
    numberOfChecked.current--;
  }

  printConsole("onSelectionChanged : commitDelete = "+commitDelete);
 
  if (selectedRowsNumber > 0) {
    console.log("onSelectionChanged : gridRef = ", gridRef);
    console.log("onSelectionChanged : getSelectedNodes = ", gridRef.current.api.getSelectedNodes());
    console.log("onSelectionChanged : checkboxSelected = ", gridRef.current.checkboxSelected);
    console.log("onSelectionChanged : getDisplayedRowCount = "+gridRef.current.api.getDisplayedRowCount());
    const selectedRowIndex=gridRef.current.api.getFocusedCell().rowIndex;
    printConsole("onSelectionChanged : selectedRowIndex = " + selectedRowIndex);
    console.log("onSelectionChanged : getDisplayedRowAtIndex = ", gridRef.current.api.getDisplayedRowAtIndex(selectedRowIndex));

    const selectedRows = gridRef.current.api.getDisplayedRowAtIndex(selectedRowIndex).data;
    console.log("onSelectionChanged : selectedRows = ", selectedRows);

    if (commitDelete==="delete") {
      deleteRecIds.current.push(selectedRows.id);
    }

    if (commitDelete==="commit") {
      for (let delInd = 0; delInd < deleteRecIds.current.length; delInd++) {
        if (deleteRecIds.current[delInd] === selectedRows.id) {
          deleteRecIds.current.splice(delInd,1);
        }
      }
    }

    console.log("onSelectionChanged : deleteRecIds = ", deleteRecIds);
  }
}

async function commitChanges() {
  printConsole ("Executing commitChanges");
  if (deleteRecIds.current.length>0) {
    console.log("commitChanges : deleteRecIds.current = ", deleteRecIds.current);
    path = '/deleteIronCondorTransactions';
    
    let delArrStr = "";
    for (let delInd = 0; delInd < deleteRecIds.current.length; delInd++) {
      delArrStr = delArrStr + "'" + deleteRecIds.current[delInd] + "'";
      if (delInd < deleteRecIds.current.length - 1) {
        delArrStr = delArrStr + ",";
      }
    }

    let passApistr=path + "/" + delArrStr;
    console.log("commitChanges : passApistr = " + passApistr);
    await API.get(myAPI, passApistr)
    .then(async response => {
      await console.log("commitChanges : response = ", response);
      if (response.toString().indexOf("Successfully") > 0) {
        deleteRecIds.current = [];
        let recDelVal =recsWereDeleted * (-1);
        calledGetMyTransactions.current = false;
        setRecsWereDeleted(recDelVal);
         
      } else {
         setDisplayMainMessage(error.message);
         printConsole(error)
      }
   })
    .catch(error => {
       setDisplayMainMessage(error.message);
       printConsole(error)
    })

  }

}

function generateTimestamp() {
  //console.log("Executing generateTimestamp");
  const currentDate = new Date();
  let currentTimestamp = currentDate.getTime();
  let currentTimestampInt = parseInt(currentTimestamp);
  currentTimestampInt = parseInt(currentTimestampInt / 1000);
  currentTimestamp = currentTimestampInt.toString();
  //console.log('Current timestamp:', currentTimestamp);

  return currentTimestamp;
};

function addEmptyRow() {
  printConsole ("Executing addEmptyRow");

  selectedRecord.current = {};

  const fieldsToValidate = [
    'sellCallStrike', 'buyCallStrike', 'buyPutStrike', 'sellPutStrike',
    'sellCallOpenCost', 'buyCallOpenCost', 'buyPutOpenCost', 'sellPutOpenCost', 
    "sellCallOpenQuote", "buyCallOpenQuote", "buyPutOpenQuote", "sellPutOpenQuote", 
    "lastSale", "netAmount", "openCost",
    "sellCallCloseQuote", "sellCallCloseCost", "buyCallCloseQuote", "buyCallCloseCost",
    "buyPutCloseQuote", "buyPutCloseCost", "sellPutCloseQuote", "sellPutCloseCost",
    "lastSaleClose", "netAmountClose", "closeCost",
    "closeTradeDate", "desiredProfitPercent", "desiredNetAmount",
    "profitLossDollars", "profitLossPercent",
    "commissionFee", "maxLoss", "upper", "lower", "days"
  ];

  for (let field of fieldsToValidate) {
    selectedRecord.current[field] = 0;
  }

  selectedRecord.current["contracts"] = 1;
  selectedRecord.current["security"] = "";
  selectedRecord.current["exp"] = "";
  selectedRecord.current["openTradeDate"] = "";

  selectedRecord.current["id"] = generateTimestamp();

  selectedRecord.current["position"] = "";
  selectedRecord.current["email"] = email.current;
  selectedRecord.current["closeTradeDate"] = "";

  selectedRecord.current["paperTrade"] = false;

  selectedRecord.current["sellCallStrikeUpdate"] = false;
  selectedRecord.current["buyCallStrikeUpdate"] = false;
  selectedRecord.current["buyPutStrikeUpdate"] = false;
  selectedRecord.current["sellPutStrikeUpdate"] = false;

  setIsEditing(true);
}

function EditForm({ record, onSubmit, onReset }) {
  printConsole("Executing EditForm");
  let [formData, setFormData] = useState(record);
  let [showEditSubmitError, setShowEditSubmitError] = useState(false);
  let [displayMessage, setDisplayMessage] = useState();

  let initialDate = record.exp ? new Date(`${record.exp}T00:00:00`) : null;
  console.log("EditForm : exp : initialDate = " + initialDate);
  let [selectedExp, setSelectedExp] = useState(initialDate);

  initialDate = record.openTradeDate ? new Date(`${record.openTradeDate}T00:00:00`) : null;
  console.log("EditForm : openTradeDate : initialDate = " + initialDate);
  let [selectedOpenTradeDate, setSelectedOpenTradeDate] = useState(initialDate);

  initialDate = record.closeTradeDate ? new Date(`${record.closeTradeDate}T00:00:00`) : null;
  console.log("EditForm : closeTradeDate : initialDate = " + initialDate);
  let [selectedCloseTradeDate, setSelectedCloseTradeDate] = useState(initialDate);

  console.log("EditForm : record = ", record);

  const handleChange = (event) => {
    console.log("Executing handleChange");
    setShowEditSubmitError(false);
    console.log("handleChange : event = ", event);
    console.log("handleChange : formData = ", formData);

    const { type, name, value, checked } = event.target;

    let commissionClose = 0.65;

    //let targetValue = 0;
    let targetValue;
    if (type === "checkbox") {
      if (event.target.checked === true) {
        targetValue = true;
      } else {
        targetValue = false;
      }
    } else {
      targetValue = event.target.value;
    }
    console.log ("handleChange : event.target.name = " + event.target.name);
    console.log ("handleChange : targetValue = " + targetValue);

    let orgOpenCostFee = parseFloat(formData.openCost) - (parseFloat(formData.sellCallOpenCost) + parseFloat(formData.buyCallOpenCost) + parseFloat(formData.buyPutOpenCost) + parseFloat(formData.sellPutOpenCost));
    console.log("handleChange : orgOpenCostFee = " + orgOpenCostFee);

    // Cloning formData for manipulation
    let updatedFormData = {...formData};

    updatedFormData[event.target.name] = targetValue;
    console.log ("handleChange : updatedFormData = ", updatedFormData);

    if (event.target.name !== "security" && event.target.name !== "exp" && event.target.name !== "openTradeDate" && event.target.name !== "closeTradeDate") {
      console.log ("handleChange : event.target.name = ", event.target.name);
      
      if (updatedFormData.sellCallOpenCost.toString().trim() !== "" && updatedFormData.contracts.toString().trim() !== "")
        updatedFormData.sellCallOpenQuote = (parseFloat(updatedFormData.sellCallOpenCost) / parseFloat(updatedFormData.contracts) / 100).toFixed(2);
      else {
        updatedFormData.sellCallOpenQuote = 0;
      }
      if (updatedFormData.buyCallOpenCost.toString().trim() !== "" && updatedFormData.contracts.toString().trim() !== "") {
        updatedFormData.buyCallOpenQuote = (parseFloat(updatedFormData.buyCallOpenCost) / parseFloat(updatedFormData.contracts) / 100).toFixed(2);
      } else {
        updatedFormData.buyCallOpenQuote = 0;
      }
      if (updatedFormData.buyPutOpenCost.toString().trim() !== "" && updatedFormData.contracts.toString().trim() !== "") {
        updatedFormData.buyPutOpenQuote = (parseFloat(updatedFormData.buyPutOpenCost) / parseFloat(updatedFormData.contracts) / 100).toFixed(2);
      } else {
        updatedFormData.buyPutOpenQuote = 0;
      }
      if (updatedFormData.sellPutOpenCost.toString().trim() !== "" && updatedFormData.contracts.toString().trim() !== "") {
        updatedFormData.sellPutOpenQuote = (parseFloat(updatedFormData.sellPutOpenCost) / parseFloat(updatedFormData.contracts) / 100).toFixed(2);
      } else {
        updatedFormData.sellPutOpenQuote = 0;
      }
      
      let netAmount = (parseFloat(updatedFormData.sellCallOpenQuote) + parseFloat(updatedFormData.buyCallOpenQuote) + parseFloat(updatedFormData.sellPutOpenQuote) + parseFloat(updatedFormData.buyPutOpenQuote)).toFixed(2);
      
      if (updatedFormData.sellCallCloseCost.toString().trim() !== "" && updatedFormData.contracts.toString().trim() !== "") {
        updatedFormData.sellCallCloseQuote = (parseFloat(updatedFormData.sellCallCloseCost) / parseFloat(updatedFormData.contracts) / 100).toFixed(2);
      } else {
        updatedFormData.sellCallCloseQuote = 0;
      }
      if (updatedFormData.buyCallCloseCost.toString().trim() !== "" && updatedFormData.contracts.toString().trim() !== "") {
        updatedFormData.buyCallCloseQuote = (parseFloat(updatedFormData.buyCallCloseCost) / parseFloat(updatedFormData.contracts) / 100).toFixed(2);
      } else {
        updatedFormData.buyCallCloseQuote = 0;
      }
      if (updatedFormData.buyPutCloseCost.toString().trim() !== "" && updatedFormData.contracts.toString().trim() !== "") {
        updatedFormData.buyPutCloseQuote = (parseFloat(updatedFormData.buyPutCloseCost) / parseFloat(updatedFormData.contracts) / 100).toFixed(2);
      } else {
        updatedFormData.buyPutCloseQuote = 0;
      }
      if (updatedFormData.sellPutCloseCost.toString().trim() !== "" && updatedFormData.contracts.toString().trim() !== "") {
        updatedFormData.sellPutCloseQuote = (parseFloat(updatedFormData.sellPutCloseCost) / parseFloat(updatedFormData.contracts) / 100).toFixed(2);
      } else {
        updatedFormData.sellPutCloseQuote = 0;
      }

      let netAmountClose = (parseFloat(updatedFormData.sellCallCloseQuote) + parseFloat(updatedFormData.buyCallCloseQuote) + parseFloat(updatedFormData.sellPutCloseQuote) + parseFloat(updatedFormData.buyPutCloseQuote)).toFixed(2);

      let openCostFee = 0;
      if (updatedFormData.sellCallOpenCost.toString().trim() !== "" &&
        updatedFormData.buyCallOpenCost.toString().trim() !== "" &&
        updatedFormData.buyPutOpenCost.toString().trim() !== "" &&
        updatedFormData.sellPutOpenCost.toString().trim() !== ""
      ) {
          if (event.target.name === "includeCommissionFee" && event.target.checked === false) {
            openCostFee = (parseFloat(updatedFormData.sellCallOpenCost) + parseFloat(updatedFormData.buyCallOpenCost) + parseFloat(updatedFormData.buyPutOpenCost) + parseFloat(updatedFormData.sellPutOpenCost)).toFixed(2);
          } else if (event.target.name === "includeCommissionFee" && event.target.checked === true) {
              openCostFee = (parseFloat(updatedFormData.sellCallOpenCost) + parseFloat(updatedFormData.buyCallOpenCost) + parseFloat(updatedFormData.buyPutOpenCost) + parseFloat(updatedFormData.sellPutOpenCost) - parseFloat(updatedFormData.commissionFee)).toFixed(2);
          } else if (updatedFormData.includeCommissionFee === "true") {
              openCostFee = (parseFloat(updatedFormData.sellCallOpenCost) + parseFloat(updatedFormData.buyCallOpenCost) + parseFloat(updatedFormData.buyPutOpenCost) + parseFloat(updatedFormData.sellPutOpenCost) - parseFloat(updatedFormData.commissionFee)).toFixed(2);
          } else {
              openCostFee = (parseFloat(updatedFormData.sellCallOpenCost) + parseFloat(updatedFormData.buyCallOpenCost) + parseFloat(updatedFormData.buyPutOpenCost) + parseFloat(updatedFormData.sellPutOpenCost)).toFixed(2);
          }
        }
      
      console.log("handleChange : openCostFee = " + openCostFee);

      let closeCostFee = 0;
      if (updatedFormData.sellCallCloseCost.toString().trim() !== "" &&
        updatedFormData.buyCallCloseCost.toString().trim() !== "" &&
        updatedFormData.buyPutCloseCost.toString().trim() !== "" &&
        updatedFormData.sellPutCloseCost.toString().trim() !== ""
      ) {
        closeCostFee = (parseFloat(updatedFormData.sellCallCloseCost) + parseFloat(updatedFormData.buyCallCloseCost) + parseFloat(updatedFormData.buyPutCloseCost) + parseFloat(updatedFormData.sellPutCloseCost)).toFixed(2);
      }

      updatedFormData["netAmount"] = netAmount;
      updatedFormData["netAmountClose"] = netAmountClose;
      updatedFormData["openCost"] = openCostFee;
      updatedFormData["closeCost"] = closeCostFee;
      
      updatedFormData["profitLossDollars"] = (parseFloat(updatedFormData["openCost"]) + parseFloat(updatedFormData["closeCost"]));
      if (parseFloat(updatedFormData["openCost"]) !== 0) {
        updatedFormData["profitLossPercent"] = (parseFloat(updatedFormData["profitLossDollars"]) / parseFloat(updatedFormData["openCost"]) * 100) * (-1);
      } else {
        updatedFormData["profitLossPercent"] = 0;
      }
      updatedFormData["profitLossDollars"] = parseFloat(updatedFormData["profitLossDollars"]).toFixed(2);
      updatedFormData["profitLossPercent"] = parseFloat(updatedFormData["profitLossPercent"]).toFixed(2);
    }

    if (event.target.name === "desiredProfitPercent") {
      if (updatedFormData["desiredProfitPercent"] === "" ) {
        updatedFormData["desiredProfitPercent"] = 0.0;
      }
      let netAmount = (parseFloat(updatedFormData.sellCallOpenQuote) + parseFloat(updatedFormData.buyCallOpenQuote) + parseFloat(updatedFormData.sellPutOpenQuote) + parseFloat(updatedFormData.buyPutOpenQuote)).toFixed(2);
      let desiredNetAmount = 0;
      if (netAmount < 0) {
        desiredNetAmount = ((-1) * parseFloat(netAmount) * parseFloat(updatedFormData.desiredProfitPercent) / 100 + (-1) * parseFloat(netAmount)).toFixed(2);
      } else {
        desiredNetAmount = parseFloat(netAmount) * parseFloat(updatedFormData.desiredProfitPercent) / 100 + parseFloat(netAmount);
      }
      updatedFormData["desiredNetAmount"] = desiredNetAmount;
    }

    console.log("handleChange : after all changes : updatedFormData = ", updatedFormData);
    setFormData(updatedFormData);
  };

  function handleChangeExpDate(date) {
    printConsole("Executing handleChangeDate");
    console.log("handleChangeDate : date = ", date);

    if (!date) {
        setSelectedExp(null);
        setFormData(prevFormData => ({ ...prevFormData, exp: '' }));
        return;
    }

    // Format the date as YYYY-MM-DD string immediately upon selection
    const formattedDate = formatDate(date);
    console.log("handleChangeDate : formattedDate = ", formattedDate);

    // Update state with the formatted string
    setSelectedExp(new Date(date)); // This ensures DatePicker receives a Date object
    setFormData(prevFormData => ({ ...prevFormData, exp: formattedDate }));
  }

  function handleChangeOpenDate(date) {
    printConsole("Executing handleChangeDate");
    console.log("handleChangeDate : date = ", date);

    if (!date) {
        setSelectedOpenTradeDate(null);
        setFormData(prevFormData => ({ ...prevFormData, openTradeDate: '' }));
        return;
    }

    // Format the date as YYYY-MM-DD string immediately upon selection
    const formattedDate = formatDate(date);
    console.log("handleChangeDate : formattedDate = ", formattedDate);

    // Update state with the formatted string
    setSelectedOpenTradeDate(new Date(date)); // This ensures DatePicker receives a Date object
    setFormData(prevFormData => ({ ...prevFormData, openTradeDate: formattedDate }));
  }

  function handleChangeCloseDate(date) {
    printConsole("Executing handleChangeDate");
    console.log("handleChangeDate : date = ", date);

    if (!date) {
        setSelectedCloseTradeDate(null);
        setFormData(prevFormData => ({ ...prevFormData, closeTradeDate: '' }));
        return;
    }

    // Format the date as YYYY-MM-DD string immediately upon selection
    const formattedDate = formatDate(date);
    console.log("handleChangeDate : formattedDate = ", formattedDate);

    // Update state with the formatted string
    setSelectedCloseTradeDate(new Date(date)); // This ensures DatePicker receives a Date object
    setFormData(prevFormData => ({ ...prevFormData, closeTradeDate: formattedDate }));
  }

  function formatDate(dateString) {
    if (dateString !== "") {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 for zero-based months
      const day = date.getDate().toString().padStart(2, '0'); 
    
      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  }

  const handleEditSubmit = async (event) => {
    await printConsole("Executing handleEditSubmit");
    await console.log ("handleEditSubmit : formData = ", formData);

    const fieldsToValidateNumbers = [
      'sellCallStrike', 'buyCallStrike', 'buyPutStrike', 'sellPutStrike',
      'sellCallOpenCost', 'buyCallOpenCost', 'buyPutOpenCost', 'sellPutOpenCost', 'contracts',
      'sellCallCloseCost', 'buyCallCloseCost', 'buyPutCloseCost', 'sellPutCloseCost'
    ];

    const fieldsToValidateText = [
      'security', 'exp', 'openTradeDate'
    ];

    for (let field of fieldsToValidateText) {
      await console.log ("handleEditSubmit : formData[" + field + "] = " + formData[field]);
      if (!formData[field]) {
        setDisplayMessage(`Error: ${field} cannot be blank.`);
        setShowEditSubmitError(true);
        return; 
      }
    }

    for (let field of fieldsToValidateNumbers) {
      await console.log ("handleEditSubmit : formData[" + field + "] = " + formData[field]);
      if (formData[field] === "NaN" || formData[field].toString().trim() === "") {
        setDisplayMessage(`Error: ${field} cannot be blank.`);
        setShowEditSubmitError(true);
        return; 
      } 
      if (isNaN(parseFloat(formData[field]))) {
        setDisplayMessage(`Error: ${field} must be a number`);
        setShowEditSubmitError(true);
        return;
      }
      /*else if (parseFloat(formData[field]) === 0) {
        setDisplayMessage(`Error: ${field} cannot be 0`);
        setShowEditSubmitError(true);
        return;
      }*/
    }

    formData["security"] = formData["security"].toString().toUpperCase();

    if (formData["buyCallStrikeUpdate"]) {
      formData["buyCallStrikeUpdate"] = "false";
    } else {
      formData["buyCallStrikeUpdate"] = "true";
    }

    if (formData["sellCallStrikeUpdate"]) {
      formData["sellCallStrikeUpdate"] = "false";
    } else {
      formData["sellCallStrikeUpdate"] = "true";
    }

    if (formData["buyPutStrikeUpdate"]) {
      formData["buyPutStrikeUpdate"] = "false";
    } else {
      formData["buyPutStrikeUpdate"] = "true";
    }

    if (formData["sellPutStrikeUpdate"]) {
      formData["sellPutStrikeUpdate"] = "false";
    } else {
      formData["sellPutStrikeUpdate"] = "true";
    }
    await console.log ("handleEditSubmit : checking paperTrade");
    if (formData["paperTrade"]) {
      formData["paperTrade"] = "true";
    } else {
      formData["paperTrade"] = "false";
    }

    await console.log ("handleEditSubmit : checking includeCommissionFee");
    if (formData["includeCommissionFee"]) {
      formData["includeCommissionFee"] = "true";
    } else {
      formData["includeCommissionFee"] = "false";
    }

    await console.log("handleEditSubmit : formData = ", formData);
    path = '/saveIronCondorOptionsEdits';

    var passApistr=path + "/" + JSON.stringify(formData);
    printConsole("passApiStr = "+passApistr);
    await API.get(myAPI, passApistr)
       .then(async response => {
         await printConsole("MyPortfolioIronCondor : response = " + JSON.stringify(response));
         await printConsole(response);
         if (response.toString().indexOf("Successfully") > 0) {
            calledGetMyTransactions.current = false;
            await getMyTransactions(email.current)
            .then (()=>{
              setIsEditing(false);
            })
            
         } else {
            setShowEditSubmitError(true);
            setDisplayMessage(error.message);
            printConsole(error)
         }
      })
       .catch(error => {
          setShowEditSubmitError(true);
          setDisplayMessage(error.message);
          printConsole(error)
       })
    //setIsEditing(false); // Close the form
  };

  const handleEditReset = () => {
    printConsole("Executing handleEditReset");
    setShowEditSubmitError(false);
    console.log("handleEditReset : record = ", record);
    setFormData(record); // Set form data back to original values
  };

  const handleEditBack = () => {
    setShowEditSubmitError(false);
    setIsEditing(false);
  }

  return (
    <form onSubmit={handleEditSubmit}>
      {showEditSubmitError && (
        <div className="blink_me_red">{displayMessage}</div>
      )}
      <fieldset>
        <div className="form-row">
          <div className="form-group">
            <div className="form-field-container-major">
              <div className="edit-first-row">
                <strong><label htmlFor="security">Security:</label></strong>
                
                <strong><label htmlFor="exp">Expiration:</label></strong>
                
                <strong><label htmlFor="contracts">Contracts:</label></strong>
                
                <strong><label htmlFor="openTradeDate">Open Trade Date:</label></strong>

                <strong><label htmlFor="paperTrade">Paper Trade:</label></strong>
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <div className="form-field-container-single-row">
              <div className="edit-first-row">
                <input 
                  type="text" 
                  id="security" 
                  name="security"
                  value={formData.security} 
                  onChange={handleChange} 
                />  
                <DatePicker 
                  selected={selectedExp}
                  onChange={handleChangeExpDate}
                  dateFormat="yyyy-MM-dd"
                />
                <input 
                  type="text" 
                  id="contracts" 
                  name="contracts"
                  value={formData.contracts} 
                  onChange={handleChange} 
                />
                <DatePicker 
                  selected={selectedOpenTradeDate}
                  onChange={handleChangeOpenDate}
                  dateFormat="yyyy-MM-dd"
                />
                <input 
                  type="checkbox" 
                  id="paperTrade"
                  name="paperTrade"
                  checked={formData.paperTrade}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>  
      <br></br>
      <fieldset>
        <div className="form-row"> 
          <div className="form-group">
            <div className="form-field-container">
              <strong><label className="form-label"   htmlFor="lowerBreakeven">Lower Breakeven:</label></strong>
              <input 
                type="text" 
                id="lowerBreakeven" 
                name="lowerBreakeven"
                value={formData.lowerBreakeven} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label className="form-label"   htmlFor="upperBreakeven">Upper Breakeven:</label></strong>
              <input 
                type="text" 
                id="upperBreakeven" 
                name="upperBreakeven"
                value={formData.upperBreakeven} 
                onChange={handleChange} 
                readOnly
              />
            </div>
          </div>
        </div>  
      </fieldset>
      <br></br>
      <fieldset>
        <strong><legend>Open Trade:</legend></strong>
        <strong><legend> 
        <input 
          type="checkbox" 
          id="includeCommissionFee"
          name="includeCommissionFee"
          checked={formData.includeCommissionFee}
          onChange={handleChange}
        />
        &nbsp;Include Commission Fee:&nbsp;
        <input 
          type="text" 
          id="commissionFee" 
          name="commissionFee"
          value={formData.commissionFee} 
          onChange={handleChange} 
          readOnly
        />
        </legend></strong>

        
        <br></br>
        <div className="form-row"> 
          <div className="form-group">
            <div className="form-field-container">
              <strong><label className="form-label"   htmlFor="sellCallStrike">Sell Call Strike:</label></strong>
              <input 
                type="text" 
                id="sellCallStrike" 
                name="sellCallStrike"
                value={formData.sellCallStrike} 
                onChange={handleChange} 
              />
              <strong><label htmlFor="sellCallOpenQuote">Quote:</label></strong>
              <input 
                type="text" 
                id="sellCallOpenQuote" 
                name="sellCallOpenQuote"
                value={formData.sellCallOpenQuote} 
                //value = {parseFloat(formData.sellCallOpenCost) / parseInt(formData.contracts) / 100}
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="sellCallOpenCost">Cost:</label></strong>
              <input 
                type="text" 
                id="sellCallOpenCost" 
                name="sellCallOpenCost"
                value={formData.sellCallOpenCost} 
                onChange={handleChange} 
              />
              
            </div>
          </div>
        </div>
        <div className="form-row"> 
          <div className="form-group">
            <div className="form-field-container">
              <strong><label htmlFor="buyCallStrike">Buy Call Strike:</label></strong>
              <input 
                type="text" 
                id="buyCallStrike" 
                name="buyCallStrike"
                value={formData.buyCallStrike} 
                onChange={handleChange} 
              />
              <strong><label htmlFor="buyCallOpenQuote">Quote:</label></strong>
              <input 
                type="text" 
                id="buyCallOpenQuote" 
                name="buyCallOpenQuote"
                //value={parseFloat(formData.buyCallOpenCost) / parseInt(formData.contracts) / 100} 
                value = {formData.buyCallOpenQuote}
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="buyCallOpenCost">Cost:</label></strong>
              <input 
                type="text" 
                id="buyCallOpenCost" 
                name="buyCallOpenCost"
                value={formData.buyCallOpenCost} 
                onChange={handleChange} 
              />
              
            </div>
          </div>
        </div>  
        <div className="form-row"> 
          <div className="form-group">  
            <div className="form-field-container">
              <strong><label htmlFor="buyPutStrike">Buy Put Strike:</label></strong>
              <input 
                type="text" 
                id="buyPutStrike" 
                name="buyPutStrike"
                value={formData.buyPutStrike} 
                onChange={handleChange} 
              />
              <strong><label htmlFor="buyPutOpenQuote">Quote:</label></strong>
              <input 
                type="text" 
                id="buyPutOpenQuote" 
                name="buyPutOpenQuote"
                //value={parseFloat(formData.buyPutOpenCost) / parseInt(formData.contracts) / 100} 
                value={formData.buyPutOpenQuote}
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="buyPutOpenCost">Cost:</label></strong>
              <input 
                type="text" 
                id="buyPutOpenCost" 
                name="buyPutOpenCost"
                value={formData.buyPutOpenCost} 
                onChange={handleChange} 
              />
              
            </div>
          </div>
        </div>
        <div className="form-row"> 
          <div className="form-group">   
            <div className="form-field-container">  
              <strong><label htmlFor="sellPutStrike">Sell Put Strike:</label></strong>
              <input 
                type="text" 
                id="sellPutStrike" 
                name="sellPutStrike"
                value={formData.sellPutStrike} 
                onChange={handleChange} 
              />
              <strong><label htmlFor="sellPutOpenQuote">Quote:</label></strong>
              <input 
                type="text" 
                id="sellPutOpenQuote" 
                name="sellPutOpenQuote"
                //value={parseFloat(formData.sellPutOpenCost) / parseInt(formData.contracts) / 100} 
                value={formData.sellPutOpenQuote}
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="sellPutOpenCost">Cost:</label></strong>
              <input 
                type="text" 
                id="sellPutOpenCost" 
                name="sellPutOpenCost"
                value={formData.sellPutOpenCost} 
                onChange={handleChange} 
              />
              
            </div>
          </div>
        </div>  
        <br></br>
        <div className="form-row"> 
          <div className="form-group"> 
            <div className="form-field-container">
              <strong><label htmlFor="lastSale">Last Sale Open:</label></strong>
              <input 
                type="text" 
                id="lastSale" 
                name="lastSale"
                value={formData.lastSale} 
                onChange={handleChange} 
              />
              <strong><label htmlFor="netAmount">Net Amount Open:</label></strong>
              <input 
                type="text" 
                id="netAmount" 
                name="netAmount"
                value={formData.netAmount} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="openCost">Open Cost:</label></strong>
              <input 
                type="text" 
                id="openCost" 
                name="openCost"
                value={formData.openCost} 
                onChange={handleChange} 
                readOnly
              />
            </div>
          </div>
        </div>
        <br></br>
        <div className="form-row"> 
          <div className="form-group"> 
            <div className="form-field-container">
              <strong><label htmlFor="maxGain">Max Gain:</label></strong>
              <input 
                type="text" 
                id="maxGain" 
                name="maxGain"
                value={formData.maxGain} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="vixLastSale">VIX Last Sale:</label></strong>
              <input 
                type="text" 
                id="vixLastSale" 
                name="vixLastSale"
                value={formData.vixLastSale} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="vixChange">VIX Change %:</label></strong>
              <input 
                type="text" 
                id="vixChange" 
                name="vixChange"
                value={formData.vixChange} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="mktDirection">MKT Direction:</label></strong>
              <input 
                type="text" 
                id="mktDirection" 
                name="mktDirection"
                value={formData.mktDirection} 
                onChange={handleChange} 
                readOnly
              />
            </div>
          </div>
        </div>
        <br></br>
        <div className="form-row"> 
          <div className="form-group"> 
            <div className="form-field-container">
              <strong><label htmlFor="netVega">Net Vega:</label></strong>
              <input 
                type="text" 
                id="netVega" 
                name="netVega"
                value={formData.netVega} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="netDelta">Net Delta:</label></strong>
              <input 
                type="text" 
                id="netDelta" 
                name="netDelta"
                value={formData.netDelta} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="netGamma">Net Gamma:</label></strong>
              <input 
                type="text" 
                id="netGamma" 
                name="netGamma"
                value={formData.netGamma} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="netTheta">Net Theta:</label></strong>
              <input 
                type="text" 
                id="netTheta" 
                name="netTheta"
                value={formData.netTheta} 
                onChange={handleChange} 
                readOnly
              />
              
            </div>
          </div>
        </div>
      </fieldset>
      <br></br>
      <fieldset>
        <div className="trade-container">
          <div className="trade-header">
            <div className="close-trade-title">
              <strong><legend>Close Trade:</legend></strong>
            </div>
            <div className="stop-update-title">
              <strong><legend>Stop Update:</legend></strong>
            </div>
          </div>
          <div className="trade-fields">
            <br></br>
            <div className="form-row"> 
              <div className="form-group">  
                <div className="form-field-container">  
                  <div className="close-trade-strike-fields-left-margin">
                    <strong><label htmlFor="sellCallStrike">Buy Call Strike:</label></strong>
                    <input 
                      type="text" 
                      id="sellCallStrike" 
                      name="sellCallStrike"
                      value={formData.sellCallStrike} 
                      onChange={handleChange} 
                      readOnly
                    />
                    <strong><label htmlFor="buyCallCloseQuote">Quote:</label></strong>
                    <input 
                      type="text" 
                      id="buyCallCloseQuote" 
                      name="buyCallCloseQuote"
                      value={formData.buyCallCloseQuote} 
                      onChange={handleChange} 
                      readOnly
                    />
                    <strong><label htmlFor="buyCallCloseCost">Cost:</label></strong>
                    <input 
                      type="text" 
                      id="buyCallCloseCost" 
                      name="buyCallCloseCost"
                      value={formData.buyCallCloseCost} 
                      onChange={handleChange} 
                    />
                    <input 
                      type="checkbox" 
                      id="buyCallStrikeUpdate"
                      name="buyCallStrikeUpdate"
                      checked={formData.buyCallStrikeUpdate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row"> 
              <div className="form-group">  
                <div className="form-field-container">   
                  <div className="close-trade-strike-fields-left-margin">
                    <strong><label htmlFor="buyCallStrike">Sell Call Strike:</label></strong>
                    <input 
                      type="text" 
                      id="buyCallStrike" 
                      name="buyCallStrike"
                      value={formData.buyCallStrike} 
                      onChange={handleChange} 
                      readOnly
                    />
                    <strong><label htmlFor="sellCallCloseQuote">Quote:</label></strong>
                    <input 
                      type="text" 
                      id="sellCallCloseQuote" 
                      name="sellCallCloseQuote"
                      value={formData.sellCallCloseQuote} 
                      onChange={handleChange} 
                      readOnly
                    />
                    <strong><label htmlFor="sellCallCloseCost">Cost:</label></strong>
                    <input 
                      type="text" 
                      id="sellCallCloseCost" 
                      name="sellCallCloseCost"
                      value={formData.sellCallCloseCost} 
                      onChange={handleChange} 
                    />
                    <input 
                      type="checkbox" 
                      id="sellCallStrikeUpdate"
                      name="sellCallStrikeUpdate"
                      checked={formData.sellCallStrikeUpdate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row"> 
              <div className="form-group">  
                <div className="form-field-container">  
                  <div className="close-trade-strike-fields-left-margin">
                    <strong><label htmlFor="buyPutStrike">Sell Put Strike:</label></strong>
                    <input 
                      type="text" 
                      id="buyPutStrike" 
                      name="buyPutStrike"
                      value={formData.buyPutStrike} 
                      onChange={handleChange} 
                      readOnly
                    />
                    <strong><label htmlFor="sellPutCloseQuote">Quote:</label></strong>
                    <input 
                      type="text" 
                      id="sellPutCloseQuote" 
                      name="sellPutCloseQuote"
                      value={formData.sellPutCloseQuote} 
                      onChange={handleChange} 
                      readOnly
                    />
                    <strong><label htmlFor="sellPutCloseCost">Cost:</label></strong>
                    <input 
                      type="text" 
                      id="sellPutCloseCost" 
                      name="sellPutCloseCost"
                      value={formData.sellPutCloseCost} 
                      onChange={handleChange} 
                    />
                    <input 
                      type="checkbox" 
                      id="sellPutStrikeUpdate"
                      name="sellPutStrikeUpdate"
                      checked={formData.sellPutStrikeUpdate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>  
            <div className="form-row"> 
              <div className="form-group">   
                <div className="form-field-container">  
                  <div className="close-trade-strike-fields-left-margin">
                    <strong><label htmlFor="sellPutStrike">Buy Put Strike:</label></strong>
                    <input 
                      type="text" 
                      id="sellPutStrike" 
                      name="sellPutStrike"
                      value={formData.sellPutStrike} 
                      onChange={handleChange} readOnly
                    />
                    <strong><label htmlFor="buyPutCloseQuote">Quote:</label></strong>
                    <input 
                      type="text" 
                      id="buyPutCloseQuote" 
                      name="buyPutCloseQuote"
                      value={formData.buyPutCloseQuote} 
                      onChange={handleChange} 
                      readOnly
                    />
                    <strong><label htmlFor="buyPutCloseCost">Cost:</label></strong>
                    <input 
                      type="text" 
                      id="buyPutCloseCost" 
                      name="buyPutCloseCost"
                      value={formData.buyPutCloseCost} 
                      onChange={handleChange} 
                    />
                    <input 
                      type="checkbox" 
                      id="buyPutStrikeUpdate"
                      name="buyPutStrikeUpdate"
                      checked={formData.buyPutStrikeUpdate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="form-row"> 
              <div className="form-group"> 
                <div className="form-field-container">   
                  <strong><label htmlFor="lastSaleClose">Last Sale Close:</label></strong>
                  <input 
                    type="text" 
                    id="lastSaleClose" 
                    name="lastSaleClose"
                    value={formData.lastSaleClose} 
                    onChange={handleChange} 
                  />
                  <strong><label htmlFor="netAmountClose">Net Amount Close:</label></strong>
                  <input 
                    type="text" 
                    id="netAmountClose" 
                    name="netAmountClose"
                    value={formData.netAmountClose} 
                    onChange={handleChange} 
                    readOnly
                  />
                  <strong><label htmlFor="openCost">Close Cost + Fee:</label></strong>
                  <input 
                    type="text" 
                    id="openCost" 
                    name="openCost"
                    value={formData.closeCost} 
                    onChange={handleChange} 
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="form-row"> 
              <div className="form-group"> 
                <div className="form-field-container">  
                  <strong><label htmlFor="closeTradeDate">Close Trade Date:</label></strong>

                  <DatePicker 
                    selected={selectedCloseTradeDate}
                    onChange={handleChangeCloseDate}
                    dateFormat="yyyy-MM-dd"
                  />

                  <strong><label htmlFor="desiredProfitPercent">Desired Profit %:</label></strong>
                  <input 
                    type="text" 
                    id="desiredProfitPercent" 
                    name="desiredProfitPercent"
                    value={formData.desiredProfitPercent} 
                    onChange={handleChange} 
                  />

                  <strong><label htmlFor="desiredNetAmount">Desired Net Amount:</label></strong>
                  <input 
                    type="text" 
                    id="desiredNetAmount" 
                    name="desiredNetAmount"
                    value={formData.desiredNetAmount} 
                    onChange={handleChange} 
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="form-row"> 
          <div className="form-group"> 
            <div className="form-field-container">
              <strong><label htmlFor="netVegaCurrent">Net Vega Close:</label></strong>
              <input 
                type="text" 
                id="netVegaCurrent" 
                name="netVegaCurrent"
                value={formData.netVegaCurrent} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="netDeltaCurrent">Net Delta Close:</label></strong>
              <input 
                type="text" 
                id="netDeltaCurrent" 
                name="netDeltaCurrent"
                value={formData.netDeltaCurrent} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="netGammaCurrent">Net Gamma Close:</label></strong>
              <input 
                type="text" 
                id="netGammaCurrent" 
                name="netGammaCurrent"
                value={formData.netGammaCurrent} 
                onChange={handleChange} 
                readOnly
              />
              <strong><label htmlFor="netThetaCurrent">Net Theta Close:</label></strong>
              <input 
                type="text" 
                id="netThetaCurrent" 
                name="netThetaCurrent"
                value={formData.netThetaCurrent} 
                onChange={handleChange} 
                readOnly
              />
              
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <br></br>
        <strong><legend>Profit / Loss:</legend></strong>
        <br></br>
        <div className="form-row"> 
          <div className="form-group">    
            <div className="form-field-container-single-row">
              <strong><label htmlFor="profitLossDollars">Profit / Loss Dollars:</label></strong>
              <strong><label htmlFor="profitLossPercent">Profit / Loss Percent:</label></strong>
            </div>
          </div>
        </div>
        <div className="form-row"> 
          <div className="form-group">    
            <div className="form-field-container-single-row">
              <input 
                type="text" 
                id="profitLossDollars" 
                name="profitLossDollars"
                value={formData.profitLossDollars} 
                onChange={handleChange} 
                readOnly
              />
              
              <input 
                type="text" 
                id="profitLossPercent" 
                name="profitLossPercent"
                value={formData.profitLossPercent} 
                onChange={handleChange} 
                readOnly
              />
            </div>
          </div>
        </div>
        <br />
        <strong><legend>Notes:</legend></strong>
        <br />
        <div className="form-row"> 
          <div className="form-group">    
            <div className="form-field-container-single-row">
              <textarea 
                id="notes" 
                name="notes" 
                value={formData.notes} 
                onChange={handleChange} 
                maxLength="500"
              />
            </div>
          </div>
        </div>
      </fieldset>
      <br></br>
      <input type="hidden" name="id" value={formData.id} /> 
      <fieldset>
      <div className="form-row-buttons"> 
        <Button className="buttonStyle" onClick={() => { handleEditSubmit(); } }>Submit</Button>
        <Button className="buttonStyle" onClick={() => { handleEditReset(); } }>Reset</Button>
        <Button className="buttonStyle" onClick={() => { handleEditBack(); } }>Back</Button>
      </div>
      </fieldset>
    </form>
  );
}

const handleEditClick = (record) => {
  selectedRecord.current = record;
  setIsEditing(true);
};

function onFirstDataRendered(params) {
  printConsole ("Executing onFirstDataRendered");
  console.log("onFirstDataRendered : params = ", params);
  //params.api.sizeColumnsToFit();
  var allColumnIds = [];
  params.api.getAllDisplayedColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
  });
  params.api.autoSizeColumns(allColumnIds);
}

  function onBtExport () {
    printConsole ("Executing onBtExport");
    gridRef.current.api.exportDataAsCsv();;
  };


  function isExpired(expiration) {
    console.log("Executing isExpired");

    const today = new Date();
  
    // Ensure all dates are compared at the start of the day for accuracy
    today.setHours(0, 0, 0, 0); 
  
    // Parse the expiration date string into a Date object
    console.log("isExpired : received expiration = " + expiration);
    //const expirationDate = new Date(expiration);
    const localDateTimeString = expiration + 'T00:00:00'; // Assume midnight
    const expirationDate = new Date(localDateTimeString);
    expirationDate.setHours(0, 0, 0, 0);
  
    // Check if expiration date is greater than today
    const isFutureDate = expirationDate >= today;
    console.log("isExpired : isFutureDate = " + isFutureDate);
    console.log("isExpired : expirationDate = " + expirationDate);
    console.log("isExpired : today = " + today);
  
    if (isFutureDate) {
      return false;
    } else {
      return true;
    }
  }

  function dateTimeNow() {
    asOfDateTime.current = new Date();
    return asOfDateTime.current.toLocaleString();
  }


  const [gridApiNew, setGridApiNew] = useState(null);

  const onGridReady = (params) => {
    setGridApiNew(params.api);
  };

  const applyLiveTradeFilter = () => {
    if (!gridApiNew) return;
    gridApiNew.onFilterChanged();
  };

  const isLiveTrade = (data) => {
    return !isExpired(data.exp) || data.closeTradeDate.toString().length === 0;
  };

  const onFilterChanged = () => {
    console.log ("onFilterChanged : gridApiNew = ", gridApiNew);
    gridApiNew.forEachNode(function(node) {
      node.setRowVisible(isLiveTrade(node.data));
    });
  };

// <button onClick={applyLiveTradeFilter}>Show Live Trades</button>

  return (
    
    <View className="AppFullScreen">
      {!isEditing ? (
        
          <div className="ag-theme-balham" style={{height:500}}>
           
            <div className="asOfDateTime">As Of {dateTimeNow()}</div>
            <div className="blink_me_red">{displayMainMessage}</div>
              <AgGridReact
                rowClassRules={rowClassRules}
                rowData={rowDataState}
                columnDefs={columns}
                suppressRowClickSelection={true}
                rowSelection={'multiple'}
                ref={gridRef}
                onGridReady={onGridReady}
                onFilterChanged={onFilterChanged}
                onFirstDataRendered={onFirstDataRendered}
                onSelectionChanged={onSelectionChanged}
              />
              <div className="info-fields-container">
                <div className="info-field">
                  <label>Unrealized Profit:</label>
                  <span style={{ color: parseFloat(unrealizedProfit.current) <= 0 ? 'red' : 'green' }}>{formatCurrency(unrealizedProfit.current)}</span> 
                </div>
                <div className="info-field">
                  <label>Realized Profit:</label>
                  <span style={{ color: parseFloat(realizedProfit.current) <= 0 ? 'red' : 'green' }}>{formatCurrency(realizedProfit.current)}</span>
                </div>
                <div className="info-field">
                  <label>Invested in Market:</label>
                  <span>{formatCurrency(investedInMarket.current)}</span>
                </div>
              </div>
              <div>
                <Button className="Button" onClick={() => { addEmptyRow(); } }>Add New Option Trade</Button>
                <Button className="Button" onClick={() => { commitChanges(); } }>Delete Selected Row(s)</Button>
              </div>
          </div>
        ) : (
          <EditForm 
            record={selectedRecord.current} 
            //onSubmit={handleEditSubmit()} 
            //onReset={handleEditReset()}
          />
        )
      }
    </View>
  );
};

export default withAuthenticator(MyPortfolioIronCondor);