import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Flex, TextField, Text, PasswordField, Button } from '@aws-amplify/ui-react';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  async function handleChangePassword(e) {
    e.preventDefault();

    // Basic client-side validation (adjust as needed)
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setIsLoading(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      setSuccess(true);
      setError('');
      // Optionally clear input fields or provide other feedback
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Flex direction="column" width="300px" padding="20px" backgroundColor="white" margin="auto"
        alignItems="center"
        justifyContent="center">
      {success && <Text variation="success">Password changed successfully!</Text>}

      {!success && (
        <form onSubmit={handleChangePassword}>
          <TextField 
            label="Old Password" 
            type="password" 
            margin="auto"
            alignItems="center"
            justifyContent="center"
            value={oldPassword} 
            onChange={(e) => setOldPassword(e.target.value)} 
          />
          <br></br>
          <PasswordField 
            label="New Password" 
            margin="auto"
            alignItems="center"
            justifyContent="center"
            value={newPassword} 
            onChange={(e) => setNewPassword(e.target.value)} 
          />
          <br></br>
          <PasswordField 
            label="Confirm New Password" 
            margin="auto"
            alignItems="center"
            justifyContent="center"
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
          />

          {error && <Text variation="error">{error}</Text>}
          <br></br>

          <Button isLoading={isLoading} type="submit" variation="primary">
            Change Password
          </Button>
        </form>
      )}
    </Flex>
  );
}

export default ChangePassword;