import React, { useState, useEffect, useRef } from "react";
import { Button } from "@aws-amplify/ui-react";
import { Auth, API } from 'aws-amplify';
import { printConsole } from './lib/utility';
import { downloadFile, uploadFile } from "./lib/common";
import './App.css';
import axios from 'axios';

const AiAnalysis = ({ signOut }) => {
  const [securities, setSecurities] = useState("");
  const [aiPrompt, setAiPrompt] = useState("");
  const [data, setData] = useState({"securities": "", "aiPrompt": ""});
  const [aiMessage, setAiMessage] = useState("");
  const [error, setError] = useState('');
  const [hide, setHide] = useState("hidden");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  //let aiResponse = useRef("");
  const [aiResponse, setAiResponse] = useState("");
  let mydata = useRef();

  useEffect(() => {
    console.log("Executing useEffect");
    console.log("useEffect : data = " + JSON.stringify(data));
    console.log("useEffect : submitted = " + submitted);
    if (submitted) {
      const urlStringSecurities = jsonToUrlString(data.securities);
      console.log("useEffect : urlStringSecurities = " + urlStringSecurities);
      const urlStringAiPrompt = jsonToUrlString(data.aiPrompt);
      console.log("useEffect : urlStringAiPrompt = " + urlStringAiPrompt);
      getAiAnalysis(urlStringSecurities, urlStringAiPrompt);
    }
    
  }, [submitted, data]);

  useEffect(() => {
    console.log("Executing useEffect aiResponse");
    if (aiResponse) {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.open();
        newWindow.document.write(aiResponse);
        newWindow.document.close();
      }
    }
  }, [aiResponse]);

  async function getAiAnalysis(securities, aiPrompt) {
    printConsole("Executing getAiAnalysis");
    const myAPI="mktappapi";
    //let path = '/chatgptanalysis'; 
    let path = 'https://o7vszrggwxfzgcyvnbgmpswvz40lmvkt.lambda-url.us-east-1.on.aws';
    //var passApiStr=await path + "/" + securities + "/" + aiPrompt;
    let passApistr=path + "/?securities=" + securities + "&aiprompt=" + aiPrompt;
    await printConsole("getAiAnalysis : passApiStr = "+ passApistr);
    //await Promise.resolve(await API.get(myAPI, passApiStr))
    try {
      await Promise.resolve(await axios.get(passApistr))
        .then(async resp =>{
          await printConsole("getAiAnalysis : resp = ", resp);
          setAiResponse(await resp.data);
          await printConsole("getAiAnalysis : aiResponse = " + JSON.stringify(aiResponse));
          setAiMessage("");
          setError("");
      })
      .catch(error => {
        setError(error.message);
        printConsole("getAiAnalysis : Error = " + error.message);
      }) 
    } catch (error) {}
  }

  const jsonToUrlString = (json) => {
    const params = encodeURIComponent(JSON.stringify(json));
    return params.toString();
  };

  const handleSubmit = () => {
    setSubmitted(true);
    setAiMessage("AI Is Analyzing...");
    printConsole ("handleSubmit : securities = " + securities + "\taiPrompt = " + aiPrompt);
    setData({"securities": securities, "aiPrompt": aiPrompt});
  };


  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30vh'
  };
  const inputStyle = {
    margin: '10px',
    padding: '10px',
    width: '300px',
    borderRadius: '5px',
    border: '1px solid #ddd'
  };

  const inputStyleTextArea = {
    margin: '10px',
    padding: '10px',
    width: '50ch',
    height: '5em',
    borderRadius: '5px',
    border: '1px solid #ddd'
  };

  const buttonStyle = {
      margin: '10px',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#4CAF50',
      color: 'white',
      cursor: 'pointer'
  };

  return (
    <div className="MenuStyle">

        <div className="blink_me">{aiMessage}</div>

          <form id="aiAnalysisForm" name="aiAnalysisForm" style={formStyle}>
            <label>
              <strong>Enter List Of Securities Through Comma</strong>
              <br />
              <input
                type="text"
                id="securities"
                name="securities"
                placeholder={data.securities}
                value={securities}
                style={inputStyle}
                onChange={(e) => {setSecurities(e.target.value); setAiMessage("")}}
              />
            </label>
            <br />
            <label>
              <strong>AI Prompt</strong> 
              <br />
              <textarea
                type="textarea"
                name="aiPrompt"
                placeholder={data.aiPrompt}
                value={aiPrompt}
                style={inputStyleTextArea}
                onChange={(e) => {setAiPrompt(e.target.value); setAiMessage("")}}
                cols={50}
                rows={5}
              />
            </label>
          </form>
          <Button className="Button" onClick={handleSubmit} style={buttonStyle}>Submit</Button>
          <textarea hidden={hide} placeholder={error} readOnly></textarea>
    </div>
  );
};

export default AiAnalysis;
