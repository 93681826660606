import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import Amplify, { Auth, Hub, Storage } from 'aws-amplify';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";



console.log("I am in AddStocks.js");

const AddStock = ({ signOut }) => {
  console.log("I am in AddStocks.js");
  const [stock, setStock]=useState();
  const [msg,setMsg]=useState();
  const [hide, setHide]=useState("hidden");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("useEffect : stock = "+stock);
  }, []);

  let initString;

  async function uploadFiles() {
    var file="stocks.json";
    await console.log("uploading = "+file);
    await console.log("uploading settingsStr = "+ JSON.stringify(initString));
    var MyBlob = new Blob([JSON.stringify(initString)], {type : 'application/json'});
    await new Promise((resolve, reject) => { 
      Storage.put(file, MyBlob, {
        resumable: true,
        level: "public",
        contentType: "text/plain",
        progressCallback: (event) => { console.log('progress event', event) },
        completeCallback: (event) => {
          console.log('complete event', event);
          resolve(event);
          setMsg("Uploaded to "+event.key + "\nData = " +stock);
          setHide("");
        },
        errorCallback: (err) => {
          console.log('error err', err)
          reject()
        }
      });
    }).catch((reason) => { console.log('catch reason', reason) })
  }

  async function downloadStocks() {
    console.log("In download stocks");
    var file="stocks.json";
    const result = await Storage.get(file, { level: 'public', download:true })

    await console.log(result.Body.text());
    await result.Body.text().then(string => { 
      console.log("download : data from file : string = "+string);
      initString=JSON.parse(string);
      console.log("download : before then : initString = "+JSON.stringify(initString));
      //if (JSON.stringify(stocks)!==JSON.stringify(initString)) {
        //console.log("I am in if \n stocks = "+JSON.stringify(stocks));
        //setStocks(initString);
      //}
      return initString;
    })
  }

  const add=()=> {
    console.log("I am in AddStocks.js->add function");
    console.log("add: stock = "+stock);
    downloadStocks()
    .then(() => {
      var i, stocksArr=[];
      console.log("add : downloadstocks : then stocks = "+JSON.stringify(initString));
      var stocksArr=initString["stocks"];
      var found=0;
      for (i=0; i<stocksArr.length; i++){
        if (stock.trim().toUpperCase()===stocksArr[i]) {
          found=1;
          i=stocksArr.length;
        }
      }
      if (found===0 ) {
        console.log("stock "+ stock + " is not in the list");
        stocksArr.push(stock.toUpperCase().trim());
        console.log("add : stocksArr = "+stocksArr);
        initString["stocks"]=stocksArr;
        console.log("add : initString = "+JSON.stringify(initString));
        console.log("add : before upload : stock = "+stock);
        uploadFiles();
      }
    })
  }

  return (
    <View className="AppFullScreen">
      <Heading level={1}>Add Security</Heading>
      <View margin="3rem 0" >
      
        <Flex direction="row" justifyContent="center">
        <form id="addForm" name="addForm">
          <TextField
            placeholder="stockName"
            label="Stock Name"
            labelHidden
            variation="quiet"
            required
            name="addStock"
            id="addStock"
            onChange={(e) => { console.log("e.target.value.stock = " + e.target.value); setHide("hidden"); setStock(e.target.value); console.log("onChange : stock = "+stock); } }
          />
          </form>
          <Button onClick={()=>{add()}}>
            Add Security
          </Button>
        </Flex>
      
      </View>
      <View>
        <Flex direction="column" justifyContent="center">

          <Button margin="auto" onClick={() => { navigate("/"); } }>
            Home
          </Button>
          <View margin="auto" >
            <textarea hidden={hide} placeholder={msg} readOnly></textarea>
          </View>
        </Flex>
      </View>
    </View>
  );
};

export default withAuthenticator(AddStock);