export function printConsole (message) {
    //const timestamp = new Date().toLocaleTimeString();
    const date = new Date();
    const timeString = date.toLocaleTimeString();
    const milliseconds = date.toISOString().slice(-3); // Extract milliseconds
    const formattedTime = `${timeString}.${milliseconds}`;
    /*if (typeof(message) === 'object') {
        message = JSON.stringify(message);
    }*/
    console.log(`[${formattedTime}] ${message}`);
}