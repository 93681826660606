import Amplify, { Auth, Hub, Storage, API } from 'aws-amplify';
import { printConsole } from './utility'; 
import React from "react";
import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';

const myAPI="mktappapi";
let path = '/strategy';

let recLst=[];
let indRecLst=0;

let maxChg="";
let avgChg="";
let wAvgChg="";
let profitResponse="";
let maxChgArrStr=[];
let maxChgArrNum=[];
let chgPercentCnt=0;
let sumChgPercent=0;
let wSumChgPercent=0;
let wSum=0;
let cnt33=0;
let cnt66=0;
let cnt100=0;

export function gridColumns(listOfSymbolsWithDetails, rowData, selectedRowIndex, activeButton){
  printConsole ("gridColumns : activeButton = " + activeButton);
  class CustomCellRenderer extends React.Component {
    render() {

      const { value } = this.props;
      const safeValueSymbol = escapeHtml(value);
      let info = getSymInfo(listOfSymbolsWithDetails, safeValueSymbol);
      let exchange = info.exchange;
      let name = escapeHtml(info.name);
      let description = info.description;
      if (description.length > 0){
        description = escapeHtml(info.description);
      } 
      let symType = escapeHtml(info.type);
      let symTitle;
      if (description.length > 0) {
        symTitle = symType + "\n" + name + "\n" + description;
      } else {
        symTitle = symType + "\n" + name;
      }
      const safeValueExchange = escapeHtml(exchange);
      //console.log(listOfSymbolsWithDetails[0]["symbol"]);
      //console.log ("gridColumns : received details for symbols = " + listOfSymbolsWithDetails.length);
  
      return (
        <a
          href={`https://www.google.com/finance/quote/${safeValueSymbol}:${safeValueExchange}?window=1Y`}
          target="_blank"
          rel="noopener noreferrer"
          title={symTitle}
        >
          {safeValueSymbol}
        </a>
      );
    }
  }

  function createDetails (selectedRowIndex) {
    printConsole("Executing createDetails");
    return (
      <div>
        <div>v</div>
        <div>Rating - {rowData[selectedRowIndex].analystRating} <span style={{ marginLeft: '10px' }}></span>Annual Profit% - {rowData[selectedRowIndex].annualProfit}</div>
        <div>Earnings - {rowData[selectedRowIndex].earningsDate} <span style={{ marginLeft: '10px' }}></span>Max % Chg - {rowData[selectedRowIndex].maxChange}</div>
        <div>Stock Offer - {rowData[selectedRowIndex].stockOffer} <span style={{ marginLeft: '10px' }}></span>Update Time - {rowData[selectedRowIndex].dbTimestamp}</div>
        <div>Opt Bid - {rowData[selectedRowIndex].bid} <span style={{ marginLeft: '10px' }}></span>Opt Offer - {rowData[selectedRowIndex].offer}</div>
        <div>Open Int - {rowData[selectedRowIndex].openInterest} <span style={{ marginLeft: '10px' }}></span>Volume - {rowData[selectedRowIndex].volume}</div>
        <div>Implied Vol - {rowData[selectedRowIndex].impliedVol} <span style={{ marginLeft: '10px' }}></span>Implied Vol For Period - {rowData[selectedRowIndex].impliedPeriod}</div>
        <div>34% Reaching Min Price - {rowData[selectedRowIndex].minPrice} <span style={{ marginLeft: '10px' }}></span>34% Reaching Max Price - {rowData[selectedRowIndex].maxPrice}</div>
      </div>
    );
  }

  function printData(rowIndex, selectedRowIndex, params) {
    printConsole("Executing printData");
    console.log (params);
    printConsole("printData : typeof(rowData[selectedRowIndex]) = " + typeof(rowData[selectedRowIndex]) + "\ttypeof(selectedRowIndex) = " + typeof(selectedRowIndex));
    if (typeof(rowData[selectedRowIndex]) === "undefined" || typeof(selectedRowIndex) === "undefined") {
      return ">";
    } else if (rowIndex === selectedRowIndex) {
      printConsole("printData : rowData[selectedRowIndex].expanded = " + rowData[selectedRowIndex].expanded);
      printConsole("printData : typeof(rowData[selectedRowIndex].details) = " + typeof(rowData[selectedRowIndex].details));
      if (rowData[selectedRowIndex].expanded === false) {
        return ">"
      } else {
        return createDetails(selectedRowIndex);
      }
    } else {
      if (rowData[rowIndex].expanded === false) {
        return ">"
      } else {
        return createDetails(rowIndex);
      }
    }
  }

  class MyDetailRenderer extends React.Component {
    render() {
      printConsole("Executing MyDetailRenderer");

      const { params, rowIndex, selectedRowIndex } = this.props; // Accessing from props

      printConsole("MyDetailRenderer : selectedRowIndex = " + selectedRowIndex);
      printConsole("MyDetailRenderer : rowIndex = " + rowIndex);

      if (rowIndex === selectedRowIndex) {
        printConsole("MyDetailRenderer condition met");
      }  
      return (
          <div>{printData(rowIndex, selectedRowIndex, params)}</div>
      ) 
    }
  };

  function escapeHtml(unsafe) {
    return unsafe.replace(/[&<"']/g, match => ({
        '&': '&amp;',
        '<': '&lt;',
        '"': '&quot;',
        "'": '&#39;'
    }[match]));
  }

  function getExchange(listOfSymbolsWithDetails, symbol) {
    let exchange = "NASDAQ";
    for (var symInd = 0; symInd < listOfSymbolsWithDetails.length; symInd++) {
      let dbSymbol = listOfSymbolsWithDetails[symInd]["symbol"].toString();
      if (dbSymbol === symbol) {
        exchange = listOfSymbolsWithDetails[symInd]["exchange"].toString();
        symInd = listOfSymbolsWithDetails.length;
      }
    }
    return exchange;
  }
  function getSymInfo(listOfSymbolsWithDetails, symbol) {
    let info = {};
    let name = "";
    let description = "";
    let exchange = "";
    let type = "";
    for (var symInd = 0; symInd < listOfSymbolsWithDetails.length; symInd++) {
      let dbSymbol = listOfSymbolsWithDetails[symInd]["symbol"].toString();
      if (dbSymbol === symbol) {
        exchange = listOfSymbolsWithDetails[symInd]["exchange"].toString();
        name = listOfSymbolsWithDetails[symInd]["name"].toString();
        description = listOfSymbolsWithDetails[symInd]["description"].toString();
        type = listOfSymbolsWithDetails[symInd]["type"].toString();
        symInd = listOfSymbolsWithDetails.length;
      }
    }
    if (description.indexOf("blank") >= 0) {
      description = "";
    }
    info = {"symbol":symbol, "name":name, "description":description, "type":type, "exchange":exchange};
    return info;
  }
  let grid = [];
  if (activeButton.toString().toLowerCase() === "inthemoney") {
    grid=[{headerName: "Save", field: "save", pinned: true, headerCheckboxSelection: false, checkboxSelection: true, minWidth:10, maxWidth:80, resizable: true, cellRenderer: 'agGroupCellRenderer'},
      {headerName: "Security", field: "security", sortable: true, filter: true, pinned: true, minWidth:10, maxWidth:100, resizable: true, cellRenderer: CustomCellRenderer,},
      {headerName: "Exp", field: "expiration", sortable: true, filter: 'agDateColumnFilter',minWidth:10, maxWidth:100, resizable: true},
      {headerName: "Rank", field: "ranking", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:200, resizable: true},
      {headerName: "Earnings", field: "earningsDate", sortable: true, filter: 'agDateColumnFilter', minWidth:10, maxWidth:100, resizable: true},
      {headerName: "Strike", field: "strike", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:80, resizable: true},
      {headerName: "Profit %", field: "profit", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:89, resizable: true},
      {headerName: "Depth %", field: "depth", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:92, resizable: true},
      {headerName: "Weighted Avg %", field: "wAvgChange", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:140, resizable: true},
      {headerName: "AI Short", field: "aiAnalysis", minWidth: 100, maxWidth: 150, resizable: true, sortable: false, cellRenderer: (params) => {
        const { security, expiration, strike, profit, depth, wAvgChange } = params.data;
        const analysisUrl = `https://yv6e4pdcsv6ddz5un4mn6gw6ea0dwwyu.lambda-url.us-east-1.on.aws/security=${security}&expiration=${expiration}&strike=${strike}&profit=${profit}&depth=${depth}&weightedavg=${wAvgChange}&ai=short`;
        return (
          <a
            href={analysisUrl}
            target="_blank"
            rel="noopener noreferrer"
            title = {`Analysis ${security}`}
          >
            Analysis
          </a>
        );
      }},
      {headerName: "AI Full", field: "aiAnalysis", minWidth: 100, maxWidth: 150, resizable: true, sortable: false, cellRenderer: (params) => {
        const { security, expiration, strike, profit, depth, wAvgChange } = params.data;
        const analysisUrl = `https://yv6e4pdcsv6ddz5un4mn6gw6ea0dwwyu.lambda-url.us-east-1.on.aws/security=${security}&expiration=${expiration}&strike=${strike}&profit=${profit}&depth=${depth}&weightedavg=${wAvgChange}&ai=full`;
        return (
          <a
            href={analysisUrl}
            target="_blank"
            rel="noopener noreferrer"
            title = {`Analysis ${security}`}
          >
            Analysis
          </a>
        );
      }},
      {headerName: '>', field: "details", minWidth:50, maxWidth:50, resizable: true, sortable: false,},
    ]
  }
  if (activeButton.toString().toLowerCase() === "outofthemoney") {
    grid=[{headerName: "Save", field: "save", pinned: true, headerCheckboxSelection: false, checkboxSelection: true, minWidth:10, maxWidth:80, resizable: true, cellRenderer: 'agGroupCellRenderer'},
      {headerName: "Security", field: "security", sortable: true, filter: true, pinned: true, minWidth:10, maxWidth:100, resizable: true, cellRenderer: CustomCellRenderer,},
      {headerName: "Exp", field: "expiration", sortable: true, filter: 'agDateColumnFilter',minWidth:10, maxWidth:100, resizable: true},
      {headerName: "Rank", field: "ranking", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:200, resizable: true},
      {headerName: "Earnings", field: "earningsDate", sortable: true, filter: 'agDateColumnFilter', minWidth:10, maxWidth:100, resizable: true},
      {headerName: "Strike", field: "strike", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:80, resizable: true},
      {headerName: "Profit $", field: "profit", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:89, resizable: true},
      {headerName: "Depth %", field: "depth", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:92, resizable: true},
      {headerName: "Weighted Avg %", field: "wAvgChange", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:140, resizable: true},
      {headerName: "AI Short", field: "aiAnalysis", minWidth: 100, maxWidth: 150, resizable: true, sortable: false, cellRenderer: (params) => {
        const { security, expiration, strike, profit, depth, wAvgChange } = params.data;
        const analysisUrl = `https://yv6e4pdcsv6ddz5un4mn6gw6ea0dwwyu.lambda-url.us-east-1.on.aws/security=${security}&expiration=${expiration}&strike=${strike}&profit=${profit}&depth=${depth}&weightedavg=${wAvgChange}&ai=short`;
        return (
          <a
            href={analysisUrl}
            target="_blank"
            rel="noopener noreferrer"
            title = {`Analysis ${security}`}
          >
            Analysis
          </a>
        );
      }},
      {headerName: "AI Full", field: "aiAnalysis", minWidth: 100, maxWidth: 150, resizable: true, sortable: false, cellRenderer: (params) => {
        const { security, expiration, strike, profit, depth, wAvgChange } = params.data;
        const analysisUrl = `https://yv6e4pdcsv6ddz5un4mn6gw6ea0dwwyu.lambda-url.us-east-1.on.aws/security=${security}&expiration=${expiration}&strike=${strike}&profit=${profit}&depth=${depth}&weightedavg=${wAvgChange}&ai=full`;
        return (
          <a
            href={analysisUrl}
            target="_blank"
            rel="noopener noreferrer"
            title = {`Analysis ${security}`}
          >
            Analysis
          </a>
        );
      }},
      {headerName: '>', field: "details", minWidth:50, maxWidth:50, resizable: true, sortable: false, 
        /*cellRenderer: (params) => (
          <MyDetailRenderer
              params = {params}
              rowIndex = {params.rowIndex}
              selectedRowIndex = {selectedRowIndex} // Make sure this value is passed correctly
          />
        )*/},
    ]
  }
  if (activeButton.toString().toLowerCase() === "longterm") {
    grid=[
      {headerName: "Security", field: "security", sortable: true, filter: true, pinned: true, minWidth:10, maxWidth:100, resizable: true, cellRenderer: CustomCellRenderer,},
      {headerName: "Last Sale", field: "lastSale", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:100, resizable: true},
      {headerName: "Market Cap", field: "marketCap", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:140, resizable: true,
        valueFormatter: params => {
          const value = params.value;
          // Check if value is a valid number
          if (!Number.isNaN(parseFloat(value))) {
            return value.toLocaleString(); // Convert to locale string with commas
          } else {
            return value; // Return as is if not a number
          }
        }
      },
      {headerName: "PE Ratio", field: "peRatio", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:100, resizable: true},
      {headerName: "Dividend Yield", field: "dividendYield", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:140, resizable: true},
      {headerName: "52-Week Low", field: "fiftyTwoWeekLow", sortable: true, filter: 'agDateColumnFilter',minWidth:10, maxWidth:120, resizable: true},
      {headerName: "52-Week High", field: "fiftyTwoWeekHigh", sortable: true, filter: 'agDateColumnFilter',minWidth:10, maxWidth:130, resizable: true},
      {headerName: "Last Sale % 52-Week High", field: "downFiftyTwoWeekChange", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:220, resizable: true},
      {headerName: "EPS", field: "eps", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:80, resizable: true},
      {headerName: "Rating", field: "rating", sortable: true, filter: true, pinned: true, minWidth:10, maxWidth:100, resizable: true},
      {headerName: "AI Short", field: "aiAnalysis", minWidth: 100, maxWidth: 150, resizable: true, sortable: false, cellRenderer: (params) => {
        const { security, expiration, strike, profit, depth, wAvgChange } = params.data;
        const analysisUrl = `https://yv6e4pdcsv6ddz5un4mn6gw6ea0dwwyu.lambda-url.us-east-1.on.aws/security=${security}&expiration=longterm&strike=${strike}&profit=${profit}&depth=${depth}&weightedavg=${wAvgChange}&ai=short`;
        return (
          <a
            href={analysisUrl}
            target="_blank"
            rel="noopener noreferrer"
            title = {`Analysis ${security}`}
          >
            Analysis
          </a>
        );
      }},
      {headerName: "AI Full", field: "aiAnalysis", minWidth: 100, maxWidth: 150, resizable: true, sortable: false, cellRenderer: (params) => {
        const { security, expiration, strike, profit, depth, wAvgChange } = params.data;
        const analysisUrl = `https://yv6e4pdcsv6ddz5un4mn6gw6ea0dwwyu.lambda-url.us-east-1.on.aws/security=${security}&expiration=longterm&strike=${strike}&profit=${profit}&depth=${depth}&weightedavg=${wAvgChange}&ai=full`;
        return (
          <a
            href={analysisUrl}
            target="_blank"
            rel="noopener noreferrer"
            title = {`Analysis ${security}`}
          >
            Analysis
          </a>
        );
      }},
      {headerName: '>', field: "details", minWidth:50, maxWidth:50, resizable: true, sortable: false,},
    ]
  }

    return grid;
}

export function gridGroupColumns() {
  let grid=[
    /*{headerName: "Rating", field: "analystRating", sortable: true, filter: 'agTextColumnFilter', minWidth:10, maxWidth:200, resizable: true},
    {headerName: "Earnings", field: "earningsDate", sortable: true, filter: 'agDateColumnFilter', minWidth:10, maxWidth:100, resizable: true},
    {headerName: "Exp", field: "expiration", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:100, resizable: true},
    {headerName: "Strike", field: "strike", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:80, resizable: true},
    {headerName: "Annual Profit %", field: "annualProfit", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:140, resizable: true},
    {headerName: "Max % Chg", field: "maxChange", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:120, resizable: true},
    {headerName: "Stock Offer", field: "stockOffer", sortable: true, filter: 'agNumberColumnFilter',minWidth:10, maxWidth:120, resizable: true},
    {headerName: "Opt Bid", field: "bid", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:100, resizable: true},
    {headerName: "Opt Offer", field: "offer", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:100, resizable: true},
    {headerName: "Open Int", field: "openInterest", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:95, resizable: true},
    {headerName: "Volume", field: "volume", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:85, resizable: true},
    {headerName: "Implied Vol", field: "impliedVol", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:110, resizable: true},
    {headerName: "Implied Vol For Period", field: "impliedPeriod", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:180, resizable: true},
    {headerName: "34% Reaching Min Price", field: "minPrice", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:190, resizable: true},
    {headerName: "34% Reaching Max Price", field: "maxPrice", sortable: true, filter: 'agNumberColumnFilter', minWidth:10, maxWidth:190, resizable: true},*/
    {field: "analystRating" },
    {field: "earningsDate"}
  ]
  return grid;
}

export const rowClassRules = {

    // row style function
    'strongGoodOption': function (params) {
      printConsole("Executing strongGoodOption");
      var expStr=params.data["expiration"];
      var dtExp=new Date(expStr).toUTCString().toString();
      var dtNow=new Date();
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : strongGoodOption : daysNowToExp = "+expDays);
      printConsole("rowClassRules : params = ");
      console.log(params);
      var depth = params.data["depth"];
      printConsole("rowClassRules : depth = "+depth);
      var profit = params.data["profit"];
      printConsole("rowClassRules : profit = "+profit);
      var adjProfit=parseFloat(profit)*30/expDays;
      printConsole("rowClassRules : adjusted profit = "+(parseFloat(profit)*30/expDays));
      var maxChange = params.data["maxChange"];
      printConsole("rowClassRules : maxChange = "+maxChange);
      var wAvgChange=params.data["wAvgChange"];
      printConsole("rowClassRules : wAvgChange = "+wAvgChange);
      var minDepth=parseFloat(wAvgChange)*0.75
      printConsole("rowClassRules : minDepth = "+minDepth);
      if (adjProfit >= 0.90 && depth >= wAvgChange) {
        printConsole ("rowClassRules : strongGoodOption : adjProfit = " + adjProfit + " --- profit = "+ profit + " --- depth = " + depth + " --- wAvgChange = " + wAvgChange);
      }
      return adjProfit >= 0.90 && depth >= wAvgChange;
    },
    'goodOption': function (params) {
      printConsole("Executing goodOption");
      var expStr=params.data["expiration"];
      var dtExp=new Date(expStr).toUTCString().toString();
      var dtNow=new Date();
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : goodOption : daysNowToExp = "+expDays);
      printConsole("rowClassRules : params = ");
      console.log(params);
      var depth = params.data["depth"];
      printConsole("rowClassRules : depth = "+depth);
      var profit = params.data["profit"];
      printConsole("rowClassRules : profit = "+profit);
      var adjProfit=parseFloat(profit)*30/expDays;
      printConsole("rowClassRules : adjusted profit = "+(parseFloat(profit)*30/expDays));
      var maxChange = params.data["maxChange"];
      printConsole("rowClassRules : maxChange = "+maxChange);
      var wAvgChange=params.data["wAvgChange"];
      printConsole("rowClassRules : wAvgChange = "+wAvgChange);
      var minDepth=parseFloat(wAvgChange)*0.75
      printConsole("rowClassRules : minDepth = "+minDepth);
      if (adjProfit >= 0.90 && depth >= minDepth && depth < wAvgChange) {
        printConsole ("rowClassRules : goodOption : adjProfit = " + adjProfit + " --- profit = "+ profit + " --- depth = " + depth + " --- minDepth = " + minDepth);
      }
      return adjProfit >= 0.90 && depth >= minDepth && depth < wAvgChange;
    },
    'mediumOption': function (params) {
      printConsole("Executing mediumOption");
      var expStr=params.data["expiration"];
      var dtExp=new Date(expStr).toUTCString().toString();
      var dtNow=new Date();
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : mediumOption : daysNowToExp = "+expDays);
      printConsole("rowClassRules : params = ");
      console.log(params);
      var depth = params.data["depth"];
      printConsole("rowClassRules : depth = "+depth);
      var profit = params.data["profit"];
      printConsole("rowClassRules : profit = "+profit);
      var adjProfit=parseFloat(profit)*30/expDays;
      printConsole("rowClassRules : adjusted profit = "+(parseFloat(profit)*30/expDays));
      var maxChange = params.data["maxChange"];
      printConsole("rowClassRules : maxChange = "+maxChange);
      var wAvgChange=params.data["wAvgChange"];
      printConsole("rowClassRules : wAvgChange = "+wAvgChange);
      var minDepth=parseFloat(wAvgChange)*0.75
      printConsole("rowClassRules : minDepth = "+minDepth);
      if (adjProfit >= 0.6 && adjProfit < 0.90 && depth >= minDepth) {
        printConsole ("rowClassRules : mediumOption : adjProfit = " + adjProfit + " --- profit = "+ profit + " --- depth = " + depth + " --- minDepth = " + minDepth);
      }
      return adjProfit >= 0.6 && adjProfit < 0.90 && depth >= minDepth;
    },
    // row style expression
    'badOption': '',
  
  };