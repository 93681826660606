import logo from './logo.svg';
import './App.css';
import Amplify, { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';

const myAPI = "api30769188"
const path = '/customers'; 

const EditSecurityMenu = ({signOut}) => {
  const [input, setInput] = useState("");
  const [customers, setCustomers] = useState([]);
  //const [activeButton, setActiveButton] = useState('inTheMoney');
  const { activeButton, setActiveButton } = useActiveButton();

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  const navigate = useNavigate();

  useEffect(()=>{
    navigate(input);
  },[input, activeButton])

//changed titles
  //Function to fetch from our backend and update customers array
  function getCustomer(e) {
    let customerId = e.input
    API.get(myAPI, path + "/" + customerId)
       .then(response => {
         console.log(response)
         let newCustomers = [...customers]
         newCustomers.push(response)
         setCustomers(newCustomers)

       })
       .catch(error => {
         console.log(error)
       })
  }

  return (
    
<div className='MenuStyle' style={{
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  justifyContent: 'center', 
  
}}>
  
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/settings"); 
      }}>
      Edit Profile
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/addSecurityInfo"); 
    }}>
      Add Security
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/editSecurityInfo"); 
    }}>
      Edit Security
    </Button>
  </div>
</div>
  )
}

export default EditSecurityMenu;