import "@aws-amplify/ui-react/styles.css";
import { Routes, Route} from "react-router-dom";
import React, { useEffect, useState } from 'react'
import Amplify, { Auth, Storage } from 'aws-amplify';

import {
  withAuthenticator,
  Button,
  Text,
} from "@aws-amplify/ui-react";
import MainMenu from "./MainMenu";
import Settings from "./Settings";
import List from "./List";
import Find from "./Find";
import AddStock from "./AddStock";
import Delete from "./Delete";
import RunOne from "./RunOne";
import RunAll from "./RunAll";
import RunMostWatched from "./RunMostWatched";
import RunMostActive from "./RunMostActive";
import RunDayGainers from "./RunDayGainers";
import RunDayLosers from "./RunDayLosers";
import RunWallStreetBets from "./RunWallStreetBets";
import MyPortfolio from "./MyPortfolio";
import RunAggressive from "./RunAggressive";
import RunSmall from "./RunSmall";
import RunUndervalued from "./RunUndervalued";
import RunUnderValuedGrowth from "./RunUnderValuedGrowth";
import RunGrowthTechnology from "./RunGrowthTechnology";
import { useRef } from "react";
import AWS from 'aws-sdk';
import RunCollection from "./RunCollection";
import EditSecurityMenu from "./EditSecurityMenu";
import { ActiveButtonProvider } from './ActiveButtonContext';
import AddSecurityInfo from "./AddSecurityInfo";
import EditSecurityInfo from "./EditSecurityInfo";
import MainMenuBuyWrite from "./MainMenuBuyWrite";
import IronCondorMenu from "./IronCondorMenu";
import GenerateOptionsLegs from "./GenerateOptionsLegs";
import IronCondorOptionsLegs from "./IronCondorOptionsLegs";
import MyPortfolioIronCondor from "./MyPortfolioIronCondor";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import MainMenuLongTerm from "./MainMenuLongTerm";
import RunOneLongTerm from "./RunOneLongTerm";
import RunAllLongTerm from "./RunAllLongTerm";
import RunCollectionLongTerm from "./RunCollectionLongTerm";
import FindEtfsForIc from "./FindEtfsForIc";
import FindIcETFsGrid from "./FindIcETFsGrid";
import AiAnalysis from "./AiAnalysis";
import Reports from "./Reports";
import IcPerformance from "./IcPerformance";

export const ScreenSizeContext = React.createContext({ width: 0, height: 0 });

function App({ signOut }) {
  
  let userStatus = useRef("pending");
  const [email, setEmail] = useState();
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = (newWidth, newHeight) => {
    console.log("Executing handleResize");
    console.log("handleResize : newWidth = " + newWidth);
    console.log("handleResize : newHeight = " + newHeight);
    setScreenSize({
      width: newWidth,
      height: newHeight,
    });
  };

  const getUserInfo = async () => {
    await Auth.currentSession();
    await console.log("getUserInfo : Auth.currentAuthenticatedUser = ", await Auth.currentAuthenticatedUser());
    const user = await Auth.currentAuthenticatedUser({
      attributes: ['custom:status', 'email', 'otherAttributes'],
    });
    
    try {
      userStatus.current = user.attributes["custom:status"];
      await console.log ("userStatus.current = " + userStatus.current);
    } catch (error) {
      console.log (error);
    }
    setEmail(user.attributes.email);
    await Storage.configure({ track: true });
  };

  useEffect(() => {
    const handleWindowResize = () => {
      handleResize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);

    console.log("useeffect : width = " + screenSize.width);
    console.log("useeffect : typeof(screenSize.width) = " + typeof(screenSize.width));

    if (screenSize.width > 768) {
      var newWidth = screenSize.width * 0.2;
      var newHeight = screenSize.height * 0.2;
      console.log("useeffect : newWidth = " + newWidth + "\tnewHeight = " + newHeight);
      console.log("useeffect : new screenSize.width = " + screenSize.width);

      // Use the new values to update the state
      setScreenSize({
        width: newWidth,
        height: newHeight,
      });
    }

    getUserInfo();


    console.log("useeffect : email=" + email);

    // Remove event listener on component unmount to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [email]);
  console.log("main : new screenSize.width = " + screenSize.width);

  if (userStatus.current === "approved") {
    return (
      <ScreenSizeContext.Provider value={screenSize}>
        <div>
          <ActiveButtonProvider>
            <Routes>
                <Route path="/" element={<MainMenu signOut={signOut}/>}/>
                <Route path="/mainMenuBuyWrite" element={<MainMenuBuyWrite signOut={signOut}/>} />
                <Route path="/mainMenuLongTerm" element={<MainMenuLongTerm signOut={signOut}/>} />
                <Route path="/ironCondorMenu" element={<IronCondorMenu signOut={signOut}/>} />
                <Route path="/generateOptionsLegs" element={<GenerateOptionsLegs icType=""/>} />
                <Route path="/generateOptionsLegsSpy" element={<GenerateOptionsLegs icType="SPY"/>} />
                <Route path="/generateOptionsLegsQqq" element={<GenerateOptionsLegs icType="QQQ"/>} />
                <Route path="/generateOptionsLegsIwm" element={<GenerateOptionsLegs icType="IWM"/>} />
                <Route path="/ironCondorOptionsLegs" element={<IronCondorOptionsLegs/>} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/list" element={<List />} />
                <Route path="/find" element={<Find />} />
                <Route path="/add" element={<AddStock />} />
                <Route path="/delete" element={<Delete />} />
                <Route path="/runOne" element={<RunOne />} />
                <Route path="/runOneLongTerm" element={<RunOneLongTerm />} />
                <Route path="/runAll" element={<RunAll />} />
                <Route path="/runAllLongTerm" element={<RunAllLongTerm />} />
                <Route path="/runMostWatched" element={<RunCollection externalCollection="mostwatched" />} />
                <Route path="/runMostWatchedLongTerm" element={<RunCollectionLongTerm externalCollection="mostwatched" />} />
                <Route path="/runMostActive" element={<RunCollection externalCollection="mostactive" />} />
                <Route path="/runMostActiveLongTerm" element={<RunCollectionLongTerm externalCollection="mostactive" />} />
                <Route path="/runDayGainers" element={<RunCollection externalCollection="daygainers" />} />
                <Route path="/runDayGainersLongTerm" element={<RunCollectionLongTerm externalCollection="daygainers" />} />
                <Route path="/runDayLosers" element={<RunCollection externalCollection="daylosers" />} />
                <Route path="/runDayLosersLongTerm" element={<RunCollectionLongTerm externalCollection="daylosers" />} />
                <Route path="/runWallStreetBets" element={<RunCollection externalCollection="wallstreetbets" />} />
                <Route path="/runWallStreetBetsLongTerm" element={<RunCollectionLongTerm externalCollection="wallstreetbets" />} />
                <Route path="/runAggressive" element={<RunCollection externalCollection="aggressive_small_caps" />} />
                <Route path="/runAggressiveLongTerm" element={<RunCollectionLongTerm externalCollection="aggressive_small_caps" />} />
                <Route path="/runSmall" element={<RunCollection externalCollection="small_cap_gainers" />} />
                <Route path="/runSmallLongTerm" element={<RunCollectionLongTerm externalCollection="small_cap_gainers" />} />
                <Route path="/runUndervalued" element={<RunCollection externalCollection="undervalued_large_caps" />} />
                <Route path="/runUndervaluedLongTerm" element={<RunCollectionLongTerm externalCollection="undervalued_large_caps" />} />
                <Route path="/runUnderValuedGrowth" element={<RunCollection externalCollection="undervalued_growth_stocks" />} />
                <Route path="/runUnderValuedGrowthLongTerm" element={<RunCollectionLongTerm externalCollection="undervalued_growth_stocks" />} />
                <Route path="/runGrowthTechnology" element={<RunCollection externalCollection="growth_technology_stocks" />} />
                <Route path="/runGrowthTechnologyLongTerm" element={<RunCollectionLongTerm externalCollection="growth_technology_stocks" />} />
                <Route path="/myPortfolio" element={<MyPortfolio />} />
                <Route path="/myPortfolioIronCondor" element={<MyPortfolioIronCondor />} />
                <Route path="/editSecurityMenu" element={<EditSecurityMenu />} />
                <Route path="/addSecurityInfo" element={<AddSecurityInfo />} />
                <Route path="/editSecurityInfo" element={<EditSecurityInfo />} />
                <Route path="/editProfile" element={<EditProfile />} />
                <Route path="/changePassword" element={<ChangePassword />} />
                <Route path="/findEtfsForIc" element={<FindEtfsForIc />} />
                <Route path="/findIcETFsGrid" element={<FindIcETFsGrid />} />
                <Route path="/aiAnalysis" element={<AiAnalysis />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/icPerformance" element={<IcPerformance />} />

            </Routes>
          </ActiveButtonProvider>
        </div>
      </ScreenSizeContext.Provider>
    );
  } else {
    return (
      <div>
        <center><b>You are not authorized to access TSPICKER yet.</b>
          <br></br>
          <Button style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={signOut}>
            <img src="exit.png" alt="Briefcase Icon" title="Sign Out" style={{ marginRight: '5px', width: '33%' }} />
          </Button>
        </center>
      </div>
      
    )
  }
}

export default withAuthenticator(App);