import React, { useState, useEffect, useRef } from "react";
import { Button } from "@aws-amplify/ui-react";
import { Auth, API } from 'aws-amplify';
import { printConsole } from './lib/utility';
import { downloadFile, uploadFile } from "./lib/common";
import './App.css';
import axios from 'axios';

const IcPerformance = ({ signOut }) => {
  const [securities, setSecurities] = useState("");
  const [aiPrompt, setAiPrompt] = useState("");
  let [data, setData] = useState({"ytdProfit": "", "oneYProfit": "", "lifeProfit": "", "annualizedProfit": ""});
  let [message, setMessage] = useState("Calculating Performance ...");
  const [error, setError] = useState('');
  const [hide, setHide] = useState("hidden");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  //let aiResponse = useRef("");
  const [aiResponse, setAiResponse] = useState("");
  let mydata = useRef();
  let email = useRef();

  useEffect(() => {
    console.log("Executing useEffect");
    new Promise(async (resolve, reject)=>{
      await Auth.currentAuthenticatedUser().then(async user=>{
        await printConsole("useEffect : setEmail : email with value = "+JSON.stringify(user.attributes.email));
        email.current = await JSON.stringify(user.attributes.email);
        await printConsole("useEffect : setEmail email = " + email.current);
      })
      .then(() => {
        console.log("useEffect : getIcPerformance");
        new Promise(async (resolve, reject)=>{
          await getIcPerformance(securities, aiPrompt)
        })
      })
      await setTimeout(() => resolve("done"), 3000);
    })
    
  }, []);

  async function getIcPerformance(securities, aiPrompt) {
    printConsole("Executing getIcPerformance");
    const myAPI="mktappapi";
    let path = '/icperformance'; 
    
    let passApiStr = await path + "/" + email.current;
    passApiStr = passApiStr.replace(/"/g, '');
    
    await printConsole("getIcPerformance : passApiStr = "+ passApiStr);

    try {
      await Promise.resolve(await API.get(myAPI, passApiStr))
        .then(async resp =>{
          await console.log("getIcPerformance : resp = ", resp);
          await setData(await resp);
          await printConsole("getIcPerformance : data = " + JSON.stringify(data));
          setMessage("");
          setError("");
      })
      .catch(error => {
        setError(error.message);
        printConsole("getIcPerformance : Error = " + error.message);
      }) 
    } catch (error) {}
  }

  const jsonToUrlString = (json) => {
    const params = encodeURIComponent(JSON.stringify(json));
    return params.toString();
  };


  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '40vh',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9'
  };

  const labelStyle = {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'column'
  };

  const valueStyle = {
    padding: '10px',
    width: '300px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    backgroundColor: '#e9e9e9'
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px'
  };

  const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px'
  };

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>Iron Condor Performance</div>
      <div className="blink_me">{message}</div>
      <form id="IcPerformanceForm" name="IcPerformanceForm" style={formStyle}>
        <div style={labelStyle}>
          <strong>YTD Profit</strong>
          <div style={valueStyle}>{data.ytdProfit}</div>
        </div>
        <div style={labelStyle}>
          <strong>Annualized Profit</strong>
          <div style={valueStyle}>{data.annualizedProfit}</div>
        </div>
        <div style={labelStyle}>
          <strong>1 Year Profit</strong>
          <div style={valueStyle}>{data.oneYProfit}</div>
        </div>
        <div style={labelStyle}>
          <strong>Life Time Profit</strong>
          <div style={valueStyle}>{data.lifeProfit}</div>
        </div>
      </form>
    </div>
  );
};

export default IcPerformance;
