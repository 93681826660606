import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import { useEffect, useState, useRef, useMemo } from 'react'
import Amplify, { Auth, Hub, Storage, API } from 'aws-amplify';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { printConsole } from './utility'; 
import axios from 'axios';

export async function sepElements(list){

  const myAPI="mktappapi";
  let path = '/strategy';

  let recLst=[];
  let indRecLst=0;

  let maxChg="";
  let avgChg="";
  let wAvgChg="";
  let profitResponse="";
  let maxChgArrStr=[];
  let maxChgArrNum=[];
  let chgPercentCnt=0;
  let sumChgPercent=0;
  let wSumChgPercent=0;
  let wSum=0;
  let cnt33=0;
  let cnt66=0;
  let cnt100=0;
  
  await printConsole("Executing sepElements");
  await printConsole("sepElements : list = "+JSON.stringify(list));
  path = '/maxhistoricchg';
  maxChg="";
  avgChg="";
  wAvgChg="";
  //daysNowToExp=list[0]["daysNowToExp"];
  var passApistr=path + "/" + list[0].stock.toString() + "/" + list[0]["twoYearsAgo"] + "/" + list[0]["today"] + "/" + list[0]["daysNowToExp"];
  printConsole("sepElement : passApistr = " +passApistr);
  await API.get(myAPI, passApistr)
    .then(resp =>{
      maxChg=resp;
      printConsole("sepElement : initial maxChg = "+maxChg);
      maxChgArrStr=maxChg.split(",");
      maxChgArrNum=[];
      for (var i=0; i<maxChgArrStr.length; i++) {
        maxChgArrNum.push(parseInt(maxChgArrStr[i]));
        sumChgPercent=sumChgPercent+parseInt(maxChgArrStr[i]);
        if (parseInt(maxChgArrStr[i])>=0 && parseInt(maxChgArrStr[i])<=33) {
          cnt33++;
        } else if (parseInt(maxChgArrStr[i])>=34 && parseInt(maxChgArrStr[i])<=66) {
          cnt66++;
        } else {
          cnt100++;
        }
        chgPercentCnt++;
      }
      maxChg=Math.max(...maxChgArrNum).toString();
      avgChg=Math.round((sumChgPercent/chgPercentCnt)).toString();
      var num;
      for (i=0; i<maxChgArrNum.length; i++){
        if (maxChgArrNum[i]>=0 && maxChgArrNum[i]<=33) {
          wSumChgPercent=wSumChgPercent+maxChgArrNum[i]*cnt33;
          wSum=wSum+cnt33;
        } else if (maxChgArrNum[i]>=34 && maxChgArrNum[i]<=66) {
          wSumChgPercent=wSumChgPercent+maxChgArrNum[i]*cnt66;
          wSum=wSum+cnt66;
        } else {
          wSumChgPercent=wSumChgPercent+maxChgArrNum[i]*cnt100;
          wSum=wSum+cnt100;
        }
      }
      wAvgChg=Math.round(wSumChgPercent/wSum);
      printConsole("sepElement : maxChg = " + maxChg);
      printConsole("sepElement : avgChg = " + avgChg);
      printConsole("sepElement : wAvgChg = " +wAvgChg);

      var count = Object.keys(list).length;
      var i;
      var tmpLst={};
      for (i=0; i<count;i++) {
        list[i]["maxChangePercent"]=maxChg;
        list[i]["weightedAvgChgPercent"]=wAvgChg.toString();
        list[i]["avgChgPercent"]=avgChg.toString();
        tmpLst=list[i];
        
        printConsole("sepElement : tmpLst = "+JSON.stringify(tmpLst));
        recLst[indRecLst]=tmpLst;
        indRecLst++;
        printConsole("i = "+i+"\tcount = "+count+"\tindRecLst = "+indRecLst);
        //setAnalysis(oldArray => [...oldArray, tmpLst]);
      }
      /*for (i=0; i<recLst.length; i++){
        await console.log ("sepElement : recLst[" + i + "] = " + JSON.stringify(recLst[i]));
        await setAnalysis(oldArray => [...oldArray, recLst[i]]);
      }
      await setAnalysis(recLst);*/
      //Promise.resolve(recLst)
      printConsole("printing recLst from sepElements function");
      printConsole(recLst);
      printConsole("finished printing recLst from sepElements");

      printConsole("sepElements : recLst length = "+recLst.length);
      

  })
  return await recLst;
  /*printConsole("sepElement : calculated : maxChg = " + maxChg);
  printConsole("sepElement : calculated : avgChg = " + avgChg);
  printConsole("sepElement : calculated : wAvgChg = " +wAvgChg);*/
    
}

export function loadRowData(strategyAnalysis, daysNowToExp, activeButton) {
  printConsole("Executing loadRowData");
  printConsole("loadRowData : activeButton = " + activeButton);
  let analysis = [];
  let rowdata = [];
  analysis = strategyAnalysis;
  let profit, adjProfit, depth, wAvgChange, minDepth;
  if (typeof(analysis)!='undefined') {
    printConsole ("loadRowData : analysis = "+ JSON.stringify(analysis));
    var key=0;
    var count = Object.keys(analysis).length;
    printConsole ("loadRowData : analysis length= "+count);
    var rank=0;
    for (key=0; key<count; key++) {
      try {
        profit=parseFloat(analysis[key]["profit"]);
        daysNowToExp=parseFloat(analysis[key]["daysNowToExp"]);
        adjProfit=profit*30/daysNowToExp;
        depth=parseFloat(analysis[key]["depth"]);
        wAvgChange=parseFloat(analysis[key]["weightedAvgChgPercent"]);
        minDepth=wAvgChange*0.75;
        if (adjProfit >= 0.9 && depth >= minDepth && depth < wAvgChange) {
          rank=2;
        } else if (adjProfit >= 0.6 && adjProfit <0.9 && depth >= minDepth) {
          rank=1;
        } else if (adjProfit >= 0.9 && depth >= wAvgChange){
          rank=3;
        } else {
          rank = 0;
        }
        if (analysis[key]["averageAnalystRating"].toString() === "null") {
          analysis[key]["averageAnalystRating"] = "";
        }
        if (analysis[key]["earningsTimestampStart"].toString() === "null") {
          analysis[key]["earningsTimestampStart"] = "";
        }
      } catch(err) { };
      if (activeButton.toString().toLowerCase() === "inthemoney") {
        rowdata[key]={
          id: key,
          expanded: false,
          security: analysis[key]["stock"],
          name: analysis[key]["name"],
          ranking: rank,
          analystRating: analysis[key]["averageAnalystRating"],
          details: "> ",
          stockOffer: parseFloat(analysis[key]["stockOffer"]),
          dbTimestamp: analysis[key]["dbTimestamp"],
          expiration: analysis[key]["expire"],
          strike: parseFloat(analysis[key]["strike"]),
          earningsDate: analysis[key]["earningsTimestampStart"],
          maxChange: parseFloat(analysis[key]["maxChangePercent"]),
          bid: parseFloat(analysis[key]["bid"]),
          offer: parseFloat(analysis[key]["ask"]),
          openInterest: parseInt(analysis[key]["openInterest"]),
          volume: parseInt(analysis[key]["volume"]),
          profit: parseFloat(analysis[key]["profit"]),
          annualProfit: parseFloat(analysis[key]["annualProfit"]),
          depth: parseFloat(analysis[key]["depth"]),
          wAvgChange: parseFloat(analysis[key]["weightedAvgChgPercent"]),
          impliedVol: parseFloat(analysis[key]["impliedVol"]),
          impliedPeriod: parseFloat(analysis[key]["impliedPeriod"]),
          minPrice: parseFloat(analysis[key]["minPrice"]),
          maxPrice: parseFloat(analysis[key]["maxPrice"])
          
        }
      }
      if (activeButton.toString().toLowerCase() === "outofthemoney") {
        rowdata[key]={
          id: key,
          expanded: false,
          security: analysis[key]["stock"],
          name: analysis[key]["name"],
          ranking: 0,
          analystRating: analysis[key]["averageAnalystRating"],
          details: "> ",
          stockOffer: parseFloat(analysis[key]["stockOffer"]),
          dbTimestamp: analysis[key]["dbTimestamp"],
          expiration: analysis[key]["expire"],
          strike: parseFloat(analysis[key]["strike"]),
          earningsDate: analysis[key]["earningsTimestampStart"],
          maxChange: parseFloat(analysis[key]["maxChangePercent"]),
          bid: parseFloat(analysis[key]["bid"]),
          offer: parseFloat(analysis[key]["ask"]),
          openInterest: parseInt(analysis[key]["openInterest"]),
          volume: parseInt(analysis[key]["volume"]),
          profit: parseFloat(analysis[key]["bid"]),
          annualProfit: parseFloat(analysis[key]["annualProfit"]),
          depth: parseFloat(analysis[key]["depth"]),
          wAvgChange: parseFloat(analysis[key]["weightedAvgChgPercent"]),
          impliedVol: parseFloat(analysis[key]["impliedVol"]),
          impliedPeriod: parseFloat(analysis[key]["impliedPeriod"]),
          minPrice: parseFloat(analysis[key]["minPrice"]),
          maxPrice: parseFloat(analysis[key]["maxPrice"])
        }
      }
      if (activeButton.toString().toLowerCase() === "longterm") {
        //console.log("loadRowData : inside longterm")
        let marketCap = 0;

        if (!isNaN(parseFloat(analysis[key]["marketCap"]))) { 
            marketCap = parseFloat(analysis[key]["marketCap"]);
        }
        //console.log("marketCap = "+marketCap );

        let dividendYield = 0;
        if (!isNaN(parseFloat(analysis[key]["dividendYield"]))) {
            dividendYield = parseFloat(analysis[key]["dividendYield"]);
        }

        let dividendRate = 0;
        if (!isNaN(parseFloat(analysis[key]["dividendRate"]))) {
            dividendRate = parseFloat(analysis[key]["dividendRate"]);
        }

        let forwardPE = 0;
        if (!isNaN(parseFloat(analysis[key]["forwardPE"]))) {
            forwardPE = parseFloat(analysis[key]["forwardPE"]);
        }

        let netExpenseRatio = 0;
        if (!isNaN(parseFloat(analysis[key]["netExpenseRatio"]))) {
            netExpenseRatio = parseFloat(analysis[key]["netExpenseRatio"]);
        }

        let ytdReturn = 0;  
        if (!isNaN(parseFloat(analysis[key]["ytdReturn"]))) {
            ytdReturn = parseFloat(analysis[key]["ytdReturn"]);
        }

        let peRatio = 0;
        if (!isNaN(parseFloat(analysis[key]["peRatio"]))) {
            peRatio = parseFloat(analysis[key]["peRatio"]);
        }

        let eps = 0;
        if (!isNaN(parseFloat(analysis[key]["eps"]))) {
            eps = parseFloat(analysis[key]["eps"]);
        }

        let priceToBook = 0;
        if (!isNaN(parseFloat(analysis[key]["priceToBook"]))) {
            priceToBook = parseFloat(analysis[key]["priceToBook"]);
        }

        let trailingAnnualDividendYield = 0;
        if (!isNaN(parseFloat(analysis[key]["trailingAnnualDividendYield"]))) {
          trailingAnnualDividendYield = parseFloat(analysis[key]["trailingAnnualDividendYield"]);
        }

        let lastSale = 0;
        if (!isNaN(parseFloat(analysis[key]["regularMarketPrice"]))) {
          lastSale = parseFloat(analysis[key]["regularMarketPrice"]);
        }

        let rating = "";
        try {
          rating = analysis[key]["averageAnalystRating"];
        } catch(err) { };

        let deepFiftyTwoWeeks = 0;
        try {
          if (!isNaN(parseFloat(analysis[key]["fiftyTwoWeekHigh"]))) {
            deepFiftyTwoWeeks = lastSale / parseFloat(analysis[key]["fiftyTwoWeekHigh"]) * 100;
            deepFiftyTwoWeeks = deepFiftyTwoWeeks.toFixed(2);
            deepFiftyTwoWeeks = parseFloat(deepFiftyTwoWeeks);
          }
        } catch(err) { };

        rowdata[key]={
          id: key,
          expanded: false,
          security: analysis[key]["symbol"],
          name: analysis[key]["longName"],
          lastSale: lastSale,
          marketCap: marketCap,
          peRatio: peRatio,
          netExpenseRatio: netExpenseRatio,
          dividendYield: dividendYield,
          dividendRate: dividendRate,
          fiftyTwoWeekLow: analysis[key]["fiftyTwoWeekLow"],
          fiftyTwoWeekHigh: analysis[key]["fiftyTwoWeekHigh"],
          downFiftyTwoWeekChange: deepFiftyTwoWeeks,
          eps: eps,
          rating: rating,
          regularMarketChange: parseFloat(analysis[key]["regularMarketChange"]),
          regularMarketVolume: parseFloat(analysis[key]["regularMarketVolume"]),
          averageDailyVolume3Month: parseFloat(analysis[key]["averageDailyVolume3Month"]),
          ytdReturn: ytdReturn,
          bid: parseFloat(analysis[key]["bid"]),
          offer: parseFloat(analysis[key]["ask"]),
          forwardPE: forwardPE,
          priceToBook: priceToBook,
          fiftyTwoWeekChangePercent: parseFloat(analysis[key]["fiftyTwoWeekChangePercent"]),
          trailingAnnualDividendYield: trailingAnnualDividendYield,
          details: "> ",
        }
      }
    }
    printConsole("loadRowData : rowdata.length = "+rowdata.length);
    
  }
  return rowdata;
}

let email="";

async function setEmail() {
  await printConsole("Executing setEmail");
    return (
      new Promise(async (resolve, reject)=>{
        await Auth.currentAuthenticatedUser().then(async user=>{
          await printConsole("setEmail : email with value = "+JSON.stringify(user.attributes.email));
          email = await user.attributes.email;
          await printConsole("setEmail email = "+email)
        })
        await setTimeout(() => resolve("done"), 3000);
      })
    )
}

export async function getUserInfo() {
  await printConsole("Executing getUserInfo");
  if (email === "" || typeof(email) === 'undefined'){
    await setEmail().then(async (res)=>{
      await console.log ("email in getUserInfo = " + email)
      await printConsole("res in getUserInfo = "+res);
    })
  }
  return email;
}

let initString = "";

export async function downloadFile(file) {
  await printConsole("Executing downloadFile");
  await printConsole("downloadFile : received file - " + file);
  let initString = {};
  const myAPI="mktappapi";
  let path = '/getProfile';
  var passApistr=path + "/" + file.toString();
  printConsole("downloadFile : passApistr = " +passApistr);
  await API.get(myAPI, passApistr)
  .then(async (result) => {
    initString = await result;
  })
  await printConsole ("downloadFile : initString = " + JSON.stringify(initString));
  return await initString;
}

export async function uploadFile(file, settingsStr) {
  let uploadResult;
  await printConsole("Executing uploadFile");
  await printConsole("uploadFile : received file - " + file);
  await printConsole("uploadFile : received settingsStr - " + JSON.stringify(settingsStr));

  const myAPI="mktappapi";
  let path = '/saveProfile';
  var passApistr=path + "/" + file.toString() + "/" + JSON.stringify(settingsStr);
  printConsole("uploadloadFile : passApistr = " +passApistr);
  await API.get(myAPI, passApistr)
  .then(async (result) => {
    await printConsole ("uploadFile : completed the upload");
    uploadResult = await "completed the upload";
  })
  .catch(error => {
    printConsole("uploadFile : error = " + error);
    uploadResult = error;
  })
  printConsole("uploadFile : uploadResult = " + uploadResult);
  return uploadResult;
}

export async function strategy(params, activeButton) {
  await printConsole("Executing strategy");
  await printConsole("newstrategy : params = "+params);
  await printConsole("newstrategy : activeButton = "+activeButton);
  let analysis = [];

  const myAPI="mktappapi";
  let path = '/newstrategy';
  //countOfResponses.current++;
  //await printConsole ("newstrategy : data = " + JSON.stringify(data));
  //await printConsole ("newstrategy : stocks.current[stocks] = " + stocks.current["stocks"]);

  var paramsJSON=JSON.parse(params);
  await printConsole ("newstrategy : paramsJSON = " + JSON.stringify(paramsJSON));

  var passApistr=path + "/" + paramsJSON.days + "/" + paramsJSON.daysOffset + "/" + paramsJSON.minDepth + "/" + paramsJSON.stocks.toString() + "/" + activeButton;
  printConsole("newstrategy : passApiStr = "+passApistr);
  await API.get(myAPI, passApistr)
  .then(async response => {
    await printConsole("newstrategy : response = " + JSON.stringify(response));
    analysis = await response;
    await printConsole("newstrategy : analysis.length = " + analysis.length);
    //setCounter(countOfResponses.current);
    var count = Object.keys(response).length;
    /*if (countOfResponses.current === numOfStocks.current && count === 0) {
    //setShowLoading(false);
    }*/
    /*if (count>0){
      //await sepElements(response);
      await loadRowData(analysis);
    }*/
    printConsole("newstrategy : analysis");
    console.log(analysis);
    
  })
  return analysis;
}

export async function strategyLongTerm(params) {
  await printConsole("Executing strategyLongTerm");
  await printConsole("strategyLongTerm : params = "+params);
  let analysis = [];

  const myAPI="mktappapi";
  let path = 'https://q6cbw7oapojfyvnodujsgcoi6i0qgdii.lambda-url.us-east-1.on.aws';

  var paramsJSON=JSON.parse(params);
  await printConsole ("strategyLongTerm : paramsJSON = " + JSON.stringify(paramsJSON));

  var passApistr=path + "/?securities=" + paramsJSON.stocks.toString();
  printConsole("strategyLongTerm : passApiStr = "+passApistr);
  await axios.get(passApistr)
  .then(async response => {
    await printConsole("strategyLongTerm : response = " + JSON.stringify(response));
    analysis = await response.data;
    await printConsole("strategyLongTerm : analysis.length = " + analysis.length);
    //setCounter(countOfResponses.current);
    var count = Object.keys(response).length;
    /*if (countOfResponses.current === numOfStocks.current && count === 0) {
    //setShowLoading(false);
    }*/
    /*if (count>0){
      //await sepElements(response);
      await loadRowData(analysis);
    }*/
    printConsole("strategyLongTerm : analysis");
    console.log(analysis);
    
  })
  return analysis;
}

export async function strategyetfs(params, activeButton) {
  await printConsole("Executing strategyetfs");
  await printConsole("strategyetfs : params = "+params);
  let analysis = [];

  const myAPI="mktappapi";
  let path = 'https://54rf6lz4j7lmrfvhjp7easidjm0nrsje.lambda-url.us-east-1.on.aws';

  var paramsJSON=JSON.parse(params);
  await printConsole ("strategyetfs : paramsJSON = " + JSON.stringify(paramsJSON));

  var passApistr=path + "/?days=" + paramsJSON.days + "&daysOffset=" + paramsJSON.daysOffset + "&minDepth=" + paramsJSON.minDepth + "&direction=" + activeButton + "&excludeLeveragedEtfs=" + paramsJSON.excludeLeveragedEtfs;
  printConsole("strategyetfs : passApiStr = "+passApistr);
  await axios.get(passApistr)
  //await API.get(myAPI, passApistr)
  .then(async response => {
    await printConsole("strategyetfs : response = " + JSON.stringify(response));
    analysis = await response.data;
    await printConsole("strategyetfs : analysis.length = " + analysis.length);
    printConsole("strategyetfs : analysis");
    console.log(analysis);
  })
  .catch(async error =>{
    printConsole ("strategyetfs : Error processing response");
    console.log(error);
  })
  return analysis;
}

async function listFiles() {
  let data = {};
  await printConsole("Executing listFiles");
  await printConsole("in listFiles");
  await printConsole("listFiles : email = "+email.current);
  await Storage.list('', { level: 'public' })
  .then(async ({ results }) => {
    await printConsole(results);
    if (typeof (email.current) !== "undefined"){
      await printConsole("listFiles : results size = "+results.length);
      var i;
      var found=false;
      for (i=0; i<results.length; i++){
        //await printConsole("listFiles : key " + i + " = "+results[i].key);
        if (results[i].key.toString().toLowerCase().trim()===email.current.toLowerCase().trim()){
          await printConsole("listFiles : emails matched = "+email.current);

          data = await JSON.parse(await downloadFile(email.current));
          i=results.length
          found=true;
        }
      }
      if (!found) {
        await printConsole("email was not found");
        data = await JSON.parse(await downloadFile("default.json"));
      }
    }
  })
  .catch((err) => printConsole(err.message));  
  return await data
}
