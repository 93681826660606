import logo from './logo.svg';
import './App.css';
import Amplify, { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';
import { printConsole } from './lib/utility';

const myAPI="mktappapi";
let path = '/addsecurityinfo'; 

const AddSecurityInfo = ({signOut}) => {
  printConsole("I am in AddSecurityInfo");
  const [input, setInput] = useState("");
  let [displayMessage, setDisplayMessage] = useState("");
  const [formData, setFormData] = useState({
    securitySymbol: '',
    securityName: '',
    type: '',
    description: '',
    exchange: ''
  });
  const { activeButton, setActiveButton } = useActiveButton();

  const handleSubmit = async (event) => {
    await printConsole("Executing handleSubmit");
    await setDisplayMessage("Adding...");
    await event.preventDefault();
    // Handle the form submission
    await console.log(formData); 
    let name = await encodeURI(formData.securityName);
    printConsole("name = " + name);
    let description = await encodeURI(formData.description)
    printConsole("description = " + description);
    var passApiStr=await path + "/" + formData.securitySymbol.toUpperCase().trim() + "/" + name.trim() + "/" + formData.type + "/" + description.trim() + "/" + formData.exchange;
    await printConsole("downloadStocks : passApiStr = "+ passApiStr);
    await Promise.resolve(await API.get(myAPI, passApiStr))
      .then(async resp =>{
        await setDisplayMessage(resp);
        await printConsole("downloadStocks : displayMessage = " + displayMessage);
    })
    .catch(error => {
      printConsole(error.message);
    }) 
  };

  const navigate = useNavigate();

  useEffect(()=>{
    navigate(input);
  },[displayMessage])

  const handleClear = () => {
    setFormData({
        securitySymbol: '',
        securityName: '',
        type: '',
        description: '',
        exchange: ''
    });
    setDisplayMessage("");
  };

  const handleChange = (event) => {
      setFormData({
          ...formData,
          [event.target.name]: event.target.value
      });
      setDisplayMessage("");
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40vh'
  };

  const inputStyle = {
      margin: '10px',
      padding: '10px',
      width: '300px',
      borderRadius: '5px',
      border: '1px solid #ddd'
  };

  const buttonStyle = {
      margin: '10px',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#4CAF50',
      color: 'white',
      cursor: 'pointer'
  };

  return (
    
    <form onSubmit={handleSubmit} style={formStyle}>
      <div>{(
        <div className="blink_me" style={{ fontSize: '18px' }}>{displayMessage}</div>
      )}</div>
        <input 
            type="text" 
            name="securitySymbol" 
            value={formData.securitySymbol} 
            onChange={handleChange} 
            required 
            placeholder="Security Symbol"
            style={inputStyle}
        />
        <input 
            type="text" 
            name="securityName" 
            value={formData.securityName} 
            onChange={handleChange} 
            required 
            placeholder="Security Name"
            style={inputStyle}
        />
         <select 
            name="type" 
            value={formData.type} 
            onChange={handleChange} 
            required
            style={inputStyle}
        >
          <option value="">Select Type</option>
          <option value="ETF">ETF</option>
          <option value="Stock">Stock</option>
        </select>
        <input 
            type="text" 
            name="description" 
            value={formData.description} 
            onChange={handleChange} 
            placeholder="Description"
            style={inputStyle}
        />
        <select 
            name="exchange" 
            value={formData.exchange} 
            onChange={handleChange} 
            required
            style={inputStyle}
        >
            <option value="">Select Exchange</option>
            <option value="NYSEARCA">NYSEARCA</option>
            <option value="NASDAQ">NASDAQ</option>
            <option value="NYSE">NYSE</option>
            <option value="BATS">BATS</option>
            <option value="NYSEAMERICAN">NYSEAMERICAN</option>
            <option value="TSE">TSE</option>
            <option value="OTCMKTS">OTCMKTS</option>
            <option value="ASX">ASX</option>
        </select>
        <div className="menu-row" style={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
          <button type="submit" style={buttonStyle}>Submit</button>
          <button type="button" onClick={handleClear} style={buttonStyle}>Clear</button>
        </div>
    </form>
  )
}

export default AddSecurityInfo;