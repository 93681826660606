import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef } from 'react'
import Amplify, { Auth, Hub, Storage, API } from 'aws-amplify';
import { Button, Text } from "@aws-amplify/ui-react";
import { printConsole } from './lib/utility'; 
import { downloadFile } from "./lib/common";
import { uploadFile } from "./lib/common";
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';


const GenerateOptionsLegs=({ icType })=> {

  console.log("GenerateOptionsLegs : icType = " + icType);

  let email = useRef();
  let days = useRef();
  const [forceRender, setForceRender]=useState(true);
  let daysOffset =useRef();
  let security = useRef();
  let upperBound = useRef();
  let lowerBound = useRef();
  let profitAlert = useRef();
  let contracts = useRef();
  let position = useRef("Long");
  let minDepth = useRef();
  let defaultIsClicked = useRef(false);
  let optionsLegs = useRef({});
  let shortBounds = useRef({});

  const [settingsStr, setSettingsStr]=useState({"days":"","security":"","upperBound":"", "lowerBound":"", "profitAlert":"", "contracts":""});
  const [data,setData]=useState(settingsStr);
  const [error,setError]=useState('');
  let [displayMessage, setDisplayMessage] = useState("");
  const [hide, setHide]=useState("hidden");
  const [submitted, setSubmitted]=useState(false);
  const [suggest, setSuggest] = useState(false);
  let [selectedExp, setSelectedExp] = useState(null);
  const daysInputRef = useRef(null);
  const dateInputRef = useRef(null);
  const location = useLocation();
  let passedSecurity = location.state?.security || "";
  let passedSecurityCounter = useRef(0);
  console.log("GenerateOptionsLegs : passedSecurity = " + passedSecurity);

  if (passedSecurity !== "" && data.security === "" && passedSecurityCounter.current ===0) {
    console.log("GenerateOptionsLegs : passedSecurity : data = ", data);
    //passedSecurity = {"security" : passedSecurity};
    passedSecurityCounter.current++;
    security.current = passedSecurity;
    //setData(passedSecurity);
    setData((prevData) => ({
      ...prevData,
      security: passedSecurity
    }));
  }

  let initString="";

  async function listFiles() {
    await printConsole("Executing listFiles");
    if (typeof(email.current) === 'undefined') {
      await getUserInfo();
    }
    //setData ( await JSON.parse(await downloadFile(email.current)));
  }

  async function getLegs() {
    printConsole("Executing getLegs");
    const myAPI="mktappapi";
    let path = '/getIronCondorOptionsLegs'; 
    printConsole("getLegs : data = ", data);
    
    data.profitAlert = data.profitAlert || 0;

    var passApiStr=await path + "/" + data.position.toUpperCase().trim() + "/" + data.days + "/" + data.security.toUpperCase().trim() + "/" + data.upperBound + "/" + data.lowerBound + "/" + data.profitAlert + "/" + data.contracts;
    await printConsole("getLegs : passApiStr = "+ passApiStr);
    await Promise.resolve(await API.get(myAPI, passApiStr))
      .then(async resp =>{
        await printConsole("getLegs : resp = " + resp);
        optionsLegs.current = await resp;
        await printConsole("getLegs : optionsLegs = " + JSON.stringify(optionsLegs.current));
        setError("");
    })
    .catch(error => {
      setError(error.message);
      printConsole("getLegs : Error = " + error.message);
    }) 
  }

  async function getShortBounds() {
    printConsole("Executing getShortBounds");
    const myAPI="mktappapi";
    let path = '/getIronCondorShortBounds'; 
    var passApiStr=await path + "/" + data.security.toUpperCase().trim() + "/" + data.days;
    await printConsole("getShortBounds : passApiStr = "+ passApiStr);
    await Promise.resolve(await API.get(myAPI, passApiStr))
      .then(async resp =>{
        await printConsole("getShortBounds : resp = " + resp);
        shortBounds.current = await resp;
        await printConsole("getShortBounds : shortBounds = " + JSON.stringify(shortBounds.current));

        upperBound.current = shortBounds.current.upperBound;
        lowerBound.current = shortBounds.current.lowerBound;

        setData(prevData => ({
          ...prevData,
          upperBound: shortBounds.current.upperBound,
          lowerBound: shortBounds.current.lowerBound
        }));

        setError("");
    })
    .catch(error => {
      setError(error.message);
      printConsole("getShortBounds : Error = " + error.message);
    }) 
  }

  useEffect(()=>{
    
      printConsole ("Executing useEffect");
      if (typeof(email.current) === 'undefined') {
        getUserInfo();
      }
      printConsole("useEffect : init = "+JSON.stringify(initString));
      executeSettingsForm();
      
      printConsole("useEffect : submitted = " + submitted)
      if (submitted) {
        Promise.resolve(getLegs())
        .then((resp)=>{
          printConsole("useEffect : optionsLegs = " + JSON.stringify(optionsLegs.current));

          if (error === "" && Object.keys(optionsLegs.current).length !== 0) {
            navigate("/ironCondorOptionsLegs", { state: { optionsLegs: optionsLegs.current } });
          }
        })
        .catch((error)=>{
          printConsole("useEffect : error = ");
          setError(error.message);
          console.log(error);
        })
      }

      if (suggest) {
        Promise.resolve(getShortBounds())
        .then((resp)=>{
          printConsole("useEffect : shortBounds = " + JSON.stringify(shortBounds.current));

          if (error === "" && Object.keys(shortBounds.current).length !== 0) {
            setSuggest(false);
            //navigate("/ironCondorOptionsLegs", { state: { optionsLegs: optionsLegs.current } });
          }
        })
        .catch((error)=>{
          printConsole("useEffect : error = ");
          setError(error.message);
          console.log(error);
        })
      }
      if (icType === "") {
        handleInputChange();
      }
    //}
  },[forceRender, submitted, settingsStr, data, suggest]);

  async function getUserInfo() {
    await printConsole("Executing getUserInfo");
    return (
      await new Promise((resolve, reject)=>{
        Auth.currentAuthenticatedUser().then(user=>{
          printConsole("getUserInfo : email = "+JSON.stringify(user.attributes.email));
          email.current = user.attributes.email;
          printConsole("getUserInfo : email = "+email.current);
        })
        setTimeout(() => resolve("done"), 1000);
      })
    )
  }

  const navigate = useNavigate();
  printConsole("Executing GenerateOptionsLegs");

  const executeSettingsForm=async ()=>{
    await getUserInfo().then(()=>{
      printConsole("executeSettingsForm : data.days = "+ data.days);
      if (typeof(email.current) === 'undefined') {
        getUserInfo();
      }
      listFiles();
    })
  };

  printConsole("main : settingsStr = "+JSON.stringify(settingsStr));
  printConsole("main : data = "+JSON.stringify(data));
  printConsole("submitted = " + submitted);
  printConsole("forceRender = " + forceRender);
  if (defaultIsClicked.current) {
    //setData ({"days":"","daysOffset":"10","minDepth":"10"})
  }

  const handleSuggest = () => {
    setSuggest(true);
  }

  useEffect (()=>{
    printConsole("useEffect : icType = " + icType);
    if (icType !== "") {
      days.current = 30;
      security.current = icType;
      upperBound.current = 5;
      lowerBound.current = 0.1;
      profitAlert.current = 0;
      contracts.current = 1;
      position.current = "LONG";
      if (!submitted) {
        handleSubmit();
      }
    }
  })

  const handleSubmit = ()=>{
    printConsole("Executing handleSubmit");

    if (displayMessage === "") {
      setDisplayMessage("Getting IC Data ...");
    }
    
    printConsole("handleSubmit : submitted = "+submitted);
    printConsole("handleSubmit : days = " + days.current);
    printConsole("handleSubmit : security = " + security.current);
    printConsole("handleSubmit : upperBound = " + upperBound.current);
    printConsole("handleSubmit : lowerBound = " + lowerBound.current);
    printConsole("handleSubmit : profitAlert = " + profitAlert.current);
    printConsole("handleSubmit : contracts = " + contracts.current);
    printConsole("handleSubmit : position = " + position.current);
    if (!days.current || !security.current || !upperBound.current || !lowerBound.current || !contracts.current) {
      setError("Please fill in all fields");
      return;
    }

    setError('');

    setSubmitted(true);
    printConsole("handleSubmit : submitted = "+submitted);
    printConsole("handleSubmit : days = " + days.current);
    printConsole("handleSubmit : security = " + security.current);
    printConsole("handleSubmit : upperBound = " + upperBound.current);
    printConsole("handleSubmit : lowerBound = " + lowerBound.current);
    printConsole("handleSubmit : profitAlert = " + profitAlert.current);
    printConsole("handleSubmit : contracts = " + contracts.current);
    printConsole("handleSubmit : position = " + position.current);
  
    
    setData({"days":days.current,"security":security.current,"upperBound":upperBound.current, "lowerBound":lowerBound.current, "profitAlert":profitAlert.current, "position":position.current, "contracts":contracts.current, "email":email.current});
    console.log("handleSubmit - data = " + JSON.stringify(data));

  }

  function clearValues() {
    printConsole("Executing clearValues")
    const clearedData = { days: "", security: "", upperBound: "", lowerBound: "", profitAlert: "", contracts: "" };
    days.current = "";
    security.current = "";
    upperBound.current = "";
    lowerBound.current = "";
    profitAlert.current = "";
    contracts.current = "";
    position.current = "Long";
    console.log("clearValues: Before update, data =", data); 
    
    //setData(clearedData);
    setData({
      ...data,
      days: "",
      security: "",
      upperBound: "",
      lowerBound: "",
      profitAlert: "",
      contracts: "",
      position: "Long" // Reset position in state to 'Long'
    });
    setSettingsStr(clearedData);

    console.log("clearValues: After update, data =", data); 
  }

  const canSuggest = data.position === "Short" && data.security && data.days;

  const handleChangeExpDate = (date) => {
    console.log ("Executing handleChangeExpDate");

    setSelectedExp(date);
    let expDays = countDays(date);
    console.log("handleChangeExpDate : expDays = " + expDays);

    if (date) {
      setData(prevData => ({ ...prevData, days: '' }));
      //setData({ ...data, days: expDays });
      days.current = expDays;
    }
  };

  const handleInputChange = (e) => {
    let name = null;
    let value = null;
    try {
      name = e.target.name;
      value = e.target.value;
    } catch (e) {}
    /*setData(prevData => ({
      ...prevData,
      [name]: value
    }));*/
  
    // Clear the expiration date if days are entered
    if (name === 'days' && value) {
      setSelectedExp(null);
    }
    
  };

  function countDays(expiration) {
    console.log("Executing countDays");

    const today = new Date();
  
    // Ensure all dates are compared at the start of the day for accuracy
    today.setHours(0, 0, 0, 0); 
  
    // Parse the expiration date string into a Date object
    console.log("countDays : received expiration = " + expiration);
    //const expirationDate = new Date(expiration);
    //const localDateTimeString = expiration + 'T00:00:00'; // Assume midnight
    const expirationDate = new Date(expiration);
    expirationDate.setHours(0, 0, 0, 0);

    console.log("countDays : expirationDate = " + expirationDate);
    console.log("countDays : today = " + today);

    console.log("countDays : expirationDate.getTime() = " + expirationDate.getTime());
    console.log("countDays : today.getTime() = " + today.getTime());
  
    const timeDifference = expirationDate.getTime() - today.getTime();
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    
    return dayDifference;
  }

  const isDaysDisabled = selectedExp !== null;
  const isDateDisabled = data.days !== '';

  return (
    <div className="MenuStyle">
      {!error && <div className="blink_me">{displayMessage}</div>}
      {!icType && (
        <>
        {/*(async () => { await getUserInfo()})*/}
        
        {error && <div className="blink_me_red">{error}</div>}
        {/*printConsole ("form : days = " + data.days + "\tdaysOffset = " + data.daysOffset + "\tminDepth = " + data.minDepth)*/}
        {/*setData({"days":"","security":"","upperBound":"", "lowerBound":"", "profitAlert":""})*/}
        <form id="settingsForm" name="settingsForm" className="formStyle">
          <label className="labelStyle label1"><strong>Position</strong></label>
          <label className="labelStyle label2"><strong>Days from today for options expiration</strong></label>
          <label className="labelStyle labelOR"></label>
          <label className="labelStyle label8"><strong>Expiration</strong></label>
          
          <select
            name="position"
            id="position"
            className="inputStyle input1"
            value={data.position}
            onChange={(e) => {
              setData({ ...data, position: e.target.value });
              position.current = e.target.value; 
            }}
          >
            <option value="Long">Long</option>
            <option value="Short">Short</option>
          </select>
          
          <input
            type="number"
            id="days"
            name="days"
            ref={daysInputRef}
            className="inputStyle input2"
            value={data.days || ''}
            onChange={(e) => {
              setData({ ...data, days: e.target.value });
              days.current = e.target.value;
              setError('');
              handleInputChange();
            }} 
            disabled={isDaysDisabled}
          ></input>
          
          <Text className="orText">OR</Text>
          <DatePicker 
            className="inputStyle input8"
            ref={dateInputRef}
            selected={selectedExp}
            onChange={handleChangeExpDate}
            dateFormat="yyyy-MM-dd"
            disabled={isDateDisabled}
          />
          
          <label className="labelStyle label3"><strong>Security</strong></label>
          <label className="labelStyle label4"><strong>Number Of Contracts</strong></label>
          
          <input
            type="text"
            name="security"
            className="inputStyle input3"
            value={data.security || ''}
            onChange={(e) => {
              setData({ ...data, security: e.target.value });
              security.current = e.target.value;
              setError('');
            }}
          ></input>
          
          <input
            type="text"
            name="contracts"
            className="inputStyle input4"
            value={data.contracts || ''}
            onChange={(e) => {
              setData({ ...data, contracts: e.target.value });
              contracts.current = e.target.value;
              setError('');
            }}
          ></input>
          
          <label className="labelStyle label5"><strong>Upper Bound %</strong></label>
          <label className="labelStyle label6"><strong>Lower Bound %</strong></label>
          
          <input
            type="number"
            name="upperBound"
            className="inputStyle input5"
            value={data.upperBound || ''}
            onChange={(e) => {
              setData({ ...data, upperBound: e.target.value });
              upperBound.current = e.target.value;
              setError('');
            }}
          ></input>
          
          <input
            type="number"
            name="lowerBound"
            className="inputStyle input6"
            value={data.lowerBound || ''}
            onChange={(e) => {
              setData({ ...data, lowerBound: e.target.value });
              lowerBound.current = e.target.value;
              setError('');
            }}
          ></input>
          
          <label className="labelStyle label7"><strong>Profit Alert %</strong></label>
          
          <input
            type="number"
            name="profitAlert"
            className="inputStyle input7"
            value={data.profitAlert || ''}
            onChange={(e) => {
              setData({ ...data, profitAlert: e.target.value });
              profitAlert.current = e.target.value;
              setError('');
            }}
          ></input>
        </form><br></br>

        <div>
          <Button className="buttonStyle" onClick={() => { handleSuggest(); }} disabled={!canSuggest} title="To enable, select position short, enter days and security">
            Suggest Safe Short Bounds
          </Button>
        </div>
        <div>
          <Button className="buttonStyle" onClick={() => { handleSubmit(); } }>Submit</Button>
          <Button className="buttonStyle" onClick={() => { clearValues(); } }>Clear</Button>
        </div>
          <br></br>
          <textarea hidden={hide} placeholder={error} readOnly></textarea>
        </>
      )}
    </div>
  );
}

export default GenerateOptionsLegs;