import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsExports from "./aws-exports";
import Delete from "./Delete";
import Find from "./Find";
import List from "./List";
import RunAll from "./RunAll";
import RunOne from "./RunOne";
import Settings from "./Settings";
import AddStock from './AddStock';
//import MainMenu from './MainMenu';
import awsmobile from './aws-exports';



Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router> {/* Wrap everything within the Router */}
    <React.StrictMode>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh' }}>
        <Link to="/">
          <img src="favicon.png" alt="Favicon" title="Home" style={{ margin: 0 }} />
        </Link>
      </div>
      <App />
    </React.StrictMode>
  </Router>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
