import logo from './logo.svg';
import './App.css';
import Amplify, { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';

const myAPI = "api30769188"
const path = '/customers'; 

const MainMenuLongTerm = ({signOut}) => {
  const [input, setInput] = useState("");
  const [customers, setCustomers] = useState([]);
  //const [activeButton, setActiveButton] = useState('inTheMoney');
  const { activeButton, setActiveButton } = useActiveButton();

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  const navigate = useNavigate();

  useEffect(()=>{
    navigate(input);
  },[input, activeButton])

//changed titles
  //Function to fetch from our backend and update customers array
  function getCustomer(e) {
    let customerId = e.input
    API.get(myAPI, path + "/" + customerId)
       .then(response => {
         console.log(response)
         let newCustomers = [...customers]
         newCustomers.push(response)
         setCustomers(newCustomers)

       })
       .catch(error => {
         console.log(error)
       })
  }

  return (
    
<div className='MenuStyle'>
<h1 style={{ display: 'none' }}>Main Menu Long Term</h1>
  <div className="menu-row">
    
  </div>
  <div className="menu-row">
    <label style={{ width: '200%', marginTop: "1%", marginBottom:"1%", backgroundColor: "lightgray", fontWeight: "bold"}} onClick={()=>{
    }}>
      ETFs
    </label>
  </div>
  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/list"); 
    }}>
      List
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runAllLongTerm"); 
    }}>
      Analysis
    </Button>
  </div>
  <div className="menu-row">
    <label style={{ width: '200%', marginTop: "1%", marginBottom:"1%", backgroundColor: "lightgray", fontWeight: "bold"}} onClick={()=>{
    }}>
      Securities
    </label>
  </div>
  

  <div className="menu-row">
    
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runWallStreetBetsLongTerm"); 
    }}>
      WallStreet Bets
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runGrowthTechnologyLongTerm"); 
    }}>
      Growth Technology
    </Button>
  </div>
  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runMostActiveLongTerm"); 
    }}>
      Most Active
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runMostWatchedLongTerm"); 
    }}>
      Most Watched
    </Button>
    
  </div>
  <div className="menu-row">
  <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runDayGainersLongTerm"); 
    }}>
      Most Gainers
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runDayLosersLongTerm"); 
    }}>
      Most Losers
    </Button>
   
  </div>
  <div className="menu-row">
  <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runAggressiveLongTerm"); 
    }}>
      Small Caps Aggressive
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runSmallLongTerm"); 
    }}>
      Small Caps Gainers
    </Button>
    
  </div>
  <div className="menu-row">
  <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runUndervaluedLongTerm"); 
    }}>
      Undervalued Large Caps
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runUnderValuedGrowthLongTerm"); 
    }}>
      Undervalued Growth
    </Button>
    
  </div>
  <div className="menu-row">
    
    <Button backgroundColor="lightgreen" style={{ width: '100%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runOneLongTerm"); 
    }}>
      Custom List
    </Button>
  </div>
  <div className="menu-row">
 
    <Button title="Settings" style={{ width: '50%', marginTop: "1%", marginBottom:"1%"}} onClick={()=>{
      setInput("/editSecurityMenu"); 
    }}>
      <img src="gear.png" alt="Gear Icon" title="Settings" style={{ marginRight: '5px', width: '33%' }} />
    </Button>
    
    
    <Button title="Sign Out" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={signOut}>
      <img src="exit.png" alt="Exit Icon" title="Sign Out" style={{ marginRight: '5px', width: '33%' }} />
    </Button>

  </div>
    
</div>
  )
}

export default MainMenuLongTerm;