import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef } from 'react'
import Amplify, { Auth, Hub, Storage, API} from 'aws-amplify';
import { printConsole } from './lib/utility'; 

import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { type } from "@testing-library/user-event/dist/type";

import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';


const myAPI="mktappapi";
let path = '/getsettings';

const List = ({ signOut }) => {
  printConsole("I am in List");
  const navigate = useNavigate();

  const [rowDataState,setRowDataSate]=useState([]);
  let analysis = useRef([]);

  let initString="";

  async function downloadStocks() {
    printConsole("Executing downloadStocks");
    path = '/getListEtfs';
    var passApiStr=await path;
    await printConsole("downloadStocks : passApiStr = "+ passApiStr);
    await Promise.resolve(await API.get(myAPI, passApiStr))
      .then(async resp =>{
        await printConsole("downloadStocks : received symbols details = ");
        await console.log(resp);
        analysis.current = await resp;
    })
    .catch(error => {
      printConsole(error.message);
    }) 
  }

  async function printStocks() {
    await printConsole("Executing printStock");
    await Promise.resolve(downloadStocks())
    .then (()=>{
      printConsole("printStocks : analysis.current.length = " + analysis.current.length);
      let rowdata=[];
      for (let key=0; key<analysis.current.length; key++) {
        rowdata[key]={
          symbol: analysis.current[key]["symbol"],
          type: analysis.current[key]["type"],
          name: analysis.current[key]["name"],
          exchange: analysis.current[key]["exchange"],
        }
      }
      if (analysis.current.length != 0) { 
        setRowDataSate(rowdata);
        printConsole("printStock : rowdata = " + JSON.stringify(rowdata[0]));
      }
    })
    .catch (error =>{
      printConsole("printStocks: ERROR = " + error.message);
    })
  }

  function getSymInfo(symbol) {
    let info = {};
    let name = "";
    let description = "";
    let exchange = "";
    let type = "";
    for (var symInd = 0; symInd < analysis.current.length; symInd++) {
      let dbSymbol = analysis.current[symInd]["symbol"].toString();
      if (dbSymbol === symbol) {
        exchange = analysis.current[symInd]["exchange"].toString();
        name = analysis.current[symInd]["name"].toString();
        type = analysis.current[symInd]["type"].toString();
        symInd = analysis.current.length;
      }
    }
    info = {"symbol":symbol, "name":name, "type":type, "exchange":exchange};
    return info;
  }

  function escapeHtml(unsafe) {
    return unsafe.replace(/[&<"']/g, match => ({
        '&': '&amp;',
        '<': '&lt;',
        '"': '&quot;',
        "'": '&#39;'
    }[match]));
  }

  class CustomCellRenderer extends React.Component {
    render() {

      const { value } = this.props;
      const safeValueSymbol = escapeHtml(value);
      let info = getSymInfo(safeValueSymbol);
      let exchange = info.exchange;
      let name = escapeHtml(info.name);
      
      let symType = escapeHtml(info.type);
      let symTitle;
      
      const safeValueExchange = escapeHtml(exchange);
      //console.log(listOfSymbolsWithDetails[0]["symbol"]);
      //console.log ("gridColumns : received details for symbols = " + listOfSymbolsWithDetails.length);
  
      return (
        <a
          href={`https://www.google.com/finance/quote/${safeValueSymbol}:${safeValueExchange}?window=1Y`}
          target="_blank"
          rel="noopener noreferrer"
          
        >
          {safeValueSymbol}
        </a>
      );
    }
  }

  const columns=[
    {headerName: "Symbol", field: "symbol", sortable: true, filter: true, minWidth:10, maxWidth:100, resizable: true, cellRenderer: CustomCellRenderer,},
    {headerName: "Type", field: "type", sortable: true, filter: true, minWidth:10, maxWidth:100, resizable: true},
    {headerName: "Full Name", field: "name", sortable: true, filter: true, width: 400, resizable: true},
    {headerName: "Exchange", field: "exchange", sortable: true, filter: true, minWidth:10, maxWidth:100, resizable: true},
  ];

useEffect(()=>{
  Promise.resolve(printStocks());

},[])


  return (
    <div className="AppFullScreen">
      <div className="MenuStyle">
        <div className="ag-theme-balham" style={{height:500, width:720}}>
        <div className="green_text">List Of ETFs</div>
          <AgGridReact
            rowData={rowDataState}
            columnDefs={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default withAuthenticator(List);