import logo from './logo.svg';
import './App.css';
import Amplify, { API } from 'aws-amplify'
import React, { useEffect, useState, useContext, useRef } from 'react'
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';
import {ScreenSizeContext} from './App'

const myAPI = "api30769188"
const path = '/customers'; 

const MainMenu = ({signOut}) => {
  const [input, setInput] = useState("");
  const [customers, setCustomers] = useState([]);
  //const [activeButton, setActiveButton] = useState('inTheMoney');
  const { activeButton, setActiveButton } = useActiveButton();
  let imageWidth = useRef("50%");

  const screenSize = useContext(ScreenSizeContext);
  console.log ("MainMenu : width = " + screenSize.width);
  screenSize.width = window.innerWidth;
  console.log ("MainMenu : width inner = " + screenSize.width);
  console.log ("MainMenu : height = " + screenSize.height);
  console.log("MainMenu : imageWidth.current = " +  imageWidth.current);

  if (screenSize.width < 694 ) {
    imageWidth.current = "50%";
    console.log("MainMenu : imageWidth.current = " +  imageWidth.current);
  } else {
    imageWidth.current = "15%";
    console.log("MainMenu : imageWidth.current = " +  imageWidth.current);
  }

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  const navigate = useNavigate();

  useEffect(()=>{
    navigate(input);
  },[input, activeButton])

//changed titles
  //Function to fetch from our backend and update customers array
  function getCustomer(e) {
    let customerId = e.input
    API.get(myAPI, path + "/" + customerId)
       .then(response => {
         console.log(response)
         let newCustomers = [...customers]
         newCustomers.push(response)
         setCustomers(newCustomers)

       })
       .catch(error => {
         console.log(error)
       })
  }

  return (
    
<div className='MenuStyle' style={{
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  justifyContent: 'center', 
  
}}>

<h1 style={{ display: 'none' }}>Main Menu</h1>
  
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/mainMenuBuyWrite"); 
      }}>
      Buy Write
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/ironCondorMenu"); 
    }}>
      Iron Condor
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/mainMenuLongTerm"); 
    }}>
      Long Term
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/aiAnalysis"); 
    }}>
      AI Analysis
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/reports"); 
    }}>
      Reports
    </Button>
  </div>

  <div className="menu-row" style={{ width: imageWidth.current, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    <Button title="Settings" style={{ width: '15%', marginTop: "1%", marginBottom:"1%", flex: 1}} onClick={()=>{
        setInput("/editProfile"); 
      }}>
        <img src="gear.png" alt="Briefcase Icon" title="Settings" style={{ marginRight: '5px', width: "50%", maxWidth: "33%" }} />
    </Button>
    <Button title="Sign Out" style={{ width: '15%', marginTop: "1%", marginBottom:"1%", flex: 1}} onClick={signOut}>
      <img src="exit.png" alt="Briefcase Icon" title="Sign Out" style={{ marginRight: '5px', width: "50%", maxWidth: "33%" }} />
    </Button>
    </div>
</div>
  )
}

export default MainMenu;