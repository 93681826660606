import React, { createContext, useState, useContext } from 'react';

const ActiveButtonContext = createContext();

export const useActiveButton = () => useContext(ActiveButtonContext);

export const ActiveButtonProvider = ({ children }) => {
  const [activeButton, setActiveButton] = useState('inTheMoney');

  return (
    <ActiveButtonContext.Provider value={{ activeButton, setActiveButton }}>
      {children}
    </ActiveButtonContext.Provider>
  );
};
