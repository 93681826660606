import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import Amplify, { Auth, Hub, Storage, API } from 'aws-amplify';
import { AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
//import 'ag-grid-enterprise';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { type } from "@testing-library/user-event/dist/type";
import { printConsole } from "./lib/utility";

ModuleRegistry.registerModules([ExcelExportModule]);

const axios = require('axios');

const myAPI="mktappapi";
let path = '/strategy';

const mboum=require('mboummarketdatalib');

const MyPortfolio = ({ signOut }) => {

  printConsole("Executing MyPortfolio");
  const navigate = useNavigate();

  let numberOfChecked=useRef(0);
  let deleteIds=useRef([]);

  //const [email, setEmail]=useState()
  let email=useRef();
  const [days, setDays]=useState();
  const [forceRender, setForceRender]=useState(false);
  const [daysOffset, setDaysOffset]=useState();
  const [minInterest, setMinInyerest]=useState();
  const [maxInterest, setMaxInterest]=useState();
  const [settingsStr, setSettingsStr]=useState({"days":"30","daysOffset":"10","minInterest":"0.7","maxInterest":"1.5"});
  const [data,setData]=useState();
  const [counter, setCounter]=useState(0);
  const [error,setError]=useState('');
  const [hide, setHide]=useState("hidden");
  const [submitted, setSubmitted]=useState(false);
  const [stocks, setStocks]=useState({});
  let calledGetMyTransactions = useRef(false);
  let displayMessage = useRef();

  let apiCalled=useRef(0);

  async function getUserInfo() {
    printConsole("Executing getUserInfo");
    return (
      await new Promise((resolve, reject)=>{
        Auth.currentAuthenticatedUser().then(async user=>{
          await printConsole("getUserInfo : user.attributes.email = "+JSON.stringify(user.attributes.email));
          //await setEmail(user.attributes.email)
          email.current=user.attributes.email;
          await printConsole("getUserInfo : setEmail = "+email.current);
        })
        setTimeout(() => resolve("done"), 1000);
      })
    )
  }

  
  let initString="";

  //const [analysis, setAnalysis]=useState([]);
  let analysis=useRef([]);

  let recLst=[];
  let indRecLst=0;

  let maxChg="";
  let avgChg="";
  let wAvgChg="";
  let maxChgArrStr=[];
  let maxChgArrNum=[];
  let chgPercentCnt=0;
  let sumChgPercent=0;
  let wSumChgPercent=0;
  let wSum=0;
  let cnt33=0;
  let cnt66=0;
  let cnt100=0;
  let profitResponse="";
  let daysNowToExp = useRef();

  
  async function getMyTransactions(email) {
    await printConsole("Executing getMyTransactions");
    await printConsole("getMyTransactions : email = "+email);
    calledGetMyTransactions.current = true;
    path = '/gettransactions';

    var passApistr=path + "/" + email;
    printConsole("passApiStr = "+passApistr);
    await API.get(myAPI, passApistr)
       .then(async response => {
         await printConsole("getMyTransactions : response = " + JSON.stringify(response));
         await printConsole(response);
         var count = Object.keys(response).length;
         await printConsole("getMyTransactions : count = "+ count);
         if (count>0){
          analysis.current=[];
          for (var i=0; i<count; i++) {
            if (i==1){
              for (var j=0; j<response[i].length; j++) {
                var keys = Object.keys(response[i]);
                await printConsole(keys[j] + " = " + response[i][j]);
                if (response[i][j]=="null") {
                  response[i][j]=0;
                }
              }
            }
            await printConsole ("getMyTransactions : response[" + i + "] = " +JSON.stringify(response[i]));
            //await setAnalysis(oldArray => [...oldArray, JSON.stringify(response[i])]);
            
            analysis.current[i]=response[i];
          }
          await printConsole("getMyTransactions : analysis.length = " +analysis.current.length);
         }
       })
       .then (async () => {
        await getLastSales()
        .then(() => {
          printConsole ("getMyTransactions : symbolprices = ");
          console.log (symbolsprices);
          for (var analInd = 0; analInd < analysis.current.length; analInd ++){
            for (var symInd = 0; symInd < symbolsprices.current.length; symInd++) {
              var keys = Object.keys(symbolsprices.current[symInd]);
              var symInSymPrices = keys[0].toString();
              var symInAnalysis = analysis.current[analInd]["symbol"].toString();
              if (symInSymPrices === symInAnalysis) {
                analysis.current[analInd]["lastSale"] = symbolsprices.current[symInd][[symInSymPrices]];
                printConsole ("getMyTransactions : update " + symInAnalysis + " lastSale = " + analysis.current[analInd]["lastSale"]);
                symInd = symbolsprices.current.length;
              }
            }
          }
        })
      })
      .then(() => {
        loadRowData();
      })
       .catch(error => {
         printConsole(error)
       })
  }

  
let rowdata=[];
const [rowDataState,setRowDataSate]=useState([]);

  function loadRowData() {
    printConsole ("Executing loadRowData");
    printConsole ("loadRowData : analysis = "+ JSON.stringify(analysis));
    var buyWriteCost, premium, maxProfit, breakEven, daysNowToExp, optionPrice, pnl, pnlProfit;
    if (typeof(analysis.current)!='undefined') {
      var key=0;
      //var count = Object.keys(analysis).length;
      var count=analysis.current.length;
      printConsole ("loadRowData : analysis length= "+count);
      var rank=0;

      for (key=0; key<count; key++) {

        var buyWriteCost = parseFloat(analysis.current[key]["buyWriteCost"]);
        if (buyWriteCost==null) buyWriteCost=0;
        var premium = parseFloat(analysis.current[key]["premium"]);
        if (premium==null) premium=0;
        var maxProfit = parseFloat(analysis.current[key]["maxProfit"]);
        if (maxProfit==null) maxProfit=0;
        var breakEven = parseFloat(analysis.current[key]["breakEven"]);
        if (breakEven==null) breakEven=0;
        var downRisk = parseFloat(analysis.current[key]["downRisk"]);
        if (downRisk==null) downRisk=0;
        var shares = parseInt(analysis.current[key]["shares"]);
        if (shares==null) shares=0;
        var sharePrice = parseFloat(analysis.current[key]["sharePrice"]);
        if (sharePrice==null) sharePrice=0;
        var sharePriceActual = parseFloat(analysis.current[key]["sharePriceActual"]);
        if (sharePriceActual==null) sharePriceActual=0;
        var callPrice = parseFloat(analysis.current[key]["callPrice"]);
        if (callPrice==null) callPrice=0;
        var contractsNum = parseInt(analysis.current[key]["contractsNum"]);
        if (contractsNum==null) contractsNum=0;
        var daysToExp = parseInt(analysis.current[key]["daysToExp"]);
        if (daysToExp==null) daysToExp=0;
        var tradeDate = analysis.current[key]["tradeDate"];
        if (tradeDate==null) tradeDate=0;
        var email = analysis.current[key]["email"];
        if (email==null) email="";
        var optionPrice = parseFloat(analysis.current[key]["optionPrice"]);
        if (optionPrice==null) optionPrice=0;
        var pnl = parseFloat(analysis.current[key]["pnl"]);
        if (pnl==null) pnl=0;
        var profitActual = parseFloat(analysis.current[key]["profitActual"]);
        if (profitActual==null) profitActual=0;
        
        rowdata[key]={
          id: analysis.current[key]["id"],
          symbol: analysis.current[key]["symbol"],
          rank: analysis.current[key]["rank"],
          offer: parseFloat(analysis.current[key]["offer"]),
          exp: analysis.current[key]["exp"],
          strike: parseFloat(analysis.current[key]["strike"]),
          lastSale: parseFloat(analysis.current[key]["lastSale"]),
          max: parseFloat(analysis.current[key]["max"]),
          bid: parseFloat(analysis.current[key]["bid"]),
          ask: parseFloat(analysis.current[key]["ask"]),
          int: parseInt(analysis.current[key]["int"]),
          vol: parseInt(analysis.current[key]["vol"]),
          profit: parseFloat(analysis.current[key]["profit"]),
          annual: parseFloat(analysis.current[key]["annual"]),
          depth: parseFloat(analysis.current[key]["depth"]),
          wAvg: parseFloat(analysis.current[key]["wAvg"]),
          volatility: parseFloat(analysis.current[key]["volatility"]),
          volatilityPeriod: parseFloat(analysis.current[key]["volatilityPeriod"]),
          volMinPeriod: parseFloat(analysis.current[key]["volMinPeriod"]),
          volMaxPeriod: parseFloat(analysis.current[key]["volMaxPeriod"]),
          buyWriteCost: buyWriteCost,
          premium: premium,
          maxProfit: maxProfit,
          breakEven: breakEven,
          downRisk: downRisk,
          shares: shares,
          sharePrice: sharePrice,
          sharePriceActual: sharePriceActual,
          callPrice: callPrice,
          contractsNum: contractsNum,
          daysToExp: daysToExp,
          tradeDate: tradeDate,
          email: email,
          optionPrice: optionPrice,
          pnl: pnl,
          profitActual: profitActual
        }
      }
      printConsole("loadRowData : typeof rowDataState = "+typeof(rowDataState));
      printConsole("loadRowData : length rowDataState = "+rowDataState.length);
      printConsole("loadRowData : count = "+count);
      console.log("loadRowData : rowDataState.length = "+rowDataState.length);

      if (count !== 0) {//rowDataState.length < count &&  
        console.log("loadRowData : setting rowDataState");
        setRowDataSate(rowdata);
        printConsole("loadRowData : rowdata = " + JSON.stringify(rowdata[0]));
      }
      
    }
  }

  const rowClassRules = {

    // row style function
    'goodOption': function(params) {
      printConsole ("Executing goodOption");
      var expStr=params.data["exp"];
      var dtExp=new Date(expStr).toUTCString().toString();
      let dateObj = new Date(dtExp);
      let secondsExp = dateObj.getTime() / 1000;
      var dtNow=new Date();
      dateObj = new Date(dtNow);
      let secondsNow = dateObj.getTime() / 1000;
      printConsole("goodOption : secondsNow = " + secondsNow + "\tsecondsExp = " + secondsExp);
      printConsole("goodOption : dtNow = " + dtNow + "\tdtExp = " + dtExp);
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : goodOption : daysNowToExp = "+expDays  + "\tsymbol = " + params.data["symbol"]);
      printConsole("rowClassRules : params = ");
      let lastSale = parseFloat(params.data["lastSale"]);
      let strike = parseFloat(params.data["strike"]);
      let diffLastSaleVsStrike;
      if (lastSale < strike) {
        diffLastSaleVsStrike = -1;
      } else {
        diffLastSaleVsStrike = 1 - strike / lastSale
      }
      console.log(params);
      var depth = params.data["depth"];
      printConsole("rowClassRules : depth = "+depth);
      var profit = params.data["profit"];
      printConsole("rowClassRules : profit = "+profit);
      var adjProfit=parseFloat(profit)*30/expDays;
      printConsole("rowClassRules : adjusted profit = "+(parseFloat(profit)*30/expDays));
      var maxChange = params.data["maxChange"];
      printConsole("rowClassRules : maxChange = "+maxChange);
      var wAvgChange=params.data["wAvg"];
      printConsole("rowClassRules : wAvgChange = "+wAvgChange);
      var minDepth=parseFloat(wAvgChange)*0.75
      printConsole("rowClassRules : minDepth = "+minDepth);
      if (adjProfit >= 0.90 && depth >= minDepth) {
        printConsole ("rowClassRules : goodOption : adjProfit = " + adjProfit + " --- profit = "+ profit + " --- depth = " + depth + " --- minDepth = " + minDepth);
      }
      return diffLastSaleVsStrike >= 0.05 && expDays >= 0;
    },
    'mediumOption': function (params) {
      printConsole ("Executing medium");
      var expStr=params.data["exp"];
      var dtExp=new Date(expStr).toUTCString().toString();
      let dateObj = new Date(dtExp);
      let secondsExp = dateObj.getTime() / 1000;
      var dtNow=new Date();
      dateObj = new Date(dtNow);
      let secondsNow = dateObj.getTime() / 1000;
      printConsole("medium : secondsNow = " + secondsNow + "\tsecondsExp = " + secondsExp);
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : mediumOption : daysNowToExp = "+expDays + "\tsymbol = " + params.data["symbol"]);
      printConsole("rowClassRules : params = ");
      let lastSale = parseFloat(params.data["lastSale"]);
      let strike = parseFloat(params.data["strike"]);
      let diffLastSaleVsStrike;
      if (lastSale < strike) {
        diffLastSaleVsStrike = -1;
      } else {
        diffLastSaleVsStrike = 1 - strike / lastSale
      }
      console.log(params);
      var depth = params.data["depth"];
      printConsole("rowClassRules : depth = "+depth);
      var profit = params.data["profit"];
      printConsole("rowClassRules : profit = "+profit);
      var adjProfit=parseFloat(profit)*30/expDays;
      printConsole("rowClassRules : adjusted profit = "+(parseFloat(profit)*30/expDays));
      var maxChange = params.data["maxChange"];
      printConsole("rowClassRules : maxChange = "+maxChange);
      var wAvgChange=params.data["wAvg"];
      printConsole("rowClassRules : wAvgChange = "+wAvgChange);
      var minDepth=parseFloat(wAvgChange)*0.75
      printConsole("rowClassRules : minDepth = "+minDepth);
      if (adjProfit >= 0.6 && adjProfit < 0.90 && depth >= minDepth) {
        printConsole ("rowClassRules : mediumOption : adjProfit = " + adjProfit + " --- profit = "+ profit + " --- depth = " + depth + " --- minDepth = " + minDepth);
      }
      return diffLastSaleVsStrike >=0 && diffLastSaleVsStrike < 0.05  && expDays >= 0;
    },
    // row style expression
    'badOptionPortfolio': function (params) {
      printConsole ("Executing badOptionPortfolio");
      var expStr=params.data["exp"];
      var dtExp=new Date(expStr).toUTCString().toString();
      let dateObj = new Date(dtExp);
      let secondsExp = dateObj.getTime() / 1000;
      var dtNow=new Date();
      dateObj = new Date(dtNow);
      let secondsNow = dateObj.getTime() / 1000;
      printConsole("badOptionPortfolio : secondsNow = " + secondsNow + "\tsecondsExp = " + secondsExp);
      dtNow.setHours(0, 0, 0, 0);
      var expDays=(Date.parse(dtExp)-Date.parse(dtNow))/1000/60/60/24;
      expDays=Math.round(expDays);
      printConsole("rowClassRules : mbadOptionPortfolio : daysNowToExp = "+expDays + "\tsymbol = " + params.data["symbol"]);
      printConsole("rowClassRules : params = ");
      let lastSale = parseFloat(params.data["lastSale"]);
      let strike = parseFloat(params.data["strike"]);
      let diffLastSaleVsStrike;
      if (lastSale < strike) {
        diffLastSaleVsStrike = -1;
      } else {
        diffLastSaleVsStrike = 1 - strike / lastSale
      }
      console.log(params);
      var depth = params.data["depth"];
      printConsole("rowClassRules : depth = "+depth);
      var profit = params.data["profit"];
      printConsole("rowClassRules : profit = "+profit);
      var adjProfit=parseFloat(profit)*30/expDays;
      printConsole("rowClassRules : adjusted profit = "+(parseFloat(profit)*30/expDays));
      var maxChange = params.data["maxChange"];
      printConsole("rowClassRules : maxChange = "+maxChange);
      var wAvgChange=params.data["wAvg"];
      printConsole("rowClassRules : wAvgChange = "+wAvgChange);
      var minDepth=parseFloat(wAvgChange)*0.75
      printConsole("rowClassRules : minDepth = "+minDepth);
      if (adjProfit >= 0.6 && adjProfit < 0.90 && depth >= minDepth) {
        printConsole ("rowClassRules : badOptionPortfolio : adjProfit = " + adjProfit + " --- profit = "+ profit + " --- depth = " + depth + " --- minDepth = " + minDepth);
      }
      return diffLastSaleVsStrike < 0  && expDays >= 0;
    },

};

let symbolsprices = useRef([]);
async function getLastSales (){
  await printConsole("Executing getLastSales");
  let symbols = "";
  await printConsole ("getLastSales : analysis.current.length = " + analysis.current.length);
  
  for (var symInd = 0; symInd < analysis.current.length; symInd++) {
    symbols = symbols + analysis.current[symInd]["symbol"];
    if (symInd < analysis.current.length - 1) {
      symbols = symbols + ",";
    }
  }

  await printConsole("getLastSales : symbols = " + symbols);
  path = '/getsymbolsprices';
  var passApistr=path + "/" + symbols;
  await printConsole("passApiStr = "+passApistr);
  await API.get(myAPI, passApistr)
  .then (response => {
    printConsole("getLastSales : received symbols prices = ");
    console.log (response);
    symbolsprices.current = response;
  })
}
  
  
useEffect(()=>{
  printConsole ("Executing useEffect");
  async function fetchData() {
    printConsole ("fetchData goodOption");
    await getUserInfo();
    if (typeof(email.current)!='undefined' && !calledGetMyTransactions.current) {
      await getMyTransactions(email.current);
    };
  };
  fetchData();
  

},[submitted])

const gridRef = useRef();

const gridApi = useCallback(() => {
  printConsole ("Executing gridApi");
  if (!gridRef.current) {
    printConsole("!gridRef.current");
    return;
  }

  printConsole("!gridRef.current");
  return gridRef.current.gridApi;
}, [gridRef]);

const setCellValue = (rowIndex, columnField, value) => {
  printConsole ("Executing setCellValue");
  gridApi().setDataValue(rowIndex, columnField, value);
};

function getFormattedDate(date) {
  printConsole ("Executing getFormattedDate");
  printConsole("getFormattedDate: received date = "+date);
  if (typeof(date)!='undefined') {
    if (date.toString().length>0){
      //printConsole("getFormattedDate : received date = "+ date.toString());
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
    
      printConsole("getFormattedDate : returning date = "+ month + '/' + day + '/' + year);
    
      return month + '/' + day + '/' + year;
    } else {
      return "";
    }
  } else {
    return "";
  }
  
}

function currencyFormat(num) {
  printConsole ("Executing currencyFormat");
  return parseFloat(num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')).toFixed(2);
}

function getFontSize() {
  printConsole ("Executing getFontSize");
  const fontSize = window.getComputedStyle(document.querySelector('#my-element'), ':before').getPropertyValue('font-size');
  return fontSize;
}

//printConsole ("font size = "+getFontSize());

const columns=[
  {headerName: "Id", field: "id", hide: true,},
  {headerName: "email", field: "email", hide: true, valueGetter : row => {
    var emailRow=email.current;
    row.data.email=emailRow;
    return emailRow;
  },},
  {headerName: "Delete", field: "save", pinned: true, headerCheckboxSelection: false, checkboxSelection: true, minWidth:10},
  {headerName: "Security", field: "symbol", sortable: true, filter: true, pinned: true, minWidth:10, resizable:true, editable: true,},
  {headerName: "Expiration", field: "exp", sortable: true, filter: 'agDateColumnFilter',minWidth:50, editable: true, resizable:true, valueGetter: row =>{
    printConsole("row: exp org = "+row.data.exp.toString());
    if (row.data.exp.toString().indexOf("GMT")>0) {
      var expiration=row.data.exp;
      expiration=expiration.toString().replace("GMT","");
      printConsole("row: expiration without GMT = "+expiration);
      expiration=getFormattedDate(new Date(expiration));
      printConsole("row: expiration formatted = "+expiration.toString());
      row.data.exp=expiration
      return expiration;
    } else {
        return row.data.exp;
    }
  },},
  {headerName: "Current Last Sale", field: "lastSale", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, editable: false, resizable:true, cellRenderer: row =>{
    if (isNaN(row.data.sharePrice)) {
      row.data.lastSale=0;
      return row.data.lastSale;
    } else {
        return row.data.lastSale;
    }
  },},
  {headerName: "Strike Price", field: "strike", sortable: true, filter: 'agNumberColumnFilter',minWidth:50, editable: true, resizable:true, },
  {headerName: "Share Price", field: "sharePrice", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, editable: true, resizable:true, cellRenderer: row =>{
    if (isNaN(row.data.sharePrice)) {
      row.data.sharePrice=0;
      return row.data.sharePrice;
    } else {
        return row.data.sharePrice;
    }
  },},
  {headerName: "PnL Actual", field: "pnl", sortable: true, filter: 'agNumberColumnFilter', minWidth:50,  editable: true, resizable:true, valueGetter : row => {
    const optionPrice=parseFloat(row.data.optionPrice);
    var pnl=0;
    const sharePriceActual=parseFloat(row.data.sharePriceActual);
    const securityPrice=parseFloat(row.data.sharePrice);
    const callPrice=parseFloat(row.data.callPrice);
    const shares=parseInt(row.data.shares);
    if (sharePriceActual>0 && optionPrice>=0 && securityPrice>0 && callPrice>0 && shares>0) {
      pnl=(sharePriceActual-securityPrice+callPrice-optionPrice)*shares;
      pnl=Math.round(pnl*100)/100;
    }
    row.data.pnl=pnl;
    return pnl;
  },},
  {headerName: "Profit Actual %", field: "profitActual", sortable: true, filter: 'agNumberColumnFilter', minWidth:50,  editable: true, resizable:true, valueGetter : row => {
    const pnl=parseFloat(row.data.pnl);
    var profitActual=0;
    const buyWriteCost=parseFloat(row.data.buyWriteCost);
    if (pnl>0 && buyWriteCost>0) {
      profitActual=pnl/buyWriteCost;
      profitActual=Math.round(profitActual*1000)/10;
    }
    row.data.profitActual=profitActual;
    return profitActual;
  },},
  {headerName: "Annual Profit %", field: "annual", sortable: true, filter: 'agNumberColumnFilter', minWidth:50,  resizable:true, valueGetter : row => {
    printConsole (row.data);
    var annualProfit=row.data.annual;
    var profit=parseFloat(row.data.profit);
    var daysToExpire=parseInt(row.data.daysToExp)
    if (profit>0 && daysToExpire>0) {
      annualProfit=profit/daysToExpire*360;
      printConsole ("annualProfit % = "+annualProfit);
      annualProfit=Math.round(annualProfit*100)/100;
    }
    row.data.annual=annualProfit;
    return annualProfit;
  },},
  {headerName: "Days To Expire", field: "daysToExp", sortable: true, filter: 'agNumberColumnFilter', minWidth:50,  resizable:true, valueGetter : row => {
    var tradeDate=row.data.tradeDate;
    var expDate=row.data.exp;
    var daysToExpire=0
    
    if (tradeDate !== null && parseInt(tradeDate) !== 0 && expDate !== null ) {
      if (typeof(tradeDate) !=='undefined' && typeof(expDate) !== 'undefined') {
        var expDateTime=new Date(expDate);
        var tradeDateTime=new Date(tradeDate);
        var daysTime = expDateTime.getTime() - tradeDateTime.getTime();
        daysToExpire= parseInt(daysTime / (1000 * 3600 *24));
      }
    }
    row.data.daysToExp=daysToExpire;
    return daysToExpire;
  }, cellRenderer: row =>{
    if (isNaN(row.data.daysToExp)) {
      row.data.daysToExp=0;
      return row.data.daysToExp;
    } else {
        return row.data.daysToExp;
    }
  },},

  {headerName: "Shares", field: "shares", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, editable: true, resizable:true, cellRenderer: row =>{
    if (isNaN(row.data.shares)) {
      row.data.shares=0;
      return row.data.shares;
    } else {
        return row.data.shares;
    }
  },},
  {headerName: "Call Price", field: "callPrice", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, editable: true, resizable:true, cellRenderer: row =>{
    if (isNaN(row.data.callPrice)) {
      row.data.callPrice=0;
      return row.data.callPrice;
    } else {
        return row.data.callPrice;
    }
  },},
  {headerName: "Contracts Num", field: "contractsNum", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, editable: true, resizable:true, cellRenderer: row =>{
    if (isNaN(row.data.contractsNum)) {
      row.data.contractsNum=0;
      return row.data.contractsNum;
    } else {
        return row.data.contractsNum;
    }
  },},
  
  
  {headerName: "BuyWrite  Cost", field: "buyWriteCost", sortable: true, filter: 'agNumberColumnFilter',minWidth:50, resizable:true, valueGetter : row => {
    var buyWriteCost=0;
    const shares=row.data.shares;
    const securityPrice=row.data.sharePrice;
    const contractsNum=row.data.contractsNum;
    const callPrice=row.data.callPrice;
    if (shares>0 && securityPrice>0 & contractsNum>0 & callPrice>0) {
      buyWriteCost=shares * securityPrice - contractsNum * callPrice * 100;
      buyWriteCost=Math.round(buyWriteCost*100)/100
    }
    row.data.buyWriteCost=buyWriteCost;
    return buyWriteCost;
  },},
  {headerName: "Premium", field: "premium", sortable: true, filter: 'agNumberColumnFilter',minWidth:50, resizable:true, valueGetter : row => {
    var premium=0;
    const strike=parseFloat(row.data.strike);
    const securityPrice=parseFloat(row.data.sharePrice);
    const callPrice=parseFloat(row.data.callPrice);
    if (strike>0 && securityPrice>0 & callPrice>0) {
      premium=strike + callPrice - securityPrice;
      premium=Math.round(premium*100)/100;
    }
    row.data.premium=premium;
    return premium;
  },},
  {headerName: "Max Profit", field: "maxProfit", sortable: true, filter: 'agNumberColumnFilter',minWidth:50, resizable:true, valueGetter : row => {
    var maxProfit=0;
    const shares=row.data.shares;
    const premium=row.data.premium;
    if (shares>0 && premium>0 ) {
      maxProfit=shares * premium;
      maxProfit=Math.round(maxProfit*100)/100
    }
    row.data.maxProfit=maxProfit;
    return maxProfit;
  },},
  {headerName: "Profit %", field: "profit", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, resizable:true, valueGetter : row => {
    var profit=parseFloat(row.data.profit);
    const maxProfit=parseFloat(row.data.maxProfit);
    const buyWriteCost=parseFloat(row.data.buyWriteCost);

    if (maxProfit>0 && buyWriteCost>0) {
      profit=maxProfit/buyWriteCost;
      profit=Math.round(profit*10000)/100
    }
    row.data.profit=profit;
    return profit;
  },},
  {headerName: "Break Even", field: "breakEven", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, resizable:true, valueGetter : row => {
    var breakEven=0;
    const strike=parseFloat(row.data.strike);
    const premium=parseFloat(row.data.premium);
    if (strike > 0 && premium>0 ) {
      breakEven=strike - premium;
    }
    row.data.breakEven=breakEven;
    return breakEven;
  },},
  {headerName: "Down Risk", field: "downRisk", sortable: true, filter: 'agNumberColumnFilter', minWidth:50, resizable:true, valueGetter : row => {
    var downRisk=0;
    const securityPrice=parseFloat(row.data.sharePrice);
    const breakEven=parseFloat(row.data.breakEven);
    if (securityPrice > 0 && breakEven>0 ) {
      downRisk=(securityPrice - breakEven)/securityPrice;
      printConsole("downRisk = "+ downRisk);
      downRisk=Math.round(downRisk*10000)/100;
    }
    row.data.downRisk=downRisk;
    return downRisk;
  },},
  {headerName: "Trade Date", field: "tradeDate", sortable: true, filter: 'agDateColumnFilter', minWidth:50,  editable: true, resizable:true, valueGetter: row =>{
    if (parseInt(row.data.tradeDate) !== 0 && row.data.tradeDate !=='' && row.data.tradeDate !== null && typeof(row.data.tradeDate)!=='undefined' && !isNaN(row.data.tradeDate)) {
      printConsole("condition 1 tradeDate = " + row.data.tradeDate);
      var tradeDate=row.data.tradeDate;
      tradeDate=tradeDate.toString().replace("GMT","");
      tradeDate=getFormattedDate(new Date(tradeDate));
      row.data.tradeDate=tradeDate
    } else {
        printConsole("condition 2 row.data.tradeDate = " + row.data.tradeDate);
        var tradeDate=row.data.tradeDate;
        printConsole("condition 2 tradeDate = " + tradeDate);
        try {
          tradeDate=tradeDate.toString();
          printConsole('precondition 3 tradeDate.indexOf("NaN") = ' + tradeDate.indexOf("NaN"));
          if (tradeDate.indexOf("NaN")>0) {
            printConsole("condition 3 tradeDate = " + tradeDate);
            row.data.tradeDate=0
          } /*else if (isNaN(row.data.tradeDate)) {
            printConsole("condition 4 tradeDate = " + row.data.tradeDate);
            row.data.tradeDate=0
          }*/
        } catch (error) {
          
        }
    }
    printConsole("return row.data.tradeDate = " + row.data.tradeDate);
    return row.data.tradeDate;
  }, cellRenderer: row =>{
    if (parseInt(row.data.tradeDate) !==0 ) {
      var tradeDate=row.data.tradeDate.toString();
      printConsole("cellRenderer : tradeDate = " + tradeDate);
      tradeDate=tradeDate.replace("GMT","");
      tradeDate=new Date(tradeDate);
      tradeDate=getFormattedDate(tradeDate);
      row.data.tradeDate=tradeDate
      return row.data.tradeDate;
    } else {
        return row.data.tradeDate;
    }
  },},
  {headerName: "Share Price Actual", field: "sharePriceActual", sortable: true, filter: 'agNumberColumnFilter', minWidth:50,  resizable:true, editable: true,cellRenderer: row =>{
    if (isNaN(row.data.sharePriceActual)) {
      row.data.sharePriceActual=0;
      return row.data.sharePriceActual;
    } else {
        return row.data.sharePriceActual;
    }
  },},
  {headerName: "Option Price Actual", field: "optionPrice", sortable: true, filter: 'agNumberColumnFilter', minWidth:50,  editable: true, resizable:true, valueGetter : row => {
    var optionPrice=row.data.optionPrice;
    const sharePriceActual=parseFloat(row.data.sharePriceActual);
    const strike=parseFloat(row.data.strike);
    if (sharePriceActual>0 && strike>0) {
      optionPrice=sharePriceActual-strike;
      optionPrice=Math.round(optionPrice*100)/100;
    }
    row.data.optionPrice=optionPrice;
    return optionPrice;
  }, cellRenderer: row =>{
    if (isNaN(row.data.optionPrice)) {
      row.data.optionPrice=0;
      return row.data.optionPrice;
    } else {
        return row.data.optionPrice;
    }
  },}
];



const onSelectionChanged=() =>{
  printConsole ("Executing onSelectionChanged");
  var commitDelete="";
  let selectedRowsNumber=gridRef.current.api.getSelectedRows().length;
  printConsole("gridRef.current.api.getSelectedRows().length = " + selectedRowsNumber);
  /*if (gridRef.current.api.getSelectedRows().length>0) {
    commitDelete="commit";
  } else {
    commitDelete="delete";
  }*/
  if (selectedRowsNumber>=numberOfChecked.current) {
    commitDelete="delete";
    numberOfChecked.current++;
  } else {
    commitDelete="commit";
    numberOfChecked.current--;
  }

  printConsole("commitDelete = "+commitDelete);
  //const selectedRows = gridRef.current.api.getSelectedRows();
  
  
  printConsole("gridRef");
  console.log(gridRef);
  printConsole("getSelectedNodes");
  console.log(gridRef.current.api.getSelectedNodes());
  if (gridRef.current.api.getSelectedNodes().length > 0) {
    printConsole("checkboxSelected");
    console.log(gridRef.current.checkboxSelected);
    printConsole("getDisplayedRowCount = "+gridRef.current.api.getDisplayedRowCount());
    const selectedRowIndex=gridRef.current.api.getFocusedCell().rowIndex;
    printConsole("selectedRowIndex = "+selectedRowIndex);
    printConsole("getDisplayedRowAtIndex");
    console.log(gridRef.current.api.getDisplayedRowAtIndex(selectedRowIndex));

    const selectedRows = gridRef.current.api.getDisplayedRowAtIndex(selectedRowIndex).data;
    printConsole("selectedRows");
    console.log(selectedRows);

    let rowJson={"security":{}};
    let dt=Date().toString();
    let timestamp=Date.parse(dt).toString();
    for (i=0; i<selectedRows.length; i++) {
      if (typeof(selectedRows[i])=='undefined') {
        selectedRows[i]="";
      }
    }
    rowJson.security["id"]=selectedRows.id.toString();;
    rowJson.security["timestamp"]=dt;
    rowJson.security["symbol"]=selectedRows.symbol.toUpperCase();
    rowJson.security["rank"]=selectedRows.rank.toString();
    rowJson.security["offer"]=selectedRows.offer.toString();
    var exp=selectedRows.exp.toString();
    /*exp=new Date(exp).toString();
    var gmtpos=exp.search("GMT")+3;
    exp=exp.substring(0,gmtpos);
    var spacePos=exp.search(" ");
    exp=exp.replace(" ",", ");
    const expDt=new Date(exp);*/
    rowJson.security["exp"]=exp;
    rowJson.security["strike"]=selectedRows.strike.toString();
    rowJson.security["bid"]=selectedRows.bid.toString();
    rowJson.security["ask"]=selectedRows.ask.toString();
    rowJson.security["int"]=selectedRows.int.toString();
    rowJson.security["vol"]=selectedRows.vol.toString();
    rowJson.security["profit"]=selectedRows.profit.toString();
    try {
      rowJson.security["annual"]=selectedRows.annual.toString();
    } catch (e) {
    }
    
    rowJson.security["depth"]=selectedRows.depth.toString();
    rowJson.security["wavg"]=selectedRows.wAvg.toString();
    rowJson.security["max"]=selectedRows.max.toString();
    rowJson.security["volatility"]=selectedRows.volatility.toString();
    rowJson.security["volatilityPeriod"]=selectedRows.volatilityPeriod.toString();
    rowJson.security["volMinPeriod"]=selectedRows.volMinPeriod.toString();
    rowJson.security["volMaxPeriod"]=selectedRows.volMaxPeriod.toString();
    rowJson.security["email"]=email.current;

    if (commitDelete==="commit") {
      for (var i=0; i<deleteIds.current.length; i++) {
        if (deleteIds.current[i]["security"]["id"]===rowJson.security["id"]){
          deleteIds.current.splice(i,1);
          printConsole("onSelectionChanged : deleteIds removed = "+ rowJson.security["id"]);
          printConsole(deleteIds.current);
        }
      }
      path = '/storetransaction';
      var passApistr=path + "/" + JSON.stringify(rowJson);
      printConsole("onSelectionChanged : passApistr = " +passApistr);
      /*API.get(myAPI, passApistr)
        .then(async resp =>{
          await printConsole("onSelectionChanged : resp = ");
          await printConsole(resp);
        })*/
    }

    if (commitDelete==="delete") {
      deleteIds.current[deleteIds.current.length]=rowJson;
      printConsole("onSelectionChanged : deleteIds added = "+ rowJson.security["id"]);
      printConsole(deleteIds.current);
      path = '/deletetransaction';
      var passApistr=path + "/" + JSON.stringify(rowJson);
      printConsole("onSelectionChanged : passApistr = " +passApistr);
      /*API.get(myAPI, passApistr)
        .then(async resp =>{
          await printConsole("onSelectionChanged : resp = ");
          await printConsole(resp);
        })*/
    }
  }
}

function addEmptyRow() {
  printConsole ("Executing addEmptyRow");
  const nowTimestamp=Date.now();
  const emptyRowData = [{
    id: nowTimestamp,
    email: email.current,
    symbol: "",
    buyWriteCost: 0,
    premium: 0,
    maxProfit: 0,
    breakEven: 0,
    downRisk: 0,
    shares: 0,
    sharePriceActual: 0,
    lastSale: 0,
    callPrice: 0,
    contractsNum: 0,
    daysToExp: 0,
    tradeDate: 0,
    optionPrice: 0,
    pnl: 0,
    profitActual: 0,
    rank: 0,
    offer: 0,
    exp: "",
    strike: 0,
    max: 0,
    bid: 0,
    ask: 0,
    int: 0,
    vol: 0,
    profit: 0,
    annual: 0,
    depth: 0,
    wAvg: 0,
    volatility: 0,
    volatilityPeriod: 0,
    volMinPeriod: 0,
    volMaxPeriod: 0,
  }];
  gridRef.current.api.applyTransaction({ add: emptyRowData });
}

function deleteOneRec(passApistr, i) {
  printConsole ("Executing deleteOneRec");
  printConsole("deleteRecs : passApistr = " + passApistr);
  printConsole("received i = "+ i);
  API.get(myAPI, passApistr)
  .then(resp =>{
      printConsole("deleteRecs : resp = ");
      printConsole(resp);
      //printConsole("grid data row 5");
      //printConsole(gridRef.current.api.getDisplayedRowAtIndex(8));
      var respStr=resp.toString();
      var removeRow=[];
      if (respStr.indexOf("Could not delete the record")<0) {
        printConsole ("Grid number of rows = " + gridRef.current.api.getDisplayedRowCount());
        printConsole("i = "+ i);
        printConsole(deleteIds.current);
        for(var j=0; j<gridRef.current.api.getDisplayedRowCount(); j++) {
          if (deleteIds.current[i].security.id == gridRef.current.api.getDisplayedRowAtIndex(j).data.id) {
            try {
              removeRow[0] = gridRef.current.api.getDisplayedRowAtIndex(j).data; 
              printConsole(removeRow);
              gridRef.current.api.applyTransaction({ remove: removeRow });
            } catch (error) {
              printConsole(error);
            }
            
          }
        }
      }
  })
}

async function deleteRecServer(passApistr, i, arrOfIds) {
  printConsole ("Executing deleteRecServer");
  await printConsole("arrayOfIds below ");
  await printConsole(arrOfIds);
  await printConsole("deleteRecs : passApistr = " + passApistr);
  await printConsole("received i = "+ i);
  await API.get(myAPI, passApistr)
  .then(async resp =>{
      await printConsole("deleteRecs : resp = ");
      await printConsole(resp);
      //printConsole("grid data row 5");
      //printConsole(gridRef.current.api.getDisplayedRowAtIndex(8));
      var respStr=resp.toString();
      var removeRow=[];
      if (respStr.indexOf("Could not delete the record")<0) {
        await printConsole ("Grid number of rows = " + gridRef.current.api.getDisplayedRowCount());
        await printConsole("i = "+ i);
        await printConsole(deleteIds.current);
        for (var i=0; i<deleteIds.current.length; i++) {
          for(var j=0; j<gridRef.current.api.getDisplayedRowCount(); j++) {
            try {
              if (deleteIds.current[i].security.id == gridRef.current.api.getDisplayedRowAtIndex(j).data.id) {
              
                removeRow[0] = gridRef.current.api.getDisplayedRowAtIndex(j).data; 
                await printConsole("before removing row "+j);
                await printConsole(removeRow);
                gridRef.current.api.applyTransaction({ remove: removeRow });
                removeRow[0] = gridRef.current.api.getDisplayedRowAtIndex(j).data; 
                await printConsole("after removing row "+j);
                await printConsole(removeRow);
              }
            } catch (error) {
              await printConsole(error);
            }
          }
        }
        deleteIds.current=[];
      }
  })
}

async function deleteRecs() {
  await printConsole ("Executing deleteRecs");
  if (deleteIds.current.length>0) {
    await printConsole(deleteIds);
    var arrOfIds=[]
    path = '/deletetransaction';
    var passApistr="";
    for (var i=0; i<deleteIds.current.length; i++) {
      var exp=convertDate(deleteIds.current[i].security.exp);
      deleteIds.current[i].security.exp=exp;
      await printConsole(deleteIds);
      arrOfIds[i]=deleteIds.current[i].security.id;
      var passApistr=path + "/" + JSON.stringify(deleteIds.current[i]);

      //deleteOneRec(passApistr, i)
    
    }
    var passApistr=path + "/" + arrOfIds.toString();
    await deleteRecServer(passApistr, i, arrOfIds);
  }
}
async function deleteRowDataState(){
  await printConsole("Executing deleteRowDataState");
  await setRowDataSate([]);
  await printConsole("deleteRowDataState : rowDataState.length = "+ rowDataState.length);
};

function convertDate(dt) {
  printConsole ("Executing convertDate");
  var dtDate=new Date(dt);
  var dtDayOfWeekNum=dtDate.getDay();
  var dtDayOfWeek="";

  if (dtDayOfWeekNum==0){
    dtDayOfWeek="Sun";
  }
  if (dtDayOfWeekNum==1){
    dtDayOfWeek="Mon";
  }
  if (dtDayOfWeekNum==2){
    dtDayOfWeek="Tue";
  }
  if (dtDayOfWeekNum==3){
    dtDayOfWeek="Wed";
  }
  if (dtDayOfWeekNum==4){
    dtDayOfWeek="Thu";
  }
  if (dtDayOfWeekNum==5){
    dtDayOfWeek="Fri";
  }
  if (dtDayOfWeekNum==6){
    dtDayOfWeek="Sat";
  }

  var dtMonthNum=dtDate.getMonth();
  var dtMonth="";
  var months=["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  for (var i=0; i<12; i++) {
    if (i==dtMonthNum) {
      dtMonth=months[i];
    }
  }
 
  var dtDay=dtDate.getDate().toString();
  var dtYear=dtDate.getFullYear().toString();
  var dtStr=dtDayOfWeek + ", " + dtDay + " " + dtMonth + " " + dtYear + " 00:00:00 GMT";

  return dtStr;
}

async function storeRec(jsonData) {
  printConsole ("Executing storeRec");
  //jsonData.exp=jsonData.exp.toString().replace(/\//g, "%2F");
  jsonData.email=email.current;
  //var jsonDataStr=JSON.stringify(jsonData);
  //jsonDataStr=jsonDataStr.replace("stockOffer", "offer");
  //jsonDataStr=jsonDataStr.replace("expiration", "exp");
  //jsonData=JSON.parse(jsonDataStr);
  /*var sym=jsonData.security.toString();
  printConsole("exp = " + exp);
  printConsole("symbol = " + sym);
  var ask=jsonData.offer.toString();
  jsonData.ask=ask;
  jsonData.symbol=sym;
  jsonData.exp=exp;*/

  const orgExp=jsonData.exp.toString();
  const orgSymbol=jsonData.symbol.toString();

  await printConsole("storeRec : delete = ");
  await console.log (jsonData);

  if (orgSymbol.length>0 && orgExp.length>0){

    var exp=await convertDate(jsonData.exp.toString());
    jsonData.exp=exp;

    try {
      tradeDate=jsonData.tradeDate;
      if (parseInt(tradeDate) !== 0) {

        var tradeDate=await convertDate(jsonData.tradeDate.toString());
        jsonData.tradeDate=tradeDate;
      }
    } catch (error) {
      
    }

    jsonData={"security":jsonData};

    var param = JSON.stringify(jsonData);
    await printConsole("storeRec : param before encoding = "+param);
    param=encodeURI(param);
    await printConsole("storeRec : param after encoding = "+param);
    jsonData.security.exp=jsonData.security.exp.toString().replace(/\//g, "%2F");
    path = '/storetransaction';
      var passApistr=path + "/" + param //JSON.stringify(jsonData);
      await printConsole("storeRec : passApistr = " +passApistr);
      await Promise.resolve(await API.get(myAPI, passApistr))
        .then(async resp =>{
          await console.log("storeRec : resp = ",resp);
        })
  }
}

async function storeLoopRecs() {
  await printConsole ("Executing storeLoopRecs");
  displayMessage.current = "Updating portfolio";
  await console.log ("storeLoopRecs : gridRef = ", gridRef);
  var numOfRecs=gridRef.current.api.getDisplayedRowCount();

  let displayedRows = [];
  for (let rowInd =0; rowInd < numOfRecs; rowInd++) {
    displayedRows[rowInd] = gridRef.current.api.getDisplayedRowAtIndex(rowInd).data;
  }
  console.log ("storeLoopRecs : displayedRows = ", displayedRows);

  await console.log ("storeLoopRecs : numOfRecs = ", numOfRecs);
  var i, j;
  var deleteIdMatch=false;
  await printConsole("storeLoopRecs : numOfRecs = " +numOfRecs);
  for (i=0;i<numOfRecs;i++) {
    try {
      await console.log ("storeLoopRecs : gridRef.current.api.getDisplayedRowAtIndex(" + i + ") =", gridRef.current.api.getDisplayedRowAtIndex(i).data);
    } catch (error) {
      await console.log ("storeLoopRecs : error : gridRef.current.api.getDisplayedRowAtIndex : i = " + i);
    };
    deleteIdMatch=false;
    for(j=0; j<deleteIds.current.length; j++) {
      //if (gridRef.current.api.getDisplayedRowAtIndex(i).data.id == deleteIds.current[j]) {
      if (displayedRows[i].id == deleteIds.current[j]) {
        deleteIdMatch=true;
        j=deleteIds.current.length;
      }
    }
    if (!deleteIdMatch){
      try {
        //await storeRec(gridRef.current.api.getDisplayedRowAtIndex(i).data)
        await storeRec(displayedRows[i])
        .then (async () => {
          await console.log ("storeLoopRecs : after storeRec : completed");
        })
      } catch (error) {};
    }
  }
  displayMessage.current = "";
}

async function commitChanges() {
  printConsole ("Executing commitChanges");
  await console.log (gridRef);
  await printConsole (gridRef.current.api.getDisplayedRowCount());
  var numOfRecs=gridRef.current.api.getDisplayedRowCount();
  var i;
  await printConsole("numOfRecs = " +numOfRecs);
  await deleteRecs()
  .then (async () => {

    await setTimeout(async () => {
      /*deleteRowDataState().then(()=>{
        printConsole("commitChanges : rowDataState.length = "+ rowDataState.length);
        
      })*/
      /*for (i=0;i<numOfRecs;i++) {
        printConsole (gridRef.current.api.getDisplayedRowAtIndex(i).data);
        storeRec(gridRef.current.api.getDisplayedRowAtIndex(i).data)
      }*/
      
      await deleteRowDataState().then(async(result)=>{
        await printConsole("commitChanges : rowDataState.length = "+ rowDataState.length);
      }).then(async(res)=>{
        await printConsole("will run storeRec");
        /*await storeLoopRecs().then(async ()=>{
          await getMyTransactions(email.current);
        })*/

        await storeLoopRecs()
        .then (async () => {
          await getMyTransactions(email.current)
          .then (async (resp) => {
            await console.log ("commitChanges : getMyTransactions : resp = ", resp);
          })
        })
        

        /*for (i=0;i<numOfRecs;i++) {
          await printConsole (gridRef.current.api.getDisplayedRowAtIndex(i).data);
          await storeRec(gridRef.current.api.getDisplayedRowAtIndex(i).data)
        }
      
        await getMyTransactions(email.current);
        */
      })     
    }, 1000);

  })
}

function onFirstDataRendered(params) {
  printConsole ("Executing onFirstDataRendered");
  //params.api.sizeColumnsToFit();
  console.log("onFirstDataRendered : params = ", params);
  var allColumnIds = [];
  params.api.getAllGridColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
  });
  params.api.autoSizeColumns(allColumnIds);
}

  function onBtExport () {
    printConsole ("Executing onBtExport");
    gridRef.current.api.exportDataAsCsv();;
  };

  return (
    
    <View className="AppFullScreen">

        <div className="ag-theme-balham" style={{height:500}}>
          <div className="blink_me">{displayMessage.current}</div>
            <AgGridReact
              rowClassRules={rowClassRules}
              rowData={rowDataState}
              columnDefs={columns}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              ref={gridRef}
              //gridOptions={gridOptions}
              onFirstDataRendered={onFirstDataRendered}
              onSelectionChanged={onSelectionChanged}
            />
               
                
        </div>
        <Button className="Button" onClick={() => { addEmptyRow(); } }>Add Row</Button>
        <Button className="Button" onClick={() => { commitChanges(); } }>Commit Changes</Button>
        <Button className="Button" onClick={() => { onBtExport(); } }>Export To CSV</Button>
        
    </View>
  );
};

export default withAuthenticator(MyPortfolio);