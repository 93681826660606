import React, { useEffect } from "react";


import {
  withAuthenticator,
} from "@aws-amplify/ui-react";

const RunAggressive = ({ signOut }) => {
  console.log("Executing RunAggressive");

  //RunCollection("aggressive_small_caps");
  
  useEffect(() => {
    
  }, []);

  return null;
}

export default withAuthenticator(RunAggressive);
