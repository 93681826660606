import logo from './logo.svg';
import './App.css';
import Amplify, { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';
import { printConsole } from './lib/utility';
import { min } from 'moment';
import axios from 'axios';

const myAPI="mktappapi";


const formatNumberWithCommas = (number) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const updateEtfsInfo = async () => {
  printConsole("Executing FindEtfsForIc");
  
  try {
      // Replace with the appropriate method (get, post, etc.) and add parameters if needed
      //const response = await axios.get('https://2fydft663cxnyhm3lsdvrp2bxi0celyp.lambda-url.us-east-1.on.aws/');
      const response = await axios.get('https://23vnqvuq4u6tt3omjgiu5xogmy0hyndz.lambda-url.us-east-1.on.aws/');
      await printConsole('updateEtfsInfo : response = ', response.data);
      //let etfStocks = JSON.parse(response.data);
      let etfStocks = response.data;
      //stocks.current = await {"stocks" : etfStocks};
      //stocksDownloaded.current = await true;
      //return response.data;
  } catch (error) {
      console.error('Error calling updateEtfsInfo:', error);
      // Handle error accordingly
  }
 
};

const FindEtfsForIc = ({signOut}) => {
  printConsole("I am in FindEtfsForIc");
  const [input, setInput] = useState("");
  let [displayMessage, setDisplayMessage] = useState("");
  const [formData, setFormData] = useState({
    minCap: '',
    maxQuoteSpread: ''
  });
  const { activeButton, setActiveButton } = useActiveButton();

  const handleSubmit = async (event) => {
    await printConsole("Executing handleSubmit");
    await setDisplayMessage("Searching...");
    await event.preventDefault();

    let etfs = [];

    let path = '/findEtfsForIc'; 

    let minCap = 0;
    let maxQuoteSpread = 0;

    if (formData.minCap) {
      minCap = formData.minCap.toString().replace(/,/g, '');
    }
    if (formData.maxQuoteSpread) {
      maxQuoteSpread = formData.maxQuoteSpread.toString();
    }

    await console.log(formData); 

    await setDisplayMessage("Getting market data for ETFs");
    await Promise.resolve(updateEtfsInfo())
    .then(async resp =>{
      await printConsole("handleSubmit : updateEtfsInfo: resp = ", resp);
      var passApiStr=await path + "/" + minCap + "/" + maxQuoteSpread;
      await printConsole("handleSubmit : passApiStr = "+ passApiStr);
      await setDisplayMessage("Identifying ETFs for Iron Condor");
      await Promise.resolve(await API.get(myAPI, passApiStr))
      .then(async resp =>{
        //await setDisplayMessage(resp);
        await console.log("handleSubmit : resp = ");
        await console.log(resp);
        etfs = await resp;
      })
      .then(() => {
        navigate('/findIcETFsGrid', { state: { data: etfs } });
      })
      .catch(error => {
        console.log(error.message);
      }) 
    })


    
  };

  const navigate = useNavigate();

  useEffect(()=>{
    navigate(input);
  },[displayMessage])

  const handleClear = () => {
    setFormData({
        minCap: '',
        maxQuoteSpread: ''
    });
    setDisplayMessage("");
  };

  const handleChange = (event) => {
    console.log("Executing handleChange");
    const { name, value } = event.target;
    let formattedValue = value;
    printConsole("handleChange : name = " + name);
    printConsole("handleChange : value = " + value);
    printConsole("handleChange : formattedValue = " + formattedValue);

    // Only format the minCap input with commas
    if (name === 'minCap') {
      // Remove non-numeric characters except commas
      formattedValue = value.replace(/[^0-9]/g, '');
      formattedValue = formatNumberWithCommas(formattedValue);
      printConsole("handleChange : modified formattedValue = " + formattedValue);
    }

      setFormData({
          ...formData,
          [name]: formattedValue
      });
      setDisplayMessage("");
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20vh'
  };

  const inputStyle = {
      margin: '10px',
      padding: '10px',
      width: '300px',
      borderRadius: '5px',
      border: '1px solid #ddd'
  };

  const buttonStyle = {
      margin: '10px',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#4CAF50',
      color: 'white',
      cursor: 'pointer'
  };

  return (
    
    <form onSubmit={handleSubmit} style={formStyle}>
      <div>{(
        <div className="blink_me" style={{ fontSize: '36px' }}>{displayMessage}</div>
      )}</div>
        <input 
            type="text" 
            name="minCap" 
            value={formData.minCap} 
            onChange={handleChange} 
            required 
            placeholder="Min Cap"
            style={inputStyle}
        />
        <input 
            type="text" 
            name="maxQuoteSpread" 
            value={formData.maxQuoteSpread} 
            onChange={handleChange} 
            required 
            placeholder="Max Quote Spread (in cents)"
            style={inputStyle}
        />
         
        <div className="menu-row" style={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
          <button type="submit" style={buttonStyle}>Submit</button>
          <button type="button" onClick={handleClear} style={buttonStyle}>Clear</button>
        </div>
    </form>
  )
}

export default FindEtfsForIc;