import React, { useState, useEffect, useRef } from "react";
import { Button } from "@aws-amplify/ui-react";
import { Auth } from 'aws-amplify';
import { printConsole } from './lib/utility';
import { downloadFile, uploadFile } from "./lib/common";
import './App.css';

const Settings = ({ signOut }) => {
  let email = useRef("");
  const [days, setDays] = useState("");
  const [daysOffset, setDaysOffset] = useState("");
  const [minDepth, setMinDepth] = useState("");
  const [excludeLeveragedEtfs, setExcludeLeveragedEtfs] = useState("");
  const [data, setData] = useState({"days": "", "daysOffset": "", "minDepth": "", "excludeLeveragedEtfs": ""});
  const [error, setError] = useState('');
  const [hide, setHide] = useState("hidden");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  let mydata = useRef();
  let uploadResult = useRef("");

  useEffect(() => {
    async function fetchData() {
      await executeSettingsForm();
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (submitted) {
      let settingsJson = data;
      printConsole("useeffect : settingsJson = " + JSON.stringify(settingsJson));

      if (settingsJson.days === "") {
        settingsJson.days = mydata.current.days.toString();
      }
      if (settingsJson.daysOffset === "") {
        settingsJson.daysOffset = mydata.current.daysOffset.toString();
      }
      if (settingsJson.minDepth === "") {
        settingsJson.minDepth = mydata.current.minDepth.toString();
      }
      if (settingsJson.excludeLeveragedEtfs === "") {
        settingsJson.excludeLeveragedEtfs = mydata.current.excludeLeveragedEtfs.toString();
      }
      printConsole("useeffect : newSettingsStr = " + JSON.stringify(settingsJson));

      //uploadResult.current = Promise.resolve(uploadFile(email.current, settingsJson));

      (async () => {
        await uploadFile(email.current, settingsJson)
        .then(result => {
          uploadResult.current = result;
          printConsole("useeffect : uploadResult = " + uploadResult.current);
          if (uploadResult.current === "completed the upload") {
            uploadResult.current = "Successful Upload";
          } else {
            uploadResult.current = "Upload Failed";
          }
          printConsole("useeffect : uploadResult = " + uploadResult.current);
          setSubmitted(false);
        });
      })();

      
    }
  }, [submitted, data]);

  const getUserInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      printConsole("getUserInfo : email = " + JSON.stringify(user.attributes.email));
      email.current = await user.attributes.email;
      return "done";
    } catch (error) {
      printConsole("Error in getUserInfo: ", error);
      return "error";
    }
  };

  const listFiles = async () => {
    try {
      await printConsole("Executing listFiles");
      if (typeof(email.current) === 'undefined') {
        await getUserInfo();
      }
      await downloadFile(email.current)
      .then(async result => {
        mydata.current = await JSON.parse(result);
        await printConsole("listFiles : downloadFile : mydata = " +JSON.stringify(mydata.current));
      });
      await printConsole("listFiles : mydata = " +JSON.stringify(mydata.current));
      await setData(mydata.current);
      setDays(mydata.current.days);
      setDaysOffset(mydata.current.daysOffset);
      setMinDepth(mydata.current.minDepth);
      setExcludeLeveragedEtfs(mydata.current.excludeLeveragedEtfs);
    } catch (error) {
      printConsole("Error in listFiles: ", error);
    }
  };

  const executeSettingsForm = async () => {
    await Promise.resolve(await getUserInfo())
    .then(async () => {
      await printConsole ("email = " + email.current);
      if (typeof(email.current) === 'undefined') {
        await getUserInfo();
      }
      await listFiles();
      await printConsole("executeSettingsForm : data.days = " + data.days);
    });
  };

  const handleSubmit = () => {
    uploadResult.current = "Uploading";
    setSubmitted(true);
    printConsole ("handleSubmit : days = " + days + "\tdaysOffset = " + daysOffset + "\tminDepth = " + minDepth + "\texcludeLeveragedEtfs = " + excludeLeveragedEtfs);
    setData({"days": days, "daysOffset": daysOffset, "minDepth": minDepth, "excludeLeveragedEtfs": excludeLeveragedEtfs});
  };

  const defaultValues = () => {
    setSubmitted(false);
    setDays(30);
    setDaysOffset(10);
    setMinDepth(10);
    setExcludeLeveragedEtfs("NO");
    setData({"days": 30, "daysOffset": 10, "minDepth": 10, "excludeLeveragedEtfs": "NO"});
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh'
  };
  const inputStyle = {
    margin: '10px',
    padding: '10px',
    width: '300px',
    borderRadius: '5px',
    border: '1px solid #ddd'
  };

  const buttonStyle = {
      margin: '10px',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#4CAF50',
      color: 'white',
      cursor: 'pointer'
  };

  /*if (loading) {
    return <div className="blink_me">Loading...</div>;
  }*/

  return (
    <div className="MenuStyle">
      {loading ? (
        <div className="blink_me">Loading...</div>
      ) : (
        <>
          {uploadResult.current === "Uploading" ? (<div className="blink_me">{uploadResult.current}</div>) : (<div className="blink_me"></div>)}
          {uploadResult.current === "Successful Upload" ? (<div className="blink_me">{uploadResult.current}</div>) : (<div className="blink_me"></div>)}
          {uploadResult.current === "Upload Failed" ? (<div className="blink_me_red">{uploadResult.current}</div>) : (<div className="blink_me"></div>)}
          {printConsole("form : data.days = " + data.days + "\tdata.daysOffset = " + data.daysOffset + "\tdata.minDepth = " + data.minDepth + "\tdata.excludeLeveragedEtfs = " + data.excludeLeveragedEtfs)}
          {printConsole("form : days = " + days + "\tdaysOffset = " + daysOffset + "\tminDepth = " + minDepth + "\texcludeLeveragedEtfs = " + excludeLeveragedEtfs)}

          <form id="settingsForm" name="settingsForm" style={formStyle}>
            <label>
              <strong>Days to Find Next Expiration</strong>
              <br />
              <input
                type="text"
                id="days"
                name="days"
                placeholder={data.days}
                value={days}
                style={inputStyle}
                onChange={(e) => {setDays(e.target.value); uploadResult.current = ""}}
              />
            </label>
            <br />
            <label>
              <strong>Days Offset</strong> 
              <br />
              <div>
              (if no options expiration on desired day):
              </div>
              <input
                type="text"
                name="daysOffset"
                placeholder={data.daysOffset}
                value={daysOffset}
                style={inputStyle}
                onChange={(e) => {setDaysOffset(e.target.value); uploadResult.current = ""}}
              />
            </label>
            <br />
            <label>
              <strong>Minimum Depth In %</strong>
              <br />
              <input
                type="text"
                name="minDepth"
                placeholder={data.minDepth}
                value={minDepth}
                style={inputStyle}
                onChange={(e) => {setMinDepth(e.target.value); uploadResult.current = ""}}
              />
            </label>
            <br />
            <br />
            <label>
              <strong>Exclude Leveraged ETFs</strong>
              <br />
              <select
                name="excludeLeveragedEtfs"
                placeholder={data.excludeLeveragedEtfs}
                value={excludeLeveragedEtfs}
                style={inputStyle}
                onChange={(e) => {setExcludeLeveragedEtfs(e.target.value); uploadResult.current = ""}}
              >
                <option value="NO">NO</option>
                <option value="YES">YES</option>
              </select>
            </label>
            <br />
          </form>
          <br />
          <Button className="Button" onClick={handleSubmit} style={buttonStyle}>Submit</Button>
          <Button className="Button" onClick={defaultValues} style={buttonStyle}>Default</Button>
          <br />
          <textarea hidden={hide} placeholder={error} readOnly></textarea>
        </>
      )}
    </div>
  );
};

export default Settings;
